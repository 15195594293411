
          @import "src/assets/scss/colors.scss";
          @import "src/assets/scss/mixins.scss";
        
.programInfo {
  display: flex;
  width: 1280px;
  min-height: 600px;
  padding: 0 50px;
  margin: 40px auto 120px auto;
  .rightWrapper {
    width: 880px;
    margin: 0 20px 0 0;
    .programTitle {
      width: 100%;
      border-bottom: 1px solid $Black;
      margin-bottom: 40px;
      .myProgram {
        @include body2-600($Gray700);
        margin-bottom: 4px;
      }
      .title {
        @include heading5($Black);
        margin-bottom: 16px;
      }
    }
    .programContent {
      margin-bottom: 48px;
      border-bottom: 1px solid $Gray300;
      > p {
        display: flex;
        align-items: center;
        width: 100%;
        margin-bottom: 16px;
        .contentTitle {
          width: 120px;
          margin-right: 8px;
          @include body3-500($Gray600);
        }
        .contentDetail {
          width: 652px;
          @include body3-500($Gray900);
        }
      }

    }
    .list {
      .listTitle {
        height: 51px;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid $Gray400;
        .listOrder {
          position: relative;
          display: flex;
          @include heading4Regular($Black);
          &:before {
            content: '';
            width: 6px;
            height: 6px;
            display: block;
            border-radius: 50%;
            background: $Gray600;
            margin-top: 11px;
            margin-right: 8px;
          }
        }
        .totalTime {
          @include body-regular($Gray600);
          > span {
            @include body3-500($Gray900);
          }
        }
      }
      .listContainer {
        width: 100%;
      }
    }
  }
  .leftWrapper {
    .floatContainer {
      position: sticky;
      top: 148px;
      width: 280px;
      padding: 16px;
      box-shadow: 0 0 2px rgba(0, 0, 0, 0.06), 0 6px 14px rgba(0, 0, 0, 0.15);
      border-radius: 8px;
      >p {
        @include heading4Regular($Black);
        margin-bottom: 8px;
      }
      .shareBox {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 41px;
        border: 1px solid $Gray300;
        border-radius: 4px;
        padding: 10px 16px;
        .tooltiptext {
          width: 326px;
          height: 65px;
          background-color: $Yellow200;
          border-radius: 8px;
          position: absolute;
          z-index: 1;
          right: 105%;
          transition: opacity 1s;
          padding: 8px 16px;
          filter: drop-shadow(0px 1px 10px rgba(0, 0, 0, 0.08)) drop-shadow(0px 9px 20px rgba(0, 0, 0, 0.15));
          @include body-regular($Gray700);
          &.hide {
            visibility: hidden;
            opacity: 0;
          }
          > span {
            @include body2-600($Black);
            margin-bottom: 4px;
          }
          &::after {
            content: " ";
            position: absolute;
            top: 50%;
            left: 100%;
            transform: rotate(180deg);
            margin-top: -10px;
            border-width: 10px;
            border-style: solid;
            border-color: transparent $Yellow200 transparent transparent;
          }
        }
        &:hover .tooltiptext {
          visibility: visible;
          opacity: 1;
        }
        .toggleBtnWrapper {
          display: flex;
          align-items: center;
          .toggleBtn {
            width: 36px;
            height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            > span {
              position: relative;
              display: inline-block;
              width: 36px;
              height: 20px;
              cursor: pointer;
              transition: ease all 0.3s ;
              border-radius: 40px;
              background: #e9e9e9;

              &:before {
                position: absolute;
                top: 2px;
                left: 34px;
                width: 16px;
                height: 16px;
                content: "";
                transition: ease all 0.3s ;
                transform: translateX(-31px);
                border-radius: 50%;
                background: #fff;
              }

              &.on {
                transition: ease all 0.3s ;
                background: $Primary;
                &:before {
                  transition: ease all 0.3s ;
                  transform: translateX(-16px);
                }
              }
            }
          }
          > span {
            width: 25px;
            margin-left: 8px;
            @include body3-500($Gray2);
          }
        }
        > span {
          @include body3-500($Gray600);
        }
      }
      .programInput {
        position: relative;
        width: 100%;
        margin-top: 8px;
        > textarea {
          width: 100%;
          height: 41px;
          padding: 9px 16px;
          border: 1px solid #DDDDDD;
          border-radius: 4px;
          resize: none;
          @include body-regular($Black);
          -ms-overflow-style: none; /* IE and Edge */
          scrollbar-width: none; /* Firefox */
          &::-webkit-scrollbar {
            display: none;
          }
          &::placeholder {
            @include body-regular($Gray500);
          }
          &.big {
            height: 146px;
          }
          &.borderPrimary {
            border: 1px solid $Primary;
          }
          &.paddingRight {
            padding-right: 36px;
          }
        }
        > img {
          position: absolute;
          top: 13px;
          right: 12px;
          cursor: pointer;
        }
        > span {
          display: flex;
          justify-content: flex-end;
          @include small-regular($Gray700);
          margin-top: 2px;
        }
      }
      .btns {
        margin-top: 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        > button {
          width: 119px;
          height: 52px;
          border-radius: 4px;
          &.backBtn {
            background: $Mint100;
            @include body2-600($Mint600);
          }
          &.saveBtn {
            background: $Mint500;
            @include body2-600($White);
          }
        }
      }
    }
  }
}
