
          @import "src/assets/scss/colors.scss";
          @import "src/assets/scss/mixins.scss";
        
.titleContainer {
  width: 1280px;
  padding: 40px 50px 40px 50px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  .top1 {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 26px;
    .top1_1 {
      display: flex;
      align-items: center;
      > div {
        &:first-child,
        &:last-child {
          @include skeletonBox(48, 21);
        }
        &:nth-child(2) {
          @include skeletonBox(18, 18);
          margin: 0 4px;
        }
      }
    }
    .top1_2 {
      @include skeletonBox(134, 52)
    }
  }
  .top2 {

    .top2_1 {

      display: flex;
      justify-content: space-between;
      align-items: center;

      .top2_1_1 {
        border: 1px solid $Gray300;
        border-radius: 4px;
        width: 580px;
        height: 196px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding: 17px 16px 45px 16px;
        .top2_1_1_top {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-bottom: 1px solid $Gray200;
          .top2_1_1_1 {

            display: flex;
            align-items: center;

            padding-bottom: 9px;
            > div {
              &:first-child,
              &:nth-child(2) {
                @include skeletonBox(52, 30);
                margin-right: 8px;
              }
              &:last-child {
                @include skeletonBox(64, 21);
              }
            }
          }
          .top2_1_1_2 {
            display: flex;
            > div {
              @include skeletonBox(24, 24);
              margin-left: 16px;
            }
          }
        }
        .top2_1_1_bottom {
          width: 100%;
          display: flex;
          flex-direction: column;
          margin-top: 16px;
          .top2_1_1_bottom_1 {
            display: flex;
            align-items: center;
            margin-bottom: 8px;
            > div {
              display: flex;
              &:first-child {
                @include skeletonBox(64, 21);
                margin-right: 8px;
              }
              &:last-child {
                @include skeletonBox(200, 21);
              }
            }
          }
          .top2_1_1_bottom_2 {
            display: flex;
            margin-bottom: 8px;
            > div {
              display: flex;
              &:first-child {
                @include skeletonBox(64, 21);
                margin-right: 8px;
              }
              &:last-child {
                @include skeletonBox(96, 21);
              }
            }
          }
          .top2_1_1_bottom_3 {
            display: flex;
            align-items: center;
            > div {
              display: flex;
              &:first-child {
                @include skeletonBox(64, 21);
                margin-right: 8px;
              }
              &:last-child {
                @include skeletonBox(56, 21);
              }
            }
          }
        }
      }
      .top2_1_2 {
        width: 580px;
        height: 196px;
        border: 1px solid $Gray300;
        border-radius: 4px;
        padding: 16px;
        > div {
          &:first-child {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            > div {
              margin-bottom: 8px;
              &:first-child {
                @include skeletonBox(28, 24);
              }
              &:last-child {
                @include skeletonBox(58, 34);
              }
            }
          }
          &:last-child {
            @include skeletonBox(548, 122);
            border-radius: 8px;
          }

        }
      }
    }
    .top2_2 {

    }
  }
}

.tabContainer {
  width: 100%;
  height: 48px;
  background: $Gray6;
  border-top: 1px solid $Gray300;
  border-bottom: 1px solid $Gray200;
  margin-bottom: 40px;
  .tabWrapper {
    width: 1280px;
    height: 100%;
    display: flex;
    align-items: center;
    margin: 0 auto;
    padding: 0 50px 0 50px;
    > div {
      @include skeletonBox(60, 24);
      margin-right: 32px;
    }
  }
}

.content {
  display: flex;
  margin: 0 auto 80px auto;
  width: 1280px;
  min-width: 1280px;
  height: auto;
  flex-direction: column;
  > div {
    display: flex;
    flex-direction: column;
    width: 1180px;
    padding: 24px;
    background: $Gray100;
    border-radius: 4px;
    border: 1px solid $Gray400;
    margin: 0 auto 80px auto;
    .content1 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 40px;
      .content1_1 {
        @include skeletonBox(328, 41);
      }
      .content1_2 {
        display: flex;
        align-items: center;
        > div {
          @include skeletonBox(100, 41);
          margin-left: 8px;
        }
      }
    }
    .content2 {
      margin-bottom: 24px;
      @include skeletonBox(90, 21);
    }
    .content3 {
      border-radius: 4px;
      border: 1px solid $Gray300;
      padding: 23px 24px;
      background: $White;
      .content3_1 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 24px;
        > div {
          @include skeletonBox(534, 117);
        }
      }
      .content3_2 {
        @include skeletonBox(1084, 367);
      }
    }
  }

}