
          @import "src/assets/scss/colors.scss";
          @import "src/assets/scss/mixins.scss";
        
.exerciseItemDetail {
  width: 100%;
  .detailWrap {
    width: 100%;
    height: 56px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $Gray200;
    padding: 8px;
    cursor: pointer;
    .itemLeft {
      display: flex;
      position: relative;
      > img {
        width: 40px;
        height: 40px;
        border-radius: 4px;
        margin-right: 8px;
        z-index: 1;
      }
      .imgBack {
        position: absolute;
        width: 40px;
        height: 40px;
        border-radius: 4px;
        background: $Gray200;
        margin-right: 8px;
      }
      .exItem {
        .itemTitle {
          @include body-regular($Gray900);
        }
        .level {
          display: flex;
          @include small-medium($Gray600);
          &.green:before {
            display: block;
            width: 8px;
            height: 8px;
            content: '';
            border-radius: 50%;
            margin-right: 4px;
            margin-top: 5px;
            background: $Green500;
          }
          &.whiteGreen:before {
            display: block;
            width: 8px;
            height: 8px;
            content: '';
            border-radius: 50%;
            margin-right: 4px;
            margin-top: 5px;
            background: $Green300;
          }
          &.yellow:before {
            display: block;
            width: 8px;
            height: 8px;
            content: '';
            border-radius: 50%;
            margin-right: 4px;
            margin-top: 5px;
            background: $Yellow500;
          }
          &.orange:before {
            display: block;
            width: 8px;
            height: 8px;
            content: '';
            border-radius: 50%;
            margin-right: 4px;
            margin-top: 5px;
            background: $Orange500;
          }
          &.red:before {
            display: block;
            width: 8px;
            height: 8px;
            content: '';
            border-radius: 50%;
            margin-right: 4px;
            margin-top: 5px;
            background: $Alert;
          }
        }
      }
    }
    .itemRight {
      display: flex;
      .unit {
        display: flex;
        align-items: center;
        @include small-medium($Gray900);
        margin-right: 20px;
        > span {
          margin: 0 8px;
          @include small-regular($Gray500);
        }
      }
      .rotate {
        transform: rotateX(180deg);
      }
    }
  }
  .detailDropdown {
    width: 100%;
    background: $Gray100;
    border-bottom: 1px solid $Gray200;
    padding: 32px 32px 40px 32px;
    video {
      width: 653px;
      height: 367px;
      border-radius: 4px;
      margin-bottom: 40px;
    }
    .exName {
      width: 100%;
      border-bottom: 1px solid $Black;
      margin-bottom: 24px;
      > p {
        @include heading3Thin($Black);
        margin-bottom: 16px;
      }
    }
    .exDetail {
      > p {
        display: flex;
        margin-bottom: 16px;
        .title {
          width: 48px;
          margin-right: 8px;
          @include body3-500($Gray600);
        }
        > span {
          width: 598px;
          word-break: keep-all;
          @include body3-500($Gray900);
        }
        &:last-child {
          margin: 0;
        }
      }
    }
  }

}