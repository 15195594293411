
          @import "src/assets/scss/colors.scss";
          @import "src/assets/scss/mixins.scss";
        
.planDates {
  display: flex;
  justify-content: space-between;
  width: 1280px;
  padding: 0 50px;
  margin: 52px auto 120px auto;
  .rightWrapper {
    width: 880px;
    min-height: 764px;
    .arrowBtns {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-content: center;
      margin-bottom: 16px;
      > img{
        width: 40px;
        height: 40px;
      }
      .active {
        cursor: pointer;
      }
    }
    .datesContainer {
      width: 100%;
      max-height: 1006px;
      display: flex;
      > div {
        width: 280px;
        max-height: 100%;
        border: 1px solid $Gray300;
        border-radius: 8px;
        margin-right: 20px;
        .dateBoxTop {
          width: 100%;
          height: 76px;
          border-bottom: 1px solid $Gray300;
          padding: 12px 16px;
          > p {
            @include small-regular($Gray900);
            margin-bottom: 8px;
          }
          .dateTopTitle {
            display: flex;
            justify-content: space-between;
            align-content: center;
            > span {
              @include body2-500($Gray900);
            }
            .dateBtns {
              > button {
                width: 37px;
                height: 26px;
                border-radius: 4px;
                @include small-medium($Gray600);
                background: $Gray200;
                &.btnCopy {
                  margin-right: 4px;
                }
              }
            }
          }
        }
        .dateBoxMain {
          padding: 16px;
          .mainTop {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 8px;
            > span {
              @include body-regular($Gray600);
              > span {
                @include body-small($Gray500);
              }
            }
            > button {
              width: 53px;
              height: 30px;
              background: $Mint100;
              border-radius: 4px;
              @include small-semi-bold($Mint600);
            }
          }
          .mainList {
            width: 248px;
            height: 560px;
            overflow-y: auto;
            overflow-x: hidden;
            &::-webkit-scrollbar {
              /* 스크롤바 막대 너비 설정 */
              width: 4px;
            }

            &::-webkit-scrollbar-thumb {
              /* 스크롤바 막대 높이 설정 */
              background: $Mint300;
              /* 스크롤바 둥글게 설정 */
              border-radius: 100px;
              //background-clip: padding-box;
              border: 3px solid transparent;
            }

            &::-webkit-scrollbar-track {
              /* 스크롤바 막대 높이 설정 */
              background: $Gray200;
              /* 스크롤바 둥글게 설정 */
              border-radius: 100px;
              //background-clip: padding-box;
              border: 3px solid transparent;
            }
            > div {
              position: relative;
              display: flex;
              justify-content: space-between;
              align-items: center;
              width: 100%;
              height: 72px;
              border-bottom: 1px solid $Gray200;
              padding: 12px 8px;
              .grayBox {
                width: 48px;
                height: 48px;
                background: $Gray6;
                border-radius: 4px;
              }
              > img {
                position: absolute;
                width: 48px;
                height: 48px;
                border-radius: 4px;
              }
              .exInfo {
                width: 176px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                height: 48px;
                margin-top: -6px;
                .exTitle {
                  width: 100%;
                  @include body3-500($Gray900);
                  text-overflow: ellipsis;
                  overflow: hidden;
                  white-space: nowrap;
                }
                .exCount {
                  display: flex;
                  > span {
                    @include small-regular($Gray600);
                    &.multiply {
                      margin: 0 4px;
                      @include small-regular($Gray500);
                    }
                  }
                }
              }
            }
          }
        }
        &:last-child {
          margin-right: 0;
        }
      }
      .addNew {
        height: 74px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        > img {
          margin-right: 8px;
        }
        > span {
          @include body2-500($Gray600);
        }
      }
    }
  }
  .leftWrapper {
    .floatContainer {
      position: sticky;
      top: 148px;
      width: 280px;
      padding: 16px;
      box-shadow: 0 0 2px rgba(0, 0, 0, 0.06), 0 6px 14px rgba(0, 0, 0, 0.15);
      border-radius: 8px;
      > p {
        @include heading4Regular($Black);
        margin-bottom: 24px;
      }
      .totalExCount {
        display: flex;
        align-items: center;
        margin-bottom: 8px;
        > img {
          margin-right: 8px;
        }
        > span {
          @include body3-600($Mint600);
        }
      }
      .planWrap {
        width: 100%;
        max-height: 196px;
        overflow-y: auto;
        overflow-x: hidden;
        &::-webkit-scrollbar {
          /* 스크롤바 막대 너비 설정 */
          width: 4px;
        }

        &::-webkit-scrollbar-thumb {
          /* 스크롤바 막대 높이 설정 */
          background: $Mint300;
          /* 스크롤바 둥글게 설정 */
          border-radius: 100px;
          //background-clip: padding-box;
          border: 3px solid transparent;
        }

        &::-webkit-scrollbar-track {
          /* 스크롤바 막대 높이 설정 */
          background: $Gray200;
          /* 스크롤바 둥글게 설정 */
          border-radius: 100px;
          //background-clip: padding-box;
          border: 3px solid transparent;
        }
        > div {
          width: 99%;
          background: $Gray100;
          height: 59px;
          border-bottom: 1px solid $Gray200;
          padding: 8px 16px;
          &:first-child {
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
          }
          &:last-child {
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
          }
          .day {
            @include small-regular($Gray900);
            margin-bottom: 4px;
          }
          .dateWrap {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .date {
              @include body3-500($Gray900);
            }
            .countTime {
              @include body-regular($Gray600);
              > span {
                @include body-regular($Gray400);
              }
            }
          }
          &:last-child {
            border-bottom: 0;
          }
        }
      }
      > button {
        display: block;
        @include body3-500($Gray600);
        text-decoration: underline;
        margin: 24px auto 48px auto;
      }
      .btnBox {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        > button {
          width: 119px;
          height: 52px;
          border-radius: 4px;
        }
        .backBtn {
          background: $Mint100;
          @include body2-600($Mint600);
        }
        .nextBtn {
          background: $Mint500;
          @include body2-600($White);
        }
      }
    }
  }
}