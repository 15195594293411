
          @import "src/assets/scss/colors.scss";
          @import "src/assets/scss/mixins.scss";
        
.planAssignment {
  .subHeader {
    position: sticky;
    top: 64px;
    z-index: 10;
    height: 48px;
    background: $Gray6;
    width: 100%;
    min-width: 1280px;
    .editHeaderWrapper {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background: $Gray700;
      > p {
        @include body2-600($White);
      }
    }
    .subHeaderWrapper {
      width: 1280px;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 auto;
      padding: 0 50px;
      .title {
        @include heading3Thin($Black);
      }
      .patient {
        @include body2-600($Black);
        > span {
          @include body2-400($Gray400);
          .gender,
          .birth {
            @include body2-400($Gray700);
          }
        }
      }
      .steps {
        display: flex;
        align-items: center;
      }
    }

  }
}