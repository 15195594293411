
          @import "src/assets/scss/colors.scss";
          @import "src/assets/scss/mixins.scss";
        
.notiSkeleton {
  margin: 24px 0 40px 0;
  .item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: $White;
    width: 100%;
    border-radius: 8px;
    padding: 8px 16px;
    margin: 0 0 16px 0;
    cursor: pointer;
    > div {
      display: flex;
      align-items: center;
      .tag {
        display: flex;
        align-items: center;
        justify-content: center;
        @include skeletonBox(48, 22);
        border-radius: 4px;
        margin-right: 72px;
        > span {

        }
      }
      .itemContent {
        display: flex;
        .notiTitle {
          @include skeletonBox(240, 22);
          margin-bottom: 4px;
          margin-right: 8px;
          padding-top: 3px;
        }
        .notiTitle2 {
          @include skeletonBox(160, 22);
          margin-bottom: 4px;
          padding-top: 3px;
        }
      }
    }
    .date {
      @include skeletonBox(50, 18);
    }
    &:last-child {
      margin: 0 0 0 0;
    }
  }
}