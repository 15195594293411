
          @import "src/assets/scss/colors.scss";
          @import "src/assets/scss/mixins.scss";
        
.noticeSkeleton {
  width: 1180px;
  margin: 0 auto;
  > h1 {
    text-align: center;
    @include skeletonBox(140, 60);
    margin: 50px auto 10px auto;
  }
  .searchContainer {
    margin: 0 0 24px auto;
    @include skeletonBox(328, 41);
  }
  .table {
    width: 100%;
    min-height: 540px;
    padding-bottom: 120px;
    .column {
      width: 100%;
      display: flex;
      padding: 8px 16px;
      border-bottom: 1px solid $Gray200;
      > p {
        @include skeletonBox(104, 21);
        &:first-child {
          @include skeletonBox(72, 21);
          margin-right: 36px;
        }
      }
    }
    .contentWrapper {
      margin-bottom: 40px;
      .content {
        width: 100%;
        display: flex;
        padding: 8px 16px;
        border-bottom: 1px solid $Gray200;
        .date {
          margin-right: 36px;
          @include skeletonBox(72, 21);

        }
        .title {
          @include skeletonBox(400, 21);
        }
      }
      .page {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 40px;
        > div {
          margin-right: 4px;
          @include skeletonBox(23, 21);
        }
      }
    }

  }
}