
          @import "src/assets/scss/colors.scss";
          @import "src/assets/scss/mixins.scss";
        
.homeContentGroup {
  width: 1180px;
  height: 228px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  .group {
    width: 280px;
    height: 100%;
    display: flex;
    flex-direction: column;
    border: 1px solid $Gray300;
    border-radius: 4px;
    padding: 24px;
    background: $White;
    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 23px;
      > p {
        @include body2-500($Black);
      }
      .wheel {
        width: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background: rgba(255, 255, 255, 0.50);
        cursor: pointer;
        > div {
          width: 24px;
          height: 24px;
          background: url('https://resources.mora.kr/static/app/mora-ex/assets/web/G_setting.svg') no-repeat;
          transition: ease all .2s;
        }
        &:hover {
          > div {
            background: url('https://resources.mora.kr/static/app/mora-ex/assets/web/P_setting.svg') no-repeat;
          }
        }
        &:active {
          > div {
            background: url('https://resources.mora.kr/static/app/mora-ex/assets/web/DP_setting.svg') no-repeat;
          }
        }
      }

    }
    .groupName {
      display: flex;
      align-items: center;
      margin-bottom: 14px;
      > h6 {
        width: 54px;
        margin-right: 12px;
        @include body3-500($Gray600);
      }
      > p {
        @include body3-600($Black);
      }


    }
    .code {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      > h6 {
        width: 54px;
        margin-right: 12px;
        @include body3-500($Gray600);
      }
      > div {
        height: 32px;
        display: flex;
        align-items: center;
        border-radius: 36px;
        background: $Gray100;
        padding: 4px 12px;
        cursor: pointer;
        > span {
          @include body2-600($Mint500);
          margin-right: 8px;
        }
        .copy {

          width: 18px;
          height: 18px;
          background: url('https://resources.mora.kr/static/app/mora-ex/assets/web/G_copy18.svg') no-repeat;
          transition: ease-out all .2s;

        }
        &:hover {
          .copy {
            background: url('https://resources.mora.kr/static/app/mora-ex/assets/web/P_copy18.svg') no-repeat;
          }

        }
        &:active {
          .copy {
            background: url('https://resources.mora.kr/static/app/mora-ex/assets/web/DP_copy18.svg') no-repeat;
          }

        }
      }
    }
  }
  .service {
    width: 280px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 24px;
    border: 1px solid $Gray300;
    border-radius: 4px;
    background: $White;
    .subs {
      @include body2-500($Black);
    }
    .subContent {
      .subsName {
        @include title2Thin($Black);
        margin-bottom: 8px;
      }
      .subsDesc {
        display: flex;
        @include body3-500($Gray600);
        margin-bottom: 3px;
        .count {
          @include body3-600($Mint500);
          margin: 0 2px 0 4px;
          &.red {
            display: flex;
            align-items: center;
            color: $Red500 !important;
            > img {
              margin-left: 4px;
            }
          }
        }
        .date {
          @include body-regular($Gray500);
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
      .subsPeriod {
        @include body3-500($Gray600);
        > span {
          @include body3-600($Gray700);
        }
      }
    }
  }
  .plans {
    width: 580px;
    height: 100%;
    border: 1px solid $Gray300;
    border-radius: 4px;
    background: $White;
    padding: 24px;
    > p {
      @include body2-500($Black);
      margin-bottom: 18px;
    }
    .planList {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      .planItem {
        width: 100%;
        height: 38px;
        display: flex;
        align-content: center;
        margin-bottom: 12px;
        background: $Gray100;
        border-radius: 4px;
        padding: 8px 24px;
        cursor: pointer;
        transition: ease all .2s;
        .creator {
          width: 193px;
          display: flex;
          justify-content: center;
          align-items: center;
          > span {
            margin-left: 8px;
            @include body3-500($Gray800);
          }
        }
        .bar {
          @include small-regular($Gray300);
        }
        .mainPart {
          width: 129px;
          text-align: center;
          @include body3-500($Gray800);
        }
        .patient {
          width: 193px;
          text-align: center;
          @include body3-500($Gray800);
          > span {
            margin: 0 4px;
            @include body-regular($Gray400);
          }
        }
        .status {
          text-align: center;
          width: 129px;
          > span {
            background: aliceblue;
            border-radius: 4px;
            padding: 2px 6px;
            @include small-semi-bold($Gray800);
            &.gray {
              background: $Gray300;
            }
            &.blue {
              background: $Mint200;
            }
            &.yellow {
              background: $Yellow200;
            }
            &.lightBlue {
              background: $Mint100;
            }
            &.pink {
              background: $Red100;
            }
          }
        }
        &:last-child {
          margin-bottom: 0;
        }
        &:hover {
          box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.08);
        }
        &:active {
          background: $Gray200;
          box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.08);
        }
      }
      .noPlans {
        width: 100%;
        height: 138px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: $Gray100;
        border-radius: 4px;
        > p {
          @include body2-500($Gray600);
        }
      }
    }
  }
  .addCardInputBox{
    width: 100%;
    background: $White;
    padding: 40px 24px;
    .infoBox {
      margin-bottom: 24px;
      >p{
        @include body3-500($Black);
        margin-bottom: 8px;
      }
      >input{
        border: 1px solid $Gray4;
        border-radius: 8px;
        width: 100%;
        height: 56px;
        padding: 16px;
        @include body2-400;
      }
      >input::placeholder {
        @include body2-400($Gray3);
      }
      > input:disabled {
        background: $Gray5;
        border: 1px solid $Gray4;
      }
      &:nth-child(3) {
        margin-bottom: 0;
      }
    }
    > p {
      width: 100%;
      text-align: center;
      @include body3-500($Red400);
      margin-top: 24px;
      > span {
        margin-left: 8px;
        color: $Mint500;
      }
    }
  }
}