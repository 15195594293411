
          @import "src/assets/scss/colors.scss";
          @import "src/assets/scss/mixins.scss";
        
.rehabCardProgram {
  width: 272px;
  height: 350px;
  cursor: pointer;
  border-radius: 14px;
  margin: 0 16px 16px 0;
  transition: ease-out all .3s ;
  .programItemWrapper{
    position: relative;
    .iconBox {
      position: absolute;
      z-index: 1;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 9px;
      margin-right: 10px;
      right: 0;
      > div {
        width: 28px;
        height: 28px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 10px 0 0 0;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .programItemImgBox{
      position: relative;
      width: 272px;
      height: 272px;
      display: flex;
      font-size: 0;
      flex-wrap: wrap;
      background: $Gray5;
      border-radius: 8px;
      transition: ease-out all .3s ;
      .programItem{
        width: 50%;
        height: 50%;
        .exImage{
          width: 100%;
          height: 100%;
        }
      }

      .programItem:first-child {
        .exImage {
          border-top-left-radius: 8px;
        }
      }

      .programItem:nth-child(2) {
        .exImage {
          border-top-right-radius: 8px;
        }
      }
      .programItem:nth-child(3) {
        .exImage {
          border-bottom-left-radius: 8px;
        }
      }
      .programItem:nth-child(4) {
        .exImage {
          border-bottom-right-radius: 8px;
        }
      }
    }
    .programTitleBox{
      width: 272px;
      min-height: 75px;
      position: absolute;
      display: flex;
      align-items: flex-start;
      padding: 8px;
      transition: ease-out all .3s;
      border-bottom-right-radius: 8px;
      border-bottom-left-radius: 8px;
      > img {
        margin-right: 9px;
      }
      .programInfo {
        > p {
          max-width: 215px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          @include small-medium($Mint500);
          span{
            @include small-medium($Mint500);
          }
        }
        >h6{
          max-width: 215px;
          margin-bottom: 8px;
          @include body3-600($Gray900);
          font-weight: 600;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          word-wrap: break-word;
        }
        .programReferenceBox{
          > img {
            width: 16px;
            height: 16px;
            margin-right: 2px;
          }
          display: flex;
          align-items: center;
          height: 14px;
          @include small-medium($Gray600);
          > span {
            display: flex;
            align-items: center;
            > span {
              &.dot {
                margin: 0 4px;
              }
              &.name {
                margin-left: 4px;
              }
            }
          }
        }
      }

    }

  }
  &:hover {
    box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.08);
  }
}
.rehabCardProgram:nth-child(3n) {
  margin-right: 0
}