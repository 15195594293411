
          @import "src/assets/scss/colors.scss";
          @import "src/assets/scss/mixins.scss";
        
.modalExDetail {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 999;
  > div {
    width: 970px;
    height: 712px;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: $White;
    border-radius: 16px;
    box-shadow: -4px -4px 8px rgba(0, 0, 0, 0.08), 8px 8px 16px rgba(0, 0, 0, 0.16);
    .header {
      height: 54px;
      background: $White;
      border-bottom: 1px solid $Gray400;
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
      > p {
        line-height: 54px !important;
        text-align: center;
        @include heading4($Black);
      }
      > img {
        position: absolute;
        top: 15px;
        right: 24px;
        cursor: pointer;
      }
    }
    .body {
      overflow-y: auto;
      width: 100%;
      padding: 34px 126px 80px 126px;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
      &::-webkit-scrollbar {
        display: none; /* Chrome, Safari, Opera*/
      }
      > img {
        width: 30px;
        height: 30px;
        display: block;
        margin: 0 0 8px auto;
        cursor: pointer;
      }
      .title {
        width: 100%;
        height: 85px;
        border-bottom: 1px solid $Black;
        .type {
          @include body2-600($Gray700);
          margin-bottom: 4px;
        }
        .name {
          @include heading5($Black);
          padding-bottom: 16px;
        }
      }
      .content {
        width: 100%;
        height: 315px;
        border-bottom: 1px solid $Gray300;
        padding: 40px 0 48px 0;
        margin-bottom: 74px;
        > div {
          display: flex;
          margin-bottom: 16px;
          .contentTitle {
            width: 120px;
            margin-right: 8px;
            @include body3-500($Gray600);
          }
          .contents {
            display: flex;
            align-items: center;
            width: 590px;
            @include body3-500($Gray900);
            word-break: keep-all;
            &.colorDot::before {
              display: block;
              content: '';
              width: 8px;
              height: 8px;
              border-radius: 50%;
              margin-right: 8px;
            }
            &.green::before {
              background: $Green500;
            }
            &.whiteGreen::before {
              background: $Green300;
            }
            &.yellow::before {
              background: $Yellow500;
            }
            &.orange::before {
              background: $Orange;
            }
            &.red::before {
              background: $Red500;
            }
          }
        }
      }
      video {
        display: block;
        width: 653px;
        height: 367px;
        border-radius: 4px;
        margin: 0 auto 22px auto;
      }
    }
  }
}