
          @import "src/assets/scss/colors.scss";
          @import "src/assets/scss/mixins.scss";
        
.dateSelectEdit {
  display: flex;
  justify-content: space-between;
  width: 1280px;
  padding: 0 50px;
  margin: 52px auto 250px auto;
  .rightWrapper {
    width: 880px;
    .type {
      width: 540px;
      height: 56px;
      align-items: center;
      display: flex;
      border: 1px solid $Gray300;
      border-radius: 100px;
      margin: 0 auto;
      > div {
        width: 50%;
        height: 50px;
        padding: 14px 0;
        margin-right: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 56px;
        cursor: pointer;
        >p{
          @include heading4Thin($Black);
        }
        &.active {
          background: $Gray2;
          padding: 4px;
          margin-left: 4px;
          height: 90%;
          >p{
            @include heading4Regular($White);
          }
        }
      }

    }
    .optionDateWrapper{
      width: 90%;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .optionDateReset{
        min-width: 200px;
        height: 100%;
        margin-right: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        >button{
          cursor: inherit;
          >p{
            @include body2-500($Gray300);
            text-decoration: underline;
          }
        }
        .resetActive{
          cursor: pointer;
          p {
            color: $Gray2;
          }
        }
      }
      .optionCalender{
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        .prev,
        .next {
          width: 24px;
          height: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }
        .prev {
          background: url('https://resources.mora.kr/static/app/mora-ex/assets/web/G_arrow_left_sharp.svg') no-repeat;
        }
        .prev.disable {
          background: url('https://resources.mora.kr/static/app/mora-ex/assets/web/LG_arrow_left_sharp.svg') no-repeat;
          pointer-events: none;
        }
        .next {
          background: url('https://resources.mora.kr/static/app/mora-ex/assets/web/G_arrow_right_sharp.svg') no-repeat;
        }

        .dualBox{
          display: flex;
          width: 100%;
          height: 380px;
          justify-content: center;
          margin-bottom: 45px;
          margin-top: 40px;
          z-index: 1;
          .dualCalendarLeftArea{
            margin-right: 56px;
            width: 336px;
            .dualym{
              position: relative;
              display: flex;
              justify-content: center;
              height: 32px;
              align-items: center;
              margin-bottom: 16px;
              .prev {
                position: absolute;
                left: 0;
              }
              > p {
                @include heading4Bold($Black);
                &.year {
                  margin-right: 8px;
                }
              }
            }
            .dualmonthBox{
              display: flex;
              flex-direction: column;
              .dualweekBox{
                height: 32px;
                display: flex;
                flex-direction: row;
                border-radius: 8px;
                margin-top: 1px;
                >div{
                  width: calc(100% / 7);
                  min-width: 48px;
                  height: 100%;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  >p{
                    @include body2-500($Gray900);
                  }
                }
                &:first-child {
                  > div {
                    > p {
                      @include body3-500($Gray600);
                    }
                  }
                }
              }
              .dualweekBox div:last-child{
                border-right: none;
              }
              .dualdateBox .dualaWeekBox div:last-child{
                border-right: none;
              }
              .dualdateBox{
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                .dualaWeekBox{
                  position: relative;
                  width: 100%;
                  display: flex;
                  background: $White;
                  >div{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: calc(100% / 7);
                    min-width: 48px;
                    min-height: 50px;
                    cursor: pointer;
                    >p{
                      @include body2-500($Gray900);
                    }
                  }
                  .sunDayColor > div p{
                    //color: $Alert;
                  }
                  .serialMiddle {
                    > div {
                      > p {
                        background: $Gray200 !important;
                        width: 48px !important;
                        height: 46px !important;
                        border-radius: 0 !important;
                        color: $Gray900 !important;
                      }
                    }

                  }
                  .serialStart {
                    > div {
                      position: absolute;
                      margin-right: -2px;
                      width: 48px;
                      height: 46px;
                      background: linear-gradient(to left, $Gray200 50%, $White 50%);
                      > p {
                        @include body2-500($White);
                        width: 48px;
                        height: 46px;
                        border-radius: 100px;
                        background: black;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                      }
                    }

                  }
                  .serialEnd {
                    > div {
                      width: 48px;
                      height: 46px;
                      background: linear-gradient(to left, $White 50%, $Gray200 50%);

                      > p {
                        @include body2-500($White);
                        width: 48px;
                        height: 46px;
                        border-radius: 100px;
                        background: black;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                      }
                    }

                  }
                  .disable{
                    pointer-events: none;
                    background: $White !important;
                    > div {
                      >p{
                        background: $White !important;
                        font-size: 16px !important;
                        font-weight: 500 !important;
                        line-height: 25.6px !important;
                        color: $Gray400 !important;
                        text-decoration: line-through;
                      }
                    }

                  }
                  .disableCircle{
                    pointer-events: none;
                    > div {
                      >p{
                        @include body2-500($White);
                        width: 46px;
                        height: 46px;
                        border-radius: 100px;
                        background: $Gray400;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        text-decoration: line-through;
                      }
                    }
                  }
                  .declaredDatesColor{
                    > div {
                      >p{
                        color: #A8B5FF !important;
                      }
                    }

                  }
                  .nextDate{
                    pointer-events: none;
                    background: $Gray6 !important;
                    > div {
                      >p{
                        color: $Gray3 !important;
                        border: none !important;
                      }
                    }

                  }
                  .today{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    > div {
                      >p{
                        letter-spacing: -0.02em;
                      }
                    }

                  }
                  .selectDate{
                    > div {
                      >p{
                        @include body2-500($White);
                        width: 46px;
                        height: 46px;
                        border-radius: 100px;
                        background: black;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                      }
                    }

                  }
                }
              }
            }
          }
          .dualCalendarRightArea{
            width: 336px;
            .dualym{
              position: relative;
              display: flex;
              justify-content: center;
              height: 32px;
              align-items: center;
              margin-bottom: 16px;
              .next {
                position: absolute;
                right: 0;
                &.disable {
                  background: url('https://resources.mora.kr/static/app/mora-ex/assets/web/LG_arrow_right_sharp.svg') no-repeat;
                  pointer-events: none;
                }
              }
              >p{
                @include heading4Bold($Black);
                &.year {
                  margin-right: 8px;
                }
              }
            }
            .dualmonthBox{
              display: flex;
              flex-direction: column;
              .dualweekBox{
                height: 32px;
                display: flex;
                flex-direction: row;
                border-radius: 8px;
                >div{
                  min-width: 48px;
                  height: 100%;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  >p{
                    @include body2-500($Gray900);
                  }
                }
                &:first-child {
                  > div {
                    > p {
                      @include body3-500($Gray600);
                    }
                  }
                }
              }
              .dualweekBox div:last-child{
                border-right: none;
              }
              .dualdateBox .dualaWeekBox div:last-child{
                border-right: none;
              }
              .dualdateBox{
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                .dualaWeekBox{
                  position: relative;
                  width: 100%;
                  display: flex;
                  background: $White;
                  >div{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: calc(100% / 7);
                    min-width: 48px;
                    min-height: 50px;
                    cursor: pointer;
                    >div {
                      >p{
                        @include body2-500($Gray900);
                      }
                    }
                  }
                  .serialMiddle {
                    > div {
                      > p {
                        background: $Gray200 !important;
                        width: 48px !important;
                        height: 46px !important;
                        border-radius: 0 !important;
                        color: $Gray900 !important;
                      }
                    }

                  }
                  .serialStart {
                    > div {
                      position: absolute;
                      margin-right: -2px;
                      width: 48px;
                      height: 46px;
                      background: linear-gradient(to left, $Gray200 50%, $White 50%);
                      > p {
                        @include body2-500($White);
                        width: 48px;
                        height: 46px;
                        border-radius: 100px;
                        background: black;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                      }
                    }

                  }
                  .serialEnd {
                    > div {
                      width: 48px;
                      height: 46px;
                      background: linear-gradient(to left, $White 50%, $Gray200 50%);

                      > p {
                        @include body2-500($White);
                        width: 48px;
                        height: 46px;
                        border-radius: 100px;
                        background: black;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                      }
                    }

                  }
                  .selectNextDate{
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    > div {
                      >p{
                        border-radius: 50%;
                        border: 1px solid $Primary;
                        width: 18px;
                        height: 18px;
                        letter-spacing: -0.02em;
                        @include small-regular;
                      }
                    }

                  }
                  .selectDate{
                    > div {
                      >p{
                        @include body2-500($White);
                        width: 46px;
                        height: 46px;
                        border-radius: 100px;
                        background: black;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                      }
                    }

                  }
                  .disable{
                    pointer-events: none;
                    background: $White !important;
                    > div {
                      >p{
                        background: $White !important;
                        font-size: 16px !important;
                        font-weight: 500 !important;
                        line-height: 25.6px !important;
                        color: $Gray400 !important;
                        text-decoration: line-through;
                      }
                    }

                  }
                  .disableCircle{
                    pointer-events: none;
                    > div {
                      >p{
                        @include body2-500($White);
                        width: 46px;
                        height: 46px;
                        border-radius: 100px;
                        background: $Gray400;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        text-decoration: line-through;
                      }
                    }
                  }
                }
              }
            }
          }
          .disableYm{
            pointer-events: none;
            >p{
              color: $Gray4 !important;
            }
          }
        }
      }
      .optionDateBtn{
        height: 64px;
        position: absolute;
        bottom: 15px;
        .optionDateSuccess{
          margin-left: -95px;
          width: 1180px;
          height: 100%;
          display: flex;
          >button{
            width: 964px;
            height: 100%;
            background: $Primary200;
            border-radius: 8px;
            cursor: inherit;
            >p{
              @include body2-500($White);
            }
            &:first-child {
              background: $White;
              width: 200px;
              margin-right: 16px;
              border: 1px solid $Primary;
              cursor: pointer;
              p {
                color: $Primary;
              }
            }
          }
          .daysActive{
            background: $Primary;
            cursor: pointer;
            >p{
              color: $White;
            }
          }
        }
      }
    }
  }
  .leftWrapper {
    .floatContainer {
      position: sticky;
      top: 148px;
      width: 280px;
      padding: 16px;
      box-shadow: 0 0 2px rgba(0, 0, 0, 0.06), 0 6px 14px rgba(0, 0, 0, 0.15);
      border-radius: 8px;
      .planTitle {
        @include heading4Regular($Black);
        margin-bottom: 24px;
      }
      .newPlanTitle {
        @include heading4Regular($Black);
        margin-bottom: 8px;
      }
      .newDate {
        @include body-regular($Gray600);
        margin-bottom: 24px;
      }
      .datesToCopy {

        .datesToAssign {
          @include body3-500($Gray900);
          margin-bottom: 8px;
        }
        .planDates {
          display: flex;
          align-items: center;
          height: 23px;
          > img {
            margin-right: 8px;
          }
          .dateWrap {
            @include body3-600($Gray900);
          }
          .primaryDate {
            font-size: 14px !important;
            font-weight: 500 !important;
            color: $Mint600 !important;
          }
          .noDate {
            @include body-regular($Gray600);
          }
        }
        &.borderBottom {
          border-bottom: 1px solid $Gray400;
          padding-bottom: 16px;
          margin-bottom: 16px;
        }
        &.height {
          display: flex;
          align-items: center;
          height: 53px;
        }
      }
      .btns {
        display: flex;
        justify-content: space-between;
        align-content: center;
        margin-top: 40px;
        > button {
          width: 119px;
          height: 52px;
          border-radius: 4px;
          &.backBtn {
            background: $Mint100;
            @include body2-600($Mint600);
            cursor: pointer;
          }
          &.nextBtn {
            background: $Gray300;
            @include body2-600($White);
            cursor: auto;
            &.active {
              background: $Mint500;
              cursor: pointer;
            }
          }
        }

      }

    }
  }
}