
          @import "src/assets/scss/colors.scss";
          @import "src/assets/scss/mixins.scss";
        
.kcbService {
  width: 100%;
  height: 100%;
  > div {
    width: 100%;
    height: 120px;
    overflow-y: scroll;
    overflow-x: hidden;
    > div {
      div,
      span {
        @include small-regular($Gray600);
      }
    }
    &::-webkit-scrollbar{
      width: 5px;
    }
    &::-webkit-scrollbar-thumb{
      /* 스크롤바 막대 높이 설정 */
      //height: 40px;
      background: $Mint500;
      //opacity: 0.16;
      /* 스크롤바 둥글게 설정 */
      border-radius: 10px;
      background-clip: padding-box;
      border: 1px solid transparent;
    }
  }
}