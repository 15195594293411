
          @import "src/assets/scss/colors.scss";
          @import "src/assets/scss/mixins.scss";
        
.planDropdown {
  width: 100%;
  height: 100%;
  .filterWrapper {
    height: 100%;
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    .dropdownBig {
      height: 100%;
      width: 100%;
      .listSelected {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: $White;
        width: 100%;
        border-radius: 4px;
        border: 1px solid $Gray300;
        &.borderPrimary:active {
          border: 1px solid $Primary !important;
          box-shadow: -4px -4px 8px rgba(0, 0, 0, 0.04), 8px 8px 16px rgba(0, 0, 0, 0.08);
        }
        .selected {
          position: relative;
          display: flex;
          align-items: center;
          width: 100%;
          height: 100%;
          padding: 15px 24px 15px 16px;
          cursor: pointer;
          .statusTag {
            width: 67px;
            margin-right: 16px;
            .status {
              @include small-semi-bold($Gray800);
              padding: 2px 6px;
              border-radius: 4px;
              &.gray {
                background: $Gray300;
              }
              &.blue {
                background: $Mint200;
              }
              &.yellow {
                background: $Yellow200;
              }
              &.lightBlue {
                background: $Mint100;
              }
              &.pink {
                background: $Red100;
              }
            }
          }

          .name {
            @include body2-500($Gray900);
          }
          .bar {
            margin: 0 8px;
            @include body2-400($Gray300);
          }
          .rate {
            margin: 0 4px 0 0;
            @include body2-400($Gray600);
          }
          .rateNo {
            @include body2-400($Gray900);
          }
          .date {
            @include body2-400($Gray900);
          }
          > img {
            position: absolute;
            right: 20px;
            width: 8px;
            height: 5px;
            transition: ease-out all .5s ;
            &.rotate {
              transform: rotateX(180deg);
            }
          }
        }
        .noPlan {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          height: 100%;
          background: $Gray100;
          border-radius: 4px;
          padding: 15px 24px 15px 16px;
          cursor: auto;
          > span {
            @include body2-400($Gray600);
          }
        }
      }
      .listSelected.borderPrimary {
        border: 1px solid $Primary;
      }
      .filterLists {
        position: absolute;
        border-radius: 4px;
        left: 0;
        top: 54px;
        width: 100%;
        max-height: 284px;
        overflow-y: auto;
        background-color: $White;
        z-index: 12;
        box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 6px 14px rgba(0, 0, 0, 0.15);
        > div {
          display: flex;
          align-items: center;
          height: 52px;
          padding: 10px 14px;
          cursor: pointer;
          .statusTag {
            width: 67px;
            margin-right: 16px;
            .status {
              @include small-semi-bold($Gray800);
              padding: 2px 6px;
              border-radius: 4px;
              &.gray {
                background: $Gray300;
              }
              &.blue {
                background: $Mint200;
              }
              &.yellow {
                background: $Yellow200;
              }
              &.lightBlue {
                background: $Mint100;
              }
              &.pink {
                background: $Red100;
              }
            }
          }
          .name {
            @include body2-500($Gray900);
          }
          .bar {
            margin: 0 8px;
            @include body2-400($Gray300);
          }
          .rate {
            margin: 0 4px 0 0;
            @include body2-400($Gray600);
          }
          .rateNo {
            @include body2-400($Gray900);
          }
          .date {
            @include body2-400($Gray900);
          }
          &.active {
            background: $Gray100 !important;
          }
          &:hover {
            background: $Gray100;
          }
          &:active {
            background: $Gray200;
          }
        }
        &::-webkit-scrollbar {
          /* 스크롤바 막대 너비 설정 */
          width: 10px;
        }

        &::-webkit-scrollbar-thumb {
          /* 스크롤바 막대 높이 설정 */
          background: $Mint400;
          /* 스크롤바 둥글게 설정 */
          border-radius: 100px;
          background-clip: padding-box;
          border: 3px solid transparent;
        }

      }
    }
  }
}