
          @import "src/assets/scss/colors.scss";
          @import "src/assets/scss/mixins.scss";
        
.content {
  width: 400px;
  margin: 88px auto 100px auto;
  .signUpImg {
    margin-bottom: 16px;
  }
  .introWrapper{
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    hr {
      border: 0;
      height: 1px;
      width: 400px;
      margin-left: 0;
      background-color: $Gray300;
    }
    >div{
      .introTitle{
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
        >h1{
          @include heading2-thin($Gray900);
          margin-bottom: 2px;
        }
        >p{
          @include body3-500($Gray600);
        }
      }
      .introDescBox{
        display: flex;
        flex-direction: column;
        margin-bottom: 40px;
        margin-top: 26px;
        >div{
          display: flex;
          align-items: center;
          margin-bottom: 8px;
          >img{
            margin-right: 8px;
          }
          >p{
            @include body3-500($Gray800);
          }
        }
        >div:last-child{
          margin-bottom: 0;
        }
      }
      .btnWrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .introBottom{
          width: 100px;
          height: 41px;
          border-radius: 4px;
          background: $Mint100;
          @include body-regular($Mint500);

        }
        .introBtnBox{
          width: 292px;
          height: 41px;

        }
      }

    }
  }
}

@include mobile {
  .content {
    width: 360px;
    overflow-x: hidden;
    .introWrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      > div {
        width: 100%;
      }
      .btnWrapper {
        flex-direction: column;
        > button {
          margin-bottom: 20px;
        }
      }
    }

  }
}