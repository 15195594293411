
          @import "src/assets/scss/colors.scss";
          @import "src/assets/scss/mixins.scss";
        
.succeedSubscription {
  overflow-x: hidden;
  .contentWrapper {
    display: flex;
    flex-direction: column;
    width: 1180px;
    margin: 0 auto;
    .title {
      display: flex;
      align-items: center;
      margin: 40px 0 104px 0;
      @include body3-500($Gray600);
      .back {
        cursor: pointer;
      }
      .here {
        @include body3-600($Gray900);
      }
      > img {
        margin: 0 4px;
      }
    }
    .content {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      > img {
        width: 308px;
        height: 321px;
        margin-right: 40px;
      }
      > div {
        > p {
          @include heading6SB($Black);
          margin-bottom: 32px;
        }
        .list {
          margin-bottom: 60px;
          > p {
            @include body2-500($Gray600);
            margin-bottom: 4px;
            &::before {
              display: inline-block;
              content: '';
              width: 4px;
              height: 4px;
              background: $Gray500;
              border-radius: 50%;
              margin-right: 6px;
              margin-bottom: 3px;
            }
          }
        }
        .btnWrapper {
          > button {
            width: 216px;
            height: 56px;
            border-radius: 4px;
            &.btn1 {
              background: $Mint100;
              @include subHeading($Primary);
              margin-right: 8px;
            }
            &.btn2 {
              background: $Primary;
              @include subHeading($White);
            }
          }
        }
      }
    }
  }
}