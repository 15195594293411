
          @import "src/assets/scss/colors.scss";
          @import "src/assets/scss/mixins.scss";
        
.programCard {
  width: 100%;
  height: 132px;
  display: flex;
  justify-content: space-between;
  padding: 16px 0;
  border-bottom: 1px solid $Gray200;
  cursor: pointer;
  .imageBox {
    width: 100px;
    height: 100px;
    margin-right: 16px;
    background: $Gray6;
    border-radius: 4px;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    background: $Gray5;
    transition: ease-out all .3s ;
    .programItem{
      width: 50%;
      height: 50%;
      .exImage{
        width: 100%;
        height: 100%;
      }
    }
    .programItem:first-child {
      .exImage {
        border-top-left-radius: 4px;
      }
    }

    .programItem:nth-child(2) {
      .exImage {
        border-top-right-radius: 4px;
      }
    }

    .programItem:nth-child(3) {
      .exImage {
        border-bottom-left-radius: 4px;
      }
    }

    .programItem:nth-child(4) {
      .exImage {
        border-bottom-right-radius: 4px;
      }
    }

    > img {
      position: absolute;
      bottom: 6px;
      right: 6px;
      cursor: pointer;
    }
  }
  .exerciseContent {
    width: 347px;
    display: flex;
    flex-direction: column;
    margin-right: 16px;
    > p {
      word-break: keep-all;
      .tags {
        margin-bottom: 4px;
        @include small-medium($Gray2);
        > span {
          @include small-regular($Gray3);
        }
      }
    }
    .exerciseTitle {
      height: 54px;
      @include heading4Regular($Black);
      margin-bottom: 6px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    .time {
      display: flex;
      align-items: center;
      >img {
        margin-right: 4px;
      }
      > span {
        @include small-medium($Gray600);
      }
    }

  }
  &:last-child {
    border-bottom: 0;
  }

}