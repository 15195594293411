
          @import "src/assets/scss/colors.scss";
          @import "src/assets/scss/mixins.scss";
        
.buttonPrimary {
  display: flex;
  align-items: center;
  justify-content: center;
  background: $Primary;
  @include body2-600($White);
  border-radius: 4px;
  margin: 0 auto;
  cursor: pointer !important;
  transition: ease all .1s;
  > img {
    margin-right: 8px;
  }

  &.btnDisabled {
    background: $Gray4;
    cursor: auto !important;
    &:hover {
      background: $Gray4;
    }
  }

  &.thin {
    @include body2-400($White);
  }

  &.small {
    @include body3-600($White);
  }

  &:hover {
    background: $Mint400;
  }

  &:active {
    background: $Mint700;
  }


}