
          @import "src/assets/scss/colors.scss";
          @import "src/assets/scss/mixins.scss";
        
.subscription {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 84px;
  .adminBody{
    width: 100%;
    background-position: top;
    background-size: contain;
    .emptyContent{
      width: 100%;
      height: 65px;
    }
    .title {
      display: flex;
      align-items: center;
      width: 1180px;
      margin: 40px auto 24px auto;
      @include body3-500($Gray600);
      .back {
        cursor: pointer;
      }
      .here {
        @include body3-600($Gray900);
      }
      > img {
        margin: 0 4px;
      }
    }
    .contentWrapper {
      width: 100%;
      min-height: 736px;
      display: flex;
      flex-direction: column;
      > div {
        width: 1180px;
        min-height: 1049px;
        display: flex;
        flex-direction: column;
        align-items: center;
        background: $White;
        border-radius: 8px;
        margin: 0 auto 120px auto;
        padding: 0;
        .description {
          @include body-regular($Black);
        }
        .serviceUsing {
          width: 100%;
          height: 153px;
          padding: 24px;
          margin-top: 24px;
          border: 1px solid $Gray5;
          border-radius: 8px;
          .serviceUsingTitle {
            @include body2-600($Gray800);
            margin-bottom: 16px;
          }
          .serviceUsingName {
            @include title2Thin;
            margin-bottom: 8px;
          }
          .serviceUsingDate {
            @include body3-500($Gray2);
          }
        }
        .servicePeriod {
          width: 560px;
          height: 48px;
          display: flex;
          border-radius: 40px;
          background: $Gray200;
          margin: 0 0 40px 0;
          cursor: pointer;
          > div {
            position: relative;
            width: 280px;
            display: flex;
            justify-content: center;
            align-items: center;

            > span {
              @include body2-500($Gray600);
            }

            &.active {
              width: 276px;
              height: 40px;
              background: $Gray600;
              border-radius: 56px;
              margin: 4px;

              > span {
                @include body2-600($White);
              }
            }
            .redBox {
              position: absolute;
              right: 50px;
              display: flex;
              align-items: center;
              justify-content: center;
              width: 55px;
              height: 24px;
              background: $Red500;
              border-radius: 4px;
              > span {
                @include small-semi-bold($White);
              }
              &::after {
                content: "";
                position: absolute;
                left: 0;
                top: 50%;
                width: 5px;
                height: 0px;
                border: 10px solid transparent;
                border-right-color: $Red500;
                border-left: 0px;
                margin-top: -10px;
                margin-left: -11px;
              }
            }
          }

        }
        .servicePriceList {
          width: 100%;
          height: 321px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 24px;
          > div {
            width: 283px;
            height: 100%;
            border: 1px solid $Gray300;
            border-radius: 8px;
            padding: 24px 16px;
            cursor: pointer;
            > p {
              display: flex;
              justify-content: center;
              align-items: center;
              height: 32px;
              width: 100%;
              @include subHeading($Gray900);
              border-radius: 4px;
              padding-top: 1px;

              &.yellow {
                background: $Yellow200;
              }
              &.green {
                background: #DAF6E1;
              }
              &.blue {
                background: $Mint200;
              }
              &.mint {
                background: $Mint300;
              }
            }
            .priceGuide {
              padding: 16px 0 8px 0;
              border-bottom: 1px solid $Gray200;
              .inquiry {
                text-align: center;
                @include body-regular;
                margin-bottom: 4px;
              }
              .contact {
                text-align: center;
                @include small-medium($Gray600);
              }
              .standard {
                height: 21px;
                @include body-regular($Gray400);
                text-decoration-line: line-through;
              }
              .monthlyDiscounted {
                width: fit-content;
                position: relative;
                display: flex;
                align-items: center;
                margin-bottom: 4px;
                @include heading2-thin($Black);
                .discountedPrice {
                  display: flex;
                  align-items: center;
                  > span {
                    @include body3-500($Gray600);
                    padding-left: 7px;
                  }
                }
                .discountRedBox {
                  position: absolute;
                  right: -59px;
                  top: 9px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  width: 50px;
                  height: 18px;
                  background: $Red500;
                  border-radius: 4px;
                  > span {
                    @include small-medium($White);
                  }
                  &::after {
                    content: "";
                    position: absolute;
                    left: 2px;
                    top: 70%;
                    width: 5px;
                    height: 0px;
                    border: 6px solid transparent;
                    border-right-color: $Red500;
                    border-left: 0px;
                    margin-top: -10px;
                    margin-left: -11px;
                  }
                }
              }
              .yearly {
                height: 21px;
                @include body3-500($Mint700);
                > span {
                  margin-left: 4px;
                  @include body3-600($Mint700);
                }
              }
              &.business {
                padding: 16px 0 8px 0;
              }
            }
            .limit {
              padding: 8px 0 26px 0;
              > p {
                @include body-regular($Gray600);
                > span {
                  @include body3-600($Black);
                }
                &:first-child {
                  margin-bottom: 4px;
                }
              }
              &.free {
                padding-top: 24px;
              }
            }

            > button {
              width: 100%;
              height: 52px;
              background: $Mint100;
              @include subHeading($Mint600);
              border-radius: 4px;
            }
            .businessInquiry {
              width: 100%;
              border-top: 1px solid $Gray200;
              padding-top: 9px;
              > h6 {
                text-align: center;
                @include body-regular($Black);
                margin-bottom: 4px;
              }
              > p {
                text-align: center;
                @include small-medium($Gray600);
              }
            }
            &.active {
              border: 1px solid $Mint700;
              box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08);
              .selectBtn {
                display: flex;
                align-items: center;
                justify-content: center;
                background: $Mint700;
                @include body2-600($White);
                > img {
                  margin-right: 8px;
                }
              }
            }
            &.inactive {
              cursor: auto;
              > button {
                background: $Gray100;
                color: $Gray300;
                cursor: auto;
              }
            }
            &:first-child,
            &:last-child {
              cursor: auto;
            }
          }
          &.month {
            > div {
              .priceGuide {
                padding: 37px 0 33px 0;
                .inquiry {
                  text-align: center;
                  @include body-regular;
                  margin-bottom: 4px;
                }
                .monthlyDiscounted {
                  margin-bottom: 0;
                  > span {
                    padding-left: 7px;
                    @include body3-500($Gray600);
                  }
                }
                &.business {
                  padding: 24px 0;
                  > p {
                    @include heading5Regular($Black);
                  }

                }
              }
            }

          }
        }
        .changePolicy {
          @include body3-500($Gray800);
        }
        .servicePriceSelected {
          display: flex;
          flex-direction: column;
          width: 100%;
          background: $Gray100;
          border: 1px solid $Gray200;
          border-radius: 4px;
          padding: 24px;
          margin-top: 60px;
          .serviceName {
            width: 100%;
            text-align: right;
            @include heading4Regular($Black);
            margin-bottom: 16px;
          }
          .priceToPay {
            height: 75px;
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid $Gray300;
            margin-bottom: 20px;
            > span {
              @include heading4Regular($Black);
            }
            > div {
              display: flex;
              flex-direction: column;
              align-items: flex-end;
              .ordered {
                @include body2-500($Gray800);
                margin-bottom: 9px;
                > span {
                  color: $Gray500;
                  margin-left: 8px;
                }
              }
              .discount {
                display: flex;
                align-items: center;
                @include body2-500($Gray800);
                .percent {
                  background: $Red500;
                  padding: 2px 3px;
                  border-radius: 4px;
                  margin: 0 8px;
                  @include small-semi-bold($White);
                }
                .discounted {
                  @include body2-600($Red500);
                }
              }
            }
          }
          .priceDeposited {
            height: 75px;
            display: flex;
            justify-content: flex-end;
            border-bottom: 1px solid $Gray300;
            margin-bottom: 20px;
            > div {
              display: flex;
              flex-direction: column;
              align-items: flex-end;
              .depositToPay {
                @include body2-500($Gray800);
                margin-bottom: 9px;
                > span {
                  @include body2-600;
                  margin-left: 8px;
                }
              }
              .deposit {
                @include body-small-bold($Gray600);
                > a {
                  @include small-medium($Gray800);
                  padding: 6px 16px;
                  background: $Gray200;
                  border-radius: 4px;
                  margin-left: 8px;
                }
              }

            }
          }
          .totalPrice {
            display: flex;
            align-items: center;
            justify-content: right;
            > span {
              @include heading3($Black);
              margin-left: 8px;
            }
          }
        }
        .servicePayment {
          width: 100%;
          margin-top: 40px;
          > p {
            padding-bottom: 10px;
            @include heading3Thin($Black);
          }
          .cardInfo {
            display: flex;
            justify-content: space-between;
            width: 100%;
            border-top: 1px solid $Gray200;
            border-bottom: 1px solid $Gray200;
            padding: 24px 0;
            margin-bottom: 26px;
          }
          .cardPayTerms {
            > p {
              width: fit-content;
              display: flex;
              align-items: center;
              cursor: pointer;
              > img {
                width: 16px;
                height: 16px;
                margin-right: 12px;
              }
              > span {
                @include body3-500($Gray800);
                > span {
                  @include body-regular($Gray600);
                }
              }
            }
          }
        }

      }

    }
  }
  .patientRegistrationInputBox {
    background: $White;
    padding: 40px 24px;
    .services {
      width: 100%;
      border-bottom: 1px solid $Gray300;
      .service {
        @include body2-500($Gray800);
        margin-bottom: 8px;
      }
      .name {
        @include heading5($Black);
        margin-bottom: 24px;
      }
    }
    .bottom {
      margin: 24px 0 0 0;
      @include body2-500($Gray600);
    }
  }
  .addCardInputBox{
    width: 100%;
    background: $White;
    padding: 24px;
    .cardInfo1 {
      position: relative;
      margin-bottom: 24px;
      >p{
        @include body3-500($Black);
        margin-bottom: 4px;
      }
      .error {
        margin-bottom: 0;
        margin-top: 4px;
        padding-left: 8px;
        @include small-regular($Alert);
      }
      >input{
        border: 1px solid $Gray4;
        border-radius: 4px;
        width: 100%;
        height: 41px;
        padding: 16px;
        @include body-regular($Gray900);
      }
      >input::placeholder {
        @include body-regular($Gray3);
      }
    }
    .cardInfo2 {
      display: flex;
      justify-content: space-between;
      margin-bottom: 24px;
      > div {
        >p{
          @include body3-500($Black);
          margin-bottom: 4px;
        }
        .error {
          margin-bottom: 0;
          margin-top: 4px;
          padding-left: 8px;
          @include small-regular($Alert);
        }
        >input{
          border: 1px solid $Gray4;
          border-radius: 4px;
          width: 100%;
          height: 41px;
          padding: 16px;
          @include body-regular($Gray900);
          &.small {
            width: 95px;
          }
          &.margin8 {
            margin-right: 8px;
          }
          &.medium {
            width: 198px;
          }
        }
        >input::placeholder {
          @include body-regular($Gray3);
        }
      }

    }
    .cardInfo3 {
      >p{
        @include body3-500($Black);
        margin-bottom: 4px;
      }
      .error {
        margin-bottom: 0;
        margin-top: 4px;
        padding-left: 8px;
        @include small-regular($Alert);
      }
      >input{
        border: 1px solid $Gray4;
        border-radius: 4px;
        width: 100%;
        height: 41px;
        padding: 16px;
        @include body-regular($Gray900);
      }
      >input::placeholder {
        @include body-regular($Gray3);
      }
    }
  }
}