
          @import "src/assets/scss/colors.scss";
          @import "src/assets/scss/mixins.scss";
        
.home {
  width: 100%;
  min-width: 1200px;
  .wrapper {
    width: 100%;
    min-width: 1280px;
    height: 100%;
    min-height: 800px;
    background: $Gray100;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 0 118px 0;
    .top {
      width: 1180px;
      background: linear-gradient(270deg, #DAF5F5 0%, #FFF 100%);
      padding: 24px;
      display: flex;
      justify-content: space-between;
      border: 1px solid $Gray300;
      border-radius: 4px;
      margin-bottom: 20px;
      > p {
        width: 1084px;
        @include heading6SB($Black);
      }

    }

    .contentStatistics {
      width: 1180px;
      border: 1px solid $Gray300;
      border-radius: 4px;
      background: $White;
      padding: 32px 24px;
      .contentBoxWrapper {
        width: 100%;
        height: 93px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 24px;

      }
      .chartWrapper {
        width: 100%;
        height: 478px;
        border: 1px solid $Gray200;
        border-radius: 8px;
        margin-bottom: 24px;
        padding: 24px;
        .topTitle {
          width: 100%;
          height: 36px;
          display: flex;
          justify-content: space-between;
          margin-bottom: 24px;
          > p {
            @include body2-600;
          }
          .legend {
            display: flex;
            align-items: center;
            > p {
              @include small-semi-bold($Gray600);
              margin-right: 14px;
              &:nth-child(3) {
                margin-right: 22px;
              }
              &::before {
                display: inline-block;
                content: '';
                width: 8px;
                height: 8px;
                border-radius: 50%;
                margin-right: 4px;
              }
              &.mint::before {
                background: $Mint500;
              }
              &.darkMint::before {
                background: $Mint800;
              }
              &.lightMint::before {
                background: $Mint300;
              }
              &.orange::before {
                background: $Orange500;
              }
              &.purple::before {
                background: $Purple400;
              }
              &.lightPurple::before {
                background: $Purple200;
              }
            }
            .filterWrapper {
              position: relative;
              display: flex;
              align-items: center;
              margin-right: 8px;
              .dropdownBig {
                .listSelected {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  background: $White;
                  padding: 12px 16px 12px 14px;
                  width: 84px;
                  height: 36px;
                  cursor: pointer;
                  border-radius: 4px;
                  border: 1px solid $Gray300;
                  &:active {
                    border: 1px solid $Primary !important;
                    box-shadow: -4px -4px 8px rgba(0, 0, 0, 0.04), 8px 8px 16px rgba(0, 0, 0, 0.08);
                  }
                  >span {
                    @include body-regular($Gray800);
                  }
                  > img {
                    width: 8px;
                    height: 5px;
                    transition: ease-out all .5s;
                    &.rotate {
                      transform: rotateX(180deg);
                    }
                  }
                }
                .listSelected.borderPrimary {
                  border: 1px solid $Primary;
                  box-shadow: -4px -4px 8px rgba(0, 0, 0, 0.04), 8px 8px 16px rgba(0, 0, 0, 0.08);
                }
                .filterLists {
                  position: absolute;
                  border-radius: 4px;
                  left: 0;
                  top: 38px;
                  width: 84px;
                  background-color: $White;
                  z-index: 13;
                  filter: drop-shadow(0px 6px 20px rgba(0, 0, 0, 0.12));
                  > div {
                    display: flex;
                    align-items: center;
                    height: 44px;
                    padding: 10px 14px;
                    cursor: pointer;
                    p {
                      cursor: pointer;
                      @include body-regular($Gray800);
                    }
                    &.active {
                      background: $Mint100 !important;;
                    }
                    &:hover {
                      background: $Gray100;
                    }
                    &:active {
                      background: $Gray200;
                    }
                  }

                }
              }
            }
          }
        }
        &.assignmentChart {
          margin-bottom: 84px;
        }
      }
      .roundChartWrapper {
        width: 100%;
        height: 168px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 24px;
        .roundChartBox {
          position: relative;
          width: 558px;
          height: 100%;
          display: flex;
          justify-content: space-between;
          border: 1px solid $Gray200;
          border-radius: 8px;
          padding: 24px;
          .title {
            display: flex;
            flex-direction: column;
            > img {
              margin-bottom: 16px;
              width: 44px;
              height: 44px;
            }
            > p {
              @include body3-500($Gray800);
              > span {
                @include body-bold;
              }
            }
          }
          > span {
            position: absolute;
            right: 57px;
            top: 72px;
            @include title2($Mint500);
          }
        }
      }
      .barChartWrapper {
        width: 100%;
        height: 255px;
        border: 1px solid $Gray200;
        border-radius: 8px;
        padding: 24px;
        .barTitleBox {
          width: 100%;
          margin-bottom: 40px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .title {
            @include body3-600($Gray800);
          }
          .green {
            @include body-small-bold($Green500);
          }
          .red {
            @include body-small-bold($Red500);
          }
          .gray {
            @include body-small-bold($Gray600);
          }
        }
        .barChartBox {
          margin-bottom: 25px;
          .barTitle {
            display: flex;
            align-items: center;
            margin-bottom: 12px;
            > span {
              @include small-regular($Gray900);
              margin-right: 4px;
              > span {
                @include small-semi-bold;
              }
            }
            > p {
              background: $Gray200;
              border-radius: 4px;
              padding: 1px 6px 0 6px;
              @include small-bold($Gray800);
            }
            .barChangeRate {
              margin-left: 8px;
              display: flex;
              justify-content: center;
              align-items: center;
              > span {
                @include small-medium;
                &.green {
                  color: $Green500;
                }
                &.red {
                  color: $Red500;
                }
              }
            }
          }
          .barProgress {
            position: relative;
            width: 100%;
            height: 8px;
            background: $Gray200;
            border-radius: 4px;
            margin-bottom: 4px;
            > div {
              position: absolute;
              height: 100%;
              border-radius: 4px;
            }
            .initPainBar {
              background: $Gray400;
            }
            .pain0 {
              background: $Pain0;
            }
            .pain1 {
              background: $Pain1;
            }
            .pain2 {
              background: $Pain2;
            }
            .pain3 {
              background: $Pain3;
            }
            .pain4 {
              background: $Pain4;
            }
            .pain5 {
              background: $Pain5;
            }
            .pain6 {
              background: $Pain6;
            }
            .pain7 {
              background: $Pain7;
            }
            .pain8 {
              background: $Pain8;
            }
            .pain9 {
              background: $Pain9;
            }
            .pain10 {
              background: $Pain10;
            }
          }
          .barRange {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            > span {
              @include small-medium($Gray500);
            }
          }
        }
        .barChartBox:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

}