
          @import "src/assets/scss/colors.scss";
          @import "src/assets/scss/mixins.scss";
        
.rehabListSkeleton {
  display: flex;
  margin: 40px auto 0 auto;
  width: 1180px;
  .rightWrapper {
    margin: 0 20px 0 0;
    .contentWrapper {
      display: flex;
      .content1 {
        width: 280px;
        margin-right: 20px;
        .content1Top {
          width: 100%;
          margin-bottom: 16px;
          > div {
            display: flex;
            align-items: center;
            @include skeletonBox(280, 48);
            margin-bottom: 8px;
            > div {
              &:nth-child(1) {
                @include skeletonBox(52, 23, $Gray100);
                margin-right: 4px;
                margin-left: 16px;
              }
              &:nth-child(2) {
                @include skeletonBox(40, 23, $Gray100);
              }
            }
            &:nth-child(4) {
              margin-bottom: 27px;
            }
          }
          > hr {
            border: 0;
            height: 1px;
            background-color: $Gray200;
          }
        }
        .content1Bottom {
          padding: 8px 0 18px 0;
          .content1BottomContent1 {
            width: 100%;
            height: 49px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid $Gray200;
            margin-bottom: 16px;
            > div:first-child {
              width: 64px;
              height: 27px;
              background: $Gray200;
              border-radius: 4px;
            }
            > div:last-child {
              width: 79px;
              height: 33px;
              background: $Gray200;
              border-radius: 4px;
            }
          }
          .content1BottomContent2 {
            width: 100%;
            .content1BottomContent2_1 {
              width: 100%;
              height: 32px;
              background: $Gray200;
              border-radius: 4px;
              margin-bottom: 16px;
            }
            .content1BottomContent2_2 {
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-bottom: 16px;
              > div:first-child {
                width: 40px;
                height: 24px;
                background: $Gray200;
                border-radius: 4px;
              }
              > div:last-child {
                width: 20px;
                height: 20px;
                background: $Gray200;
                border-radius: 4px;
              }
            }
            .content1BottomContent2_3 {
              .content1BottomContent2_3_1 {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                > div:first-child {
                  display: flex;
                  align-items: center;
                  margin-bottom: 10px;
                  > div:first-child {
                    width: 24px;
                    height: 24px;
                    background: $Gray200;
                    border-radius: 4px;
                    margin-right: 8px;
                  }
                  > div:last-child {
                    width: 71px;
                    height: 21px;
                    background: $Gray200;
                    border-radius: 4px;
                  }
                }
                > div:last-child {
                  width: 32px;
                  height: 21px;
                  background: $Gray200;
                  border-radius: 4px;
                }
              }
            }
          }
        }
      }
      .content2 {
        width: 880px;
        height: 2160px;
        padding: 16px;
        background: $Gray100;
        margin-bottom: 121px;
        .content2_1 {
          width: 100%;
          height: 41px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .content2_1_box {
            display: flex;
            align-items: center;
            .content2_1_1 {
              &:first-child {
                @include skeletonBox(328, 40);
              }
              &:nth-child(2) {
                @include skeletonBox(85, 40);
                margin: 0 8px;
              }
              &:nth-child(3) {
                @include skeletonBox(40, 40);
              }
            }
          }

          .content2_1_2 {
            @include skeletonBox(136, 40);
          }
        }

        .listWrapper {
          display: flex;
          flex-wrap: wrap;
          margin-top: 24px;
          .rehabCardExercise {
            position: relative;
            @include skeletonBox(272, 350);
            cursor: pointer;
            border-radius: 14px;
            margin: 0 16px 16px 0;
            transition: ease-out all .3s ;
            .exerciseItemWrapper{
              position: relative;
              height: 350px;
              .iconBox {
                position: absolute;
                z-index: 1;
                display: flex;
                justify-content: space-between;
                align-items: center;
                right: 10px;
                @include skeletonBox(28, 28, $Gray300);
                border-radius: 50%;
                margin: 10px 0 0 0;
              }
              .exerciseItem {
                position: relative;
                @include skeletonBox(272, 350);
                border-radius: 8px;
                transition: ease-out all .3s ;
                background: $Gray5;
                .exImage {
                  height: 350px;
                  @include skeletonBox(272, 272);
                  border-radius: 8px;
                }
                .exerciseTitleBox{
                  @include skeletonBox(272, 83);
                  position: absolute;
                  display: flex;
                  align-items: flex-start;
                  padding: 8px;
                  background: rgba(255, 255, 255, 0.80);
                  backdrop-filter: blur(2px);
                  border-bottom-right-radius: 8px;
                  border-bottom-left-radius: 8px;
                  border-top-right-radius: 0px;
                  border-top-left-radius: 0px;
                  .icon {
                    @include skeletonBox(32, 32);
                    margin-right: 9px;
                  }
                  .exNoteBox {
                    > p {
                      @include skeletonBox(32, 18);
                      max-width: 215px;
                      text-overflow: ellipsis;
                      overflow: hidden;
                      white-space: nowrap;
                      margin-bottom: 4px;
                    }
                    > h6{
                      @include skeletonBox(215, 23);
                      margin-bottom: 4px;
                      font-weight: 600;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      display: -webkit-box;
                      -webkit-line-clamp: 2;
                      -webkit-box-orient: vertical;
                      word-wrap: break-word;
                    }
                    .workoutSubTitleBox{
                      @include skeletonBox(60, 18);
                    }
                  }


                }
              }

            }

          }
          .rehabCardExercise:nth-child(3n) {
            margin-right: 0;
          }
        }


      }
    }

  }
}