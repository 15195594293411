
          @import "src/assets/scss/colors.scss";
          @import "src/assets/scss/mixins.scss";
        
.signin {
  height: 100vh;
  min-width: 1280px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  .banner {
    position: fixed;
    top: 0;
    width: 100%;
    min-width: 1280px;
    background: $Primary;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    > p {
      @include small-medium($White);
      margin-right: 12px;
    }
    > a {
      @include small-medium($White);
      line-height: 16px;
      border-bottom: 1px solid $White;
      cursor: pointer;
    }
  }
  .content {
    display: flex;
    width: 1280px;
    margin: 0 auto;
    .left,
    .right {
      margin-bottom: 82px;
      width: 50%;
      margin-top: 40px;
    }

    .left {
      display: flex;
      justify-content: center;
      align-items: center;
      > img {
        display: block;
        width: 428px;
        height: 558px;
      }
    }

    .right {
      .rightWrapper {
        width: 100%;
        min-height: 558px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        > img {
          display: block;
          margin: 0 auto 24px auto;
        }
        > form {
          width: 328px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-content: center;
          .id,
          .pw {
            display: flex;
            flex-direction: column;
            align-content: flex-start;
            width: 100%;
            margin: 0 auto;
            > label {
              @include small-medium($Black);
              margin-bottom: 4px;
            }
            .warning {
              display: flex;
              align-content: center;
              @include small-regular($Red500);
              margin-top: 2px;
              padding-left: 16px;
            }
          }

          .id {
            margin-bottom: 16px;
          }

          .pw {
            margin-bottom: 48px;
            .accountChange {
              width: 100%;
              text-align: right;
              margin-top: 16px;
              @include body-regular($Gray2);
              text-decoration: underline;
              cursor: pointer;
            }
          }
          .btnWrapper {
            width: 100%;
            margin: 0 auto;
          }
        }
        .signup {
          width: 100%;
          text-align: center;
          margin-top: 40px;
          @include small-medium($Gray600);
          > span {
            @include small-medium($Mint500);
            cursor: pointer;
          }
        }
      }

    }
  }

}

@include mobile{
  .signin {
    width: 100vw;
    min-width: 360px;
    .content {
      width: 100vw !important;
      .right {
        width: 100%;
        .rightWrapper {
          min-height: auto !important;
        }
      }
    }
  }

}






