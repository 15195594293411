
          @import "src/assets/scss/colors.scss";
          @import "src/assets/scss/mixins.scss";
        
.exerciseCard {
  width: 100%;
  height: 132px;
  display: flex;
  justify-content: space-between;
  padding: 16px 0;
  border-bottom: 1px solid $Gray200;
  cursor: pointer;
  .imgBox {
    position: relative;
    .bookmark {
      position: absolute;
      bottom: 6px;
      right: 22px;
      cursor: pointer;
    }
    .exImage {
      width: 100px;
      height: 100px;
      margin-right: 16px;
      background: $Gray6;
      border-radius: 4px;
      display: flex;
      flex-wrap: wrap;
      position: relative;
      background: $Gray5;
      transition: ease-out all .3s ;
    }
  }

  .exerciseContent {
    width: 347px;
    display: flex;
    flex-direction: column;
    margin-right: 16px;
    .exerciseTag {
      margin-bottom: 4px;
      @include small-medium($Gray2);
    }
    .exerciseTitle {
      height: 54px;
      @include heading4Regular($Black);
      margin-bottom: 6px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    .level {
      display: flex;
      align-items: center;
      > div {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        margin-right: 4px;
        &.green {
          background: $Green500;
        }
        &.whiteGreen {
          background: $Green300;
        }
        &.yellow {
          background: $Yellow500;
        }
        &.orange {
          background: $Orange;
        }
        &.red {
          background: $Red500;
        }
      }
      > span {
        @include small-medium($Gray600);
      }
    }

  }
  &:last-child {
    border-bottom: 0;
  }

}