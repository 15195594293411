
          @import "src/assets/scss/colors.scss";
          @import "src/assets/scss/mixins.scss";
        
.exerciseSelectSkeleton {
  display: flex;
  .rightWrapper {
    width: 880px;
    margin: 0 20px 0 0;
    .contentWrapper {
      display: flex;
      .content1 {
        width: 280px;
        margin-right: 20px;
        .content1Top {
          width: 100%;
          margin-bottom: 16px;
          > div {
            display: flex;
            align-items: center;
            @include skeletonBox(280, 48);
            margin-bottom: 8px;
            > div {
              &:nth-child(1) {
                @include skeletonBox(52, 23, $Gray100);
                margin-right: 4px;
                margin-left: 16px;
              }
              &:nth-child(2) {
                @include skeletonBox(40, 23, $Gray100);
              }
            }
            &:nth-child(4) {
              margin-bottom: 27px;
            }
          }
          > hr {
            border: 0;
            height: 1px;
            background-color: $Gray200;
          }
        }
        .content1Bottom {
          padding: 8px 0 18px 0;
          .content1BottomContent1 {
            width: 100%;
            height: 49px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid $Gray200;
            margin-bottom: 16px;
            > div:first-child {
              width: 64px;
              height: 27px;
              background: $Gray200;
              border-radius: 4px;
            }
            > div:last-child {
              width: 79px;
              height: 33px;
              background: $Gray200;
              border-radius: 4px;
            }
          }
          .content1BottomContent2 {
            width: 100%;
            .content1BottomContent2_1 {
              width: 100%;
              height: 32px;
              background: $Gray200;
              border-radius: 4px;
              margin-bottom: 16px;
            }
            .content1BottomContent2_2 {
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-bottom: 16px;
              > div:first-child {
                width: 40px;
                height: 24px;
                background: $Gray200;
                border-radius: 4px;
              }
              > div:last-child {
                width: 20px;
                height: 20px;
                background: $Gray200;
                border-radius: 4px;
              }
            }
            .content1BottomContent2_3 {
              .content1BottomContent2_3_1 {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                > div:first-child {
                  display: flex;
                  align-items: center;
                  margin-bottom: 10px;
                  > div:first-child {
                    width: 24px;
                    height: 24px;
                    background: $Gray200;
                    border-radius: 4px;
                    margin-right: 8px;
                  }
                  > div:last-child {
                    width: 71px;
                    height: 21px;
                    background: $Gray200;
                    border-radius: 4px;
                  }
                }
                > div:last-child {
                  width: 32px;
                  height: 21px;
                  background: $Gray200;
                  border-radius: 4px;
                }
              }
            }
          }
        }
      }
      .content2 {
        width: 580px;
        .content2_1 {
          width: 100%;
          height: 41px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .content2_1_1 {
            width: 328px;
            height: 100%;
            background: $Gray200;
            border-radius: 4px;
          }
          .content2_1_2 {
            width: 85px;
            height: 100%;
            background: $Gray200;
            border-radius: 4px;
          }
        }
        .content2_2 {
          .content2_2_1 {
            width: 100%;
            height: 132px;
            padding: 16px 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .content2_2_1_1 {
              width: 100px;
              height: 100px;
              background: $Gray200;
              border-radius: 4px;
              margin-right: 16px;
            }
            .content2_2_1_2 {
              width: 348px;
              margin-right: 16px;
              .content2_2_1_2_1 {
                display: flex;
                align-items: center;
                margin-bottom: 4px;
                > div {
                  width: 32px;
                  height: 18px;
                  background: $Gray200;
                  border-radius: 2px;
                  margin-right: 2px;
                }
              }
              .content2_2_1_2_2 {
                width: 100%;
                height: 54px;
                background: $Gray200;
                border-radius: 2px;
                margin-bottom: 6px;
              }
              .content2_2_1_2_3 {
                width: 64px;
                height: 18px;
                background: $Gray200;
                border-radius: 2px;
              }
            }
            .content2_2_1_3 {
              > div {
                width: 100px;
                height: 41px;
                background: $Gray200;
                border-radius: 4px;
                margin-bottom: 8px;
              }
            }
          }
        }
      }
    }

  }
  .leftWrapper {
    position: sticky;
    top: 40px;
    width: 280px;
    height: 436px;
    border-radius: 8px;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 6px 14px rgba(0, 0, 0, 0.15);
    padding: 16px;
    display: flex;
    flex-direction: column;
    .content1 {
      width: 96px;
      height: 27px;
      background: $Gray200;
      border-radius: 4px;
      margin-bottom: 8px;
    }
    .content2 {
      width: 100%;
      height: 41px;
      background: $Gray200;
      border-radius: 4px;
      margin-bottom: 8px;
    }
    .content3 {
      width: 100%;
      height: 41px;
      background: $Gray200;
      border-radius: 4px;
      margin-bottom: 8px;
    }
    .content4 {
      width: 100%;
      height: 41px;
      background: $Gray200;
      border-radius: 4px;
      margin-bottom: 24px;
    }
    .content5 {
      width: 96px;
      height: 27px;
      background: $Gray200;
      border-radius: 4px;
      margin-bottom: 8px;
    }
    .content6 {
      width: 96px;
      height: 21px;
      background: $Gray200;
      border-radius: 4px;
      margin-bottom: 8px;
    }
    .content7 {
      width: 96px;
      height: 21px;
      background: $Gray200;
      border-radius: 4px;
      margin-bottom: 8px;
    }
    .content8 {
      width: 96px;
      height: 21px;
      background: $Gray200;
      border-radius: 4px;
      margin-bottom: 40px;
    }
    .content9 {
      width: 100%;
      height: 52px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      > div {
        width: 119px;
        height: 100%;
        background: $Gray200;
        border-radius: 4px;
      }
    }
  }
}