
          @import "src/assets/scss/colors.scss";
          @import "src/assets/scss/mixins.scss";
        
.dropdownOrder {
  width: 100%;
  .filterWrapper {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    .dropdownBig {
      width: 100%;
      .listSelected {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: $White;
        padding: 12px 16px 12px 14px;
        width: 100%;
        cursor: pointer;
        border-radius: 4px;
        border: 1px solid $Gray300;
        >span {
          color: $Gray800;
          @include body-regular($Gray800);
        }
        > img {
          width: 8px;
          height: 5px;
          transition: ease-out all .5s ;
          &.rotate {
            transform: rotateX(180deg);
          }
        }
        &.disable {
          background: $Gray100;
          pointer-events: none;
        }
        &:active {
          border: 1px solid $Primary !important;
          box-shadow: -4px -4px 8px rgba(0, 0, 0, 0.04), 8px 8px 16px rgba(0, 0, 0, 0.08);
        }
      }
      .listSelected.borderPrimary {
        border: 1px solid $Primary;
        box-shadow: -4px -4px 8px rgba(0, 0, 0, 0.04), 8px 8px 16px rgba(0, 0, 0, 0.08);
      }
      .filterLists {
        position: absolute;
        border-radius: 4px;
        left: 0;
        margin-top: 2px;
        width: 148px;
        background-color: $White;
        z-index: 3;
        filter: drop-shadow(0px 6px 20px rgba(0, 0, 0, 0.12));
        > div {
          display: flex;
          align-items: center;
          height: 44px;
          padding: 10px 14px;
          cursor: pointer;
          p {
            cursor: pointer;
            @include body-regular($Gray800);
          }
          &.active {
            background: $Mint100 !important;;
          }
          &:hover {
            background: $Gray100;
          }
          &:active {
            background: $Gray200;
          }
        }
        &.listOpenUp {
          bottom: 28px;
        }
        &.right {
          right: 0;
          left: auto;
        }
        &.scroll {
          max-height: 271px;
          position: absolute;
          overflow-x: hidden;
          &::-webkit-scrollbar {
            /* 스크롤바 막대 너비 설정 */
            width: 10px;
          }

          &::-webkit-scrollbar-thumb {
            /* 스크롤바 막대 높이 설정 */
            background: $Mint300;
            /* 스크롤바 둥글게 설정 */
            border-radius: 100px;
            background-clip: padding-box;
            border: 3px solid transparent;
          }

          &::-webkit-scrollbar-track {
            /* 스크롤바 막대 높이 설정 */
            background: $Gray200;
            /* 스크롤바 둥글게 설정 */
            border-radius: 100px;
            background-clip: padding-box;
            border: 3px solid transparent;
          }
        }
      }



      span.small,
      p.small {
        font-size: 12px !important;
        font-weight: 400 !important;
        line-height: 18px !important;
        color: $Gray900 !important;
      }
      div.small {
        padding: 4px 8px;
      }
      .listSelected.small {
        padding: 4px 6px;
      }
    }
  }
}