
          @import "src/assets/scss/colors.scss";
          @import "src/assets/scss/mixins.scss";
        
.error {
  width: 1280px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  > img {
    margin-bottom: 20px;
  }
  .mainTxt {
    > p {
      @include title2($Black);
      text-align: center;
      &:last-child {
        margin-bottom: 16px;
      }
    }
  }
  .subTxt {
    > p {
      @include body-bold($Gray2);
      text-align: center;
      &:last-child {
        margin-bottom: 16px;
      }
    }
  }
  .btnWrapper {
    width: 412px;
  }
}