
          @import "src/assets/scss/colors.scss";
          @import "src/assets/scss/mixins.scss";
        
.buttonPrimarySmall {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 41px;
  background: $Primary;
  @include body3-600($White);
  border-radius: 4px;
  margin: 0 auto;
  cursor: pointer !important;
  transition: ease all .3s;

  &.btnDisabled {
    background: $Gray300;
    color: $White;
    cursor: auto !important;
  }
}