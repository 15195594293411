
          @import "src/assets/scss/colors.scss";
          @import "src/assets/scss/mixins.scss";
        
.dropdownBasic {
  position: relative;
  display: flex;
  align-items: center;
  margin-right: 8px;
  .dropdownBig {
    .listSelected {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: $White;
      padding: 12px 16px 12px 14px;
      width: 84px;
      height: 36px;
      cursor: pointer;
      border-radius: 4px;
      border: 1px solid $Gray300;
      &:active {
        border: 1px solid $Primary !important;
        box-shadow: -4px -4px 8px rgba(0, 0, 0, 0.04), 8px 8px 16px rgba(0, 0, 0, 0.08);
      }
      >span {
        @include body-regular($Gray800);
      }
      > img {
        width: 8px;
        height: 5px;
        transition: ease-out all .5s;
        &.rotate {
          transform: rotateX(180deg);
        }
      }
    }
    .listSelected.borderPrimary {
      border: 1px solid $Primary;
      box-shadow: -4px -4px 8px rgba(0, 0, 0, 0.04), 8px 8px 16px rgba(0, 0, 0, 0.08);
    }
    .filterLists {
      position: absolute;
      border-radius: 4px;
      left: 0;
      top: 38px;
      width: 84px;
      background-color: $White;
      z-index: 13;
      filter: drop-shadow(0px 6px 20px rgba(0, 0, 0, 0.12));
      > div {
        display: flex;
        align-items: center;
        height: 44px;
        padding: 10px 14px;
        cursor: pointer;
        p {
          cursor: pointer;
          @include body-regular($Gray800);
        }
        &.active {
          background: $Mint100 !important;;
        }
        &:hover {
          background: $Gray100;
        }
        &:active {
          background: $Gray200;
        }
      }

    }
  }
}
