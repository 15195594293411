
          @import "src/assets/scss/colors.scss";
          @import "src/assets/scss/mixins.scss";
        
.planCard {
  width: 100%;
  height: 97px;
  display: flex;
  justify-content: space-between;
  padding: 16px;
  border-bottom: 1px solid $Gray200;
  cursor: pointer;
  .planContent {
    width: 432px;
    display: flex;
    flex-direction: column;
    margin-right: 16px;

    .planTitle {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      .title {
        width: 322px;
        @include heading4Regular($Black);
        margin-bottom: 16px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
      .date {
        width: 100px;
        @include small-medium($Gray600);
        text-align: end;
      }

    }
    .info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .detail {
        display: flex;
        align-items: center;
        .mp {
          @include small-semi-bold($Gray800);
          background: $Mint200;
          border-radius: 4px;
          padding: 2px 6px;
          margin-right: 16px;
        }
        .patient {
          display: flex;
          align-items: center;
          margin-right: 16px;
          > span {
            @include small-medium($Gray600);
          }
          > div {
            width: 1px;
            height: 8px;
            background: $Gray500;
            margin: 0 4px;
          }
        }
        .staff {
          display: flex;
          align-items: center;
          > span {
            @include small-medium($Gray600);
            margin-left: 2px;
          }

        }
      }
      .dates {
        display: flex;
        align-items: center;
        > img {
          margin-right: 4px;
        }
        > span {
          @include small-medium($Gray600);
        }
      }

    }

  }
  &:last-child {
    border-bottom: 0;
    margin-bottom: 40px;
  }

}