/*반응형 화면 크기*/
$mobile: 767px;
$tablet: 1023px;
$desktop: 1439px;
$desktop_big: 1440px;

/*반응형, 브라우저 크기가 767px 이하일때*/
@mixin mobile{
    @media (max-width: $mobile){
        @content;
    }
}

/*반응형, 브라우저 크기가 768이상, 1023px 이하일때*/
@mixin tablet{
    @media (min-width: $mobile+1) and (max-width: $tablet){
        @content;
    }
}

/*반응형, 브라우저 크기가 1024px 이상일때*/
@mixin desktop{
    @media (min-width: $tablet+1) and (max-width: $desktop){
        @content;
    }
}

/*반응형, 브라우저 크기가 1440px 이상일때*/
@mixin desktop_big{
    @media (min-width: $desktop_big){
        @content;
    }
}


@mixin head3($color: #222222) {
    font-family: 'Pretendard';
    font-size: 40px;
    font-weight: 700;
    line-height: 60px;
    color: $color;
}

@mixin head3Regular($color: #222222) {
    font-family: 'Pretendard';
    font-size: 40px;
    font-weight: 600;
    line-height: 150%;
    color: $color;
}

@mixin heading($color: #222222) {
    font-family: 'Pretendard';
    font-size: 32px;
    font-weight: 800;
    line-height: 36px;
    color: $color;
}

@mixin headingRegular($color: #222222) {
    font-family: 'Pretendard';
    font-size: 32px;
    font-weight: 700;
    line-height: 48px;
    color: $color;
}

@mixin headingSmall($color: #222222) {
    font-family: 'Pretendard';
    font-size: 32px;
    font-weight: 600;
    line-height: 48px;
    color: $color;
}

@mixin heading6SB($color: #222222) {
    font-family: 'Pretendard';
    font-size: 28px;
    font-weight: 600;
    line-height: 42px;
    color: $color;
}

@mixin heading5($color: #222222){
    font-family: 'Pretendard';
    font-size: 26px;
    font-weight: 600;
    line-height: 39px;
    color: $color;
}

@mixin heading1($color: #222222) {
    font-family: 'Pretendard';
    font-size: 24px;
    font-weight: 800;
    line-height: 36px;
    color: $color;
}

@mixin title2($color: #222222) {
    font-family: 'Pretendard';
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
    color: $color;
}

@mixin title2Thin($color: #222222) {
    font-family: 'Pretendard';
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    color: $color;
}

@mixin heading2($color: #222222) {
    font-family: 'Pretendard';
    font-size: 22px;
    font-weight: 700;
    line-height: 28px;
    color: $color;
}

@mixin heading2-thin($color: #222222) {
    font-family: 'Pretendard';
    font-size: 22px;
    font-weight: 600;
    line-height: 33px;
    color: $color;
}

@mixin heading3($color: #222222) {
    font-family: 'Pretendard';
    font-size: 20px;
    font-weight: 700;
    line-height: 150%;
    color: $color;
}
@mixin heading3Thin($color: #222222) {
    font-family: 'Pretendard';
    font-size: 20px;
    font-weight: 600;
    line-height: 150%;
    color: $color;
}
@mixin heading4Bold($color: #222222) {
    font-family: 'Pretendard';
    font-size: 18px;
    font-weight: 700;
    line-height: 28.8px;
    color: $color;
}

@mixin heading4Regular($color: #222222) {
    font-family: 'Pretendard';
    font-size: 18px;
    font-weight: 600;
    line-height: 28.8px;
    color: $color;
}

@mixin heading4($color: #222222) {
    font-family: 'Pretendard';
    font-size: 18px;
    font-weight: 500;
    line-height: 28.8px;
    color: $color;
}

@mixin heading4Thin($color: #222222) {
    font-family: 'Pretendard';
    font-size: 18px;
    font-weight: 400;
    line-height: 28.8px;
    color: $color;
}
@mixin heading5Regular($color: #222222) {
    font-family: 'Pretendard';
    font-size: 17px;
    font-weight: 500;
    line-height: 150%;
    color: $color;
}
@mixin heading5Thin($color: #222222) {
    font-family: 'Pretendard';
    font-size: 17px;
    font-weight: 400;
    line-height: 25.5px;
    color: $color;
}

@mixin subHeading($color: #222222) {
    font-family: 'Pretendard';
    font-size: 16px;
    font-weight: 700;
    line-height: 25.6px;
    color: $color;
}

@mixin body2-600($color: #222222) {
    font-family: 'Pretendard';
    font-size: 16px;
    font-weight: 600;
    line-height: 25.6px;
    color: $color;
}

@mixin body2-500($color: #222222) {
    font-family: 'Pretendard';
    font-size: 16px;
    font-weight: 500;
    line-height: 25.6px;
    color: $color;
}

@mixin body2-400($color: #222222) {
    font-family: 'Pretendard';
    font-size: 16px;
    font-weight: 400;
    line-height: 25.6px;
    color: $color;
}
@mixin body1-400($color: #222222) {
    font-family: 'Pretendard';
    font-size: 15px;
    font-weight: 400;
    line-height: 150%;
    color: $color;
}
@mixin headLine($color: #222222) {
    font-family: 'Pretendard';
    font-size: 14px;
    font-weight: 800;
    line-height: 20px;
    color: $color;
}

@mixin body-bold($color: #222222) {
    font-family: 'Pretendard';
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    color: $color;
}

@mixin body3-600($color: #222222) {
    font-family: 'Pretendard';
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    color: $color;
}

@mixin body3-500($color: #222222) {
    font-family: 'Pretendard';
    font-size: 14px;
    font-weight: 500;
    line-height: 22.4px;
    color: $color;
}

@mixin body-regular($color: #222222) {
    font-family: 'Pretendard';
    font-size: 14px;
    font-weight: 400;
    line-height: 22.4px;
    color: $color;
}
@mixin body-small-bold2($color: #222222) {
    font-family: 'Pretendard';
    font-size: 13px;
    font-weight: 600;
    line-height: 150%;
    color: $color;
}
@mixin body-small-bold($color: #222222) {
    font-family: 'Pretendard';
    font-size: 13px;
    font-weight: 500;
    line-height: 19.5px;
    color: $color;
}
@mixin body-small($color: #222222) {
    font-family: 'Pretendard';
    font-size: 13px;
    font-weight: 400;
    line-height: 19.5px;
    color: $color;
}

@mixin small-bold($color: #222222) {
    font-family: 'Pretendard';
    font-size: 12px;
    font-weight: 700;
    line-height: 18px;
    color: $color;
}

@mixin small-semi-bold($color: #222222){
    font-family: 'Pretendard';
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    color: $color;
}

@mixin small-medium($color: #222222){
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    color: $color;
}
@mixin small-regular($color: #222222) {
    font-family: 'Pretendard';
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    color: $color;
}

@mixin tiny($color: #222222) {
    font-family: 'Pretendard';
    font-size: 10px;
    font-weight: 700;
    line-height: 16px;
    color: $color;
}


@mixin tiny2($color: #222222) {
    font-family: 'Pretendard';
    font-size: 8px;
    font-weight: 500;
    line-height: 16px;
    color: $color;
}

@mixin skeletonBox($width: 0, $height: 0, $color: $Gray200) {
    width: $width + px;
    height: $height + px;
    background: $color;
    border-radius: 4px;
}