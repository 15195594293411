
          @import "src/assets/scss/colors.scss";
          @import "src/assets/scss/mixins.scss";
        
.patients {
  width: 100%;
  .wrapper {
    width: 1180px;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    padding: 40px 0 120px 0;
    .tabContainer {
      width: 280px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .filter {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-top: 40px;
        .filterTitle {
          width: 100%;
          height: 49px;
          display: flex;
          align-items: center;
          border-bottom: 1px solid $Black;
          > p {
            @include heading4Regular($Black);
          }
        }
        .filterToggle {
          width: 100%;
          padding: 16px 8px;
          > div {
            display: flex;
            align-items: center;
            justify-content: space-between;
            background: $Gray100;
            border-radius: 4px;
            padding: 4px 8px;
            > span {
              @include body3-500($Gray900);
            }
            .toggleBox {
              .switch {
                position: relative;
                display: inline-block;
                width: 36px;
                height: 20px;
                vertical-align: middle;
                border-radius: 50%;
              }
              .switch input {
                display: none;
              }
              .slider {
                position: absolute;
                cursor: pointer;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: $Gray4;
                -webkit-transition: .4s;
                transition: .4s;
              }
              .slider:before {
                position: absolute;
                content: "";
                height: 16px;
                width: 16px;
                background: $White;
                -webkit-transition: .4s;
                transition: .4s;
              }
              input:checked + .slider {
                background-color: $Primary;
              }
              input:checked + .slider:before {
                -webkit-transform: translateX(16px);
                -ms-transform: translateX(16px);
                transform: translateX(16px);
                border: 2px solid $Primary;
              }
              .slider.round {
                width: 36px;
                height: 20px;
                border-radius: 34px;
              }
              .slider.round:before {
                border-radius: 50%;
                box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.08);
                border: solid 2px $Gray4;
              }
            }
          }
        }
      }
    }
    .listContainer {
      width: 880px;
      background: $Gray6;
      padding: 16px;
      border-radius: 4px;
      .listWrapper {
        width: 100%;
        min-height: 645px;
        margin: 0 auto;
        .listTop {
          display: flex;
          align-items: center;
          .searchContainer {
            width: 328px;
            margin-right: 8px;
          }
          .topRight {
            display: flex;
            margin-right: 315px;
            .dropdownWrapper {
              width: 85px;
            }
          }

        }

        .list {
          margin-top: 24px;
          .tableTitle {
            width: 100%;
            height: 54px;
            display: flex;
            align-items: center;
            padding: 8px 16px;
            > p {
              @include body-regular($Gray800);
              text-align: center;
              &.name {
                width: 66px;
              }
              &.gender {
                width: 36px;
              }
              &.birth {
                width: 66px;
              }
              &.lastPlan {
                width: 580px;
              }
              &.assignmentBtn {
                width: 68px;
              }
            }
          }
          .tableContent {
            width: 100%;
            > div {
              width: 100%;
              height: 53px;
              background: $White;
              display: flex;
              align-items: center;
              margin-bottom: 16px;
              border-radius: 8px;
              padding: 8px 16px;
              cursor: pointer;
              transition: 0.1s;
              &:last-child {
                margin-bottom: 0;
              }
              &:hover {
                box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.08);
              }
              &:last-child {
                margin-right: 14px;
              }
              .contentLastPlan {
                position: relative;
                width: 580px;
                display: flex;
                justify-content: center;
                align-items: center;
                .planBox {
                  position: relative;
                  display: flex;
                  align-items: center;
                  width: 572px;
                  height: 36px;
                  background: $Gray100;
                  border-radius: 4px;
                  padding: 8px 0;
                  .mainPart {
                    width: 95px;
                    text-align: center;
                    @include body3-500($Gray800);
                  }
                  .lastPlanStatus {
                    width: 84px;
                    text-align: center;
                    > span {
                      @include small-semi-bold($Gray800);
                      padding: 2px 6px;
                      border-radius: 4px;
                      &.gray {
                        background: $Gray300;
                      }
                      &.blue {
                        background: $Mint200;
                      }
                      &.yellow {
                        background: $Yellow200;
                      }
                      &.lightBlue {
                        background: $Mint100;
                      }
                      &.pink {
                        background: $Red100;
                      }
                    }
                  }
                  .rateBox {
                    width: 259px;
                    position: relative;
                    display: flex;
                    align-items: center;
                    padding: 8px 0;
                    .backgroundBar {
                      width: 206px;
                      height: 12px;
                      background: $Gray200;
                      border-radius: 4px;
                      margin-right: 8px;
                    }
                    .coloredBar {
                      position: absolute;
                      height: 12px;
                      border-radius: 4px;
                    }
                    .gray {
                      background: $Gray3;
                    }
                    .red {
                      background: $Red400;
                    }
                    .green {
                      background: $Green300;
                    }
                    .percent {
                      width: 40px;
                      text-align: end;
                      @include body3-500($Black);
                    }
                  }
                  .staffName {
                    position: relative;
                    width: 134px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    > span {
                      @include body3-500($Gray800);
                      text-align: center;
                      margin-left: 8px;
                    }
                  }
                }

              }
              > p {
                text-align: center;
                @include body3-500($Gray800);
                &.contentName {
                  width: 66px;
                }
                &.contentGender {
                  width: 36px;
                }
                &.contentBirth {
                  width: 66px;
                }
                &.contentAssignmentBtn {
                  width: 68px;
                  @include body3-500($Mint500);
                  text-decoration: underline;
                }
              }

            }
          }
          &.loading {
            .contentName {
              width: 58px !important;
              @include skeletonBox(58, 21);
              margin-right: 10px;
            }
            .contentGender {
              width: 32px !important;
              @include skeletonBox(32, 21);
              margin-right: 10px;
            }
            .contentBirth {
              width: 54px !important;
              @include skeletonBox(54, 21);
              margin-right: 8px;
            }
            .contentLastPlan {
              margin-right: 8px;
              .planBox {
                background: $Gray200 !important;
              }
            }
            .contentAssignmentBtn {
              @include skeletonBox(48, 21);
            }
          }
        }
        .emptyContainer {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          background: $Gray100;
          height: 480px;
          margin-top: 24px;
          > div {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            > img {
              margin-bottom: 16px;
            }
            .emptyTxt1 {
              @include body2-500($Gray600);
              text-align: center;
            }
            .emptyTxt2 {
              @include body2-600($Mint500);
              text-align: center;
            }
          }

        }
      }

    }
  }

}