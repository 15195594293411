
          @import "src/assets/scss/colors.scss";
          @import "src/assets/scss/mixins.scss";
        
.modalProgramDetail {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.4);
  cursor: auto;
  z-index: 999;
  > div {
    width: 970px;
    height: 712px;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: $White;
    border-radius: 16px;
    box-shadow: -4px -4px 8px rgba(0, 0, 0, 0.08), 8px 8px 16px rgba(0, 0, 0, 0.16);
    .header {
      height: 54px;
      background: $White;
      border-bottom: 1px solid $Gray400;
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
      > p {
        line-height: 54px !important;
        text-align: center;
        @include heading4($Black);
      }
      > img {
        position: absolute;
        top: 15px;
        right: 24px;
        cursor: pointer;
      }
    }
    .body {
      overflow-y: auto;
      width: 100%;
      padding: 34px 126px 80px 126px;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
      .iconWrapper {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: right;
        margin-bottom: 8px;
        > img {
          width: 30px;
          height: 30px;
          cursor: pointer;
          &.pencil {
            margin-left: 8px;
          }
        }
        .editOptions {
          position: absolute;
          display: none;
          top: 40px;
          width: 328px;
          height: 164px;
          border-radius: 4px;
          filter: drop-shadow(0px 6px 20px rgba(0, 0, 0, 0.12));
          background: $White;
          > p {
            display: flex;
            align-items: center;
            height: 41px;
            width: 100%;
            padding: 10px 16px;
            @include body-regular($Gray900);
            > img {
              margin-right: 4px;
            }
            &:hover {
              background: $Gray100;
            }
            &:active {
              background: $Gray200;
            }
          }
          > div {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 41px;
            width: 100%;
            padding: 10px 16px;
            .share {
              display: flex;
              align-items: center;
              > span {
                @include body-regular($Gray900);
              }
              > img {
                margin-right: 4px;
              }
            }
            .toggleBtnWrapper {
              display: flex;
              align-items: center;
              .toggleBtn {
                width: 36px;
                height: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                > span {
                  position: relative;
                  display: inline-block;
                  width: 36px;
                  height: 20px;
                  cursor: pointer;
                  transition: ease all 0.3s ;
                  border-radius: 40px;
                  background: #e9e9e9;

                  &:before {
                    position: absolute;
                    top: 2px;
                    left: 34px;
                    width: 16px;
                    height: 16px;
                    content: "";
                    transition: ease all 0.3s ;
                    transform: translateX(-31px);
                    border-radius: 50%;
                    background: #fff;
                  }

                  &.on {
                    transition: ease all 0.3s ;
                    background: $Primary;
                    &:before {
                      transition: ease all 0.3s ;
                      transform: translateX(-16px);
                    }
                  }
                }
              }
              > span {
                width: 25px;
                margin-left: 8px;
                @include body3-500($Gray2);
              }
            }
            &:hover {
              background: $Gray100;
            }
            &:active {
              background: $Gray200;
            }
          }
          &.active {
            display: block;
          }
        }
      }
      &::-webkit-scrollbar {
        display: none; /* Chrome, Safari, Opera*/
      }

      .title {
        width: 100%;
        height: 85px;
        border-bottom: 1px solid $Black;
        .type {
          @include body2-600($Gray700);
          margin-bottom: 4px;
        }
        .name {
          @include heading5($Black);
          padding-bottom: 16px;
        }
      }
      .content {
        width: 100%;
        border-bottom: 1px solid $Gray300;
        padding: 40px 0 48px 0;
        margin-bottom: 40px;
        > div {
          display: flex;
          align-items: flex-start;
          margin-bottom: 16px;
          .contentTitle {
            display: flex;
            align-items: center;
            width: 120px;
            margin-right: 8px;
            @include body3-500($Gray600);
            > img {
              margin-left: 4px;
            }
            &.academic {
              line-height: 26px;
              > p {
                margin-bottom: 8px;
                @include body3-500($Gray600);
              }
            }
          }
          .contents {
            display: flex;
            align-items: center;
            width: 590px;
            @include body3-500($Gray900);
            > span {
              @include body3-500($Gray900);
              &.creatorName {
                margin-left: 4px;
              }
              &.marginLeft {
                margin-left: 4px;
              }
            }
            &.academic {
              flex-direction: column;
              align-items: baseline;
              > p {
                margin-bottom: 8px;
                @include body3-500($Gray900);
              }
              a {
                line-height: initial;
              }
              a:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }
      .list {
        .listTitle {
          height: 51px;
          display: flex;
          justify-content: space-between;
          border-bottom: 1px solid $Gray400;
          .listOrder {
            position: relative;
            display: flex;
            @include heading4Regular($Black);
            &:before {
              content: '';
              width: 6px;
              height: 6px;
              display: block;
              border-radius: 50%;
              background: $Gray600;
              margin-top: 11px;
              margin-right: 8px;
            }
          }
          .totalTime {
            @include body-regular($Gray600);
            > span {
              @include body3-500($Gray900);
            }
          }
        }
        .listContainer {
          width: 100%;
        }
      }
    }
  }
}