$Primary: #07BEB8;
$PrimaryDark: #139AA9;
$PrimaryLight: #83DFDC;
$PrimaryBG: #F0FCFC;
$Primary100: #F0FCFC;
$Primary200: #CDF2F1;
$Primary300: #9CE5E3;
$Primary400: #6AD8D4;
$Primary400_mora: #6AD8D4;
$Primary600: #00AFA7;
$Primary700: #009287;
$Primary900: #005548;
$MoraSub100: #FFFBEF;

$Black: #000000;
$White: #FFFFFF;
$Gray1: #545454;
$Gray2: #747474;
$Gray3: #AEAEAE;
$Gray4: #DDDDDD;
$Gray5: #F2F2F2;
$Gray6: #F9F9F9;
$GrayBorder: #545454;

$Gray900: #2C2C2C;
$Gray800: #484848;
$Gray700: #5C5C5C;
$Gray600: #747474;
$Gray500: #AEAEAE;
$Gray400: #C9C9C9;
$Gray300: #DDDDDD;
$Gray200: #F2F2F2;
$Gray100: #F9F9F9;

$Success: #2ecc71;
$Favorite:#f6d860;
$Yellow100:#FFFCEE;
$Yellow150:#FFF9DE;
$Yellow200:#FFF5CA;
$Yellow300:#FFEC99;
$Yellow500:#f6d860;
$Alert: #e74c3c;
$Red100: #FFE8E5;
$Red300: #F69D92;
$Red400: #F96354;
$Red500: #D85645;
$Sub500: #FFD850;
$Sub200: #FFEFB9;

$Self0: #B5D9A4;
$Pink: #E89895;
$Yellow: #FBF5AC;

$Mint900: #007E7E;
$Mint800: #018D8D;
$Mint700: #039F9B;
$Mint600: #04AFAB;
$Mint500: #07BEB8;
$Mint400: #6AD8D4;
$Mint300: #9CE5E3;
$Mint200: #DAF5F5;
$Mint100: #F0FCFC;

$Pain0: #B5D9A4;
$Pain1: #C4E1A6;
$Pain2: #D9ECA9;
$Pain3: #F2F7AD;
$Pain4: #FBF5AC;
$Pain5: #F7E8A7;
$Pain6: #F2DDA5;
$Pain7: #EFD1A0;
$Pain8: #EBC1A2;
$Pain9: #EAB2A1;
$Pain10: #E8A09D;

$Green100: #F2FBF4;
$Green200: #C2F0CC;
$Green300: #86DF9A;
$Green400: #47C263;
$Green500: #00B031;
$Favorite: #F6D860;
$Orange: #F89C47;
$Orange200: #FFE3CA;
$Orange500: #F89C47;
$Orange600: #F18826;
$Purple: #7747FF;
$Purple400: #A180FF;
$Purple200: #D6C8FF;
$PurpleDay: #565DFF;
$PurpleJob: #A66EED;
$YellowJob: #FCB448;
$RedJob: #EB7768;
$blueJob: #6EA8ED;

$VuNavy400: #4156A1;
