
          @import "src/assets/scss/colors.scss";
          @import "src/assets/scss/mixins.scss";
        
.buttonBigGray {
  width: 100%;
  height: 52px;
  background: $Gray5;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  > div {
    width: 20px;
    height: 20px;
    background: url('https://resources.mora.kr/static/app/mora-ex/assets/web/G_search20*20.svg') no-repeat;
    background-size: contain;
    margin-right: 8px;
  }
  > span {
    @include body2-600($Gray2);
    &.small {
      @include body3-600($Gray600);
    }
  }
  &.isDisabled {
    background: $Gray6;
    cursor: auto;
    > span {
      @include body2-600($Gray4);
    }
  }
  &:hover {
    > div {
      background: url('https://resources.mora.kr/static/app/mora-ex/assets/web/P_search.svg') no-repeat;
    }
    .small {
      @include body3-600($Mint500);
    }
  }
  &:active {
    > div {
      background: url('https://resources.mora.kr/static/app/mora-ex/assets/web/DP_search.svg') no-repeat;
    }
    .small {
      @include body3-600($Mint700);
    }
  }
}