
          @import "src/assets/scss/colors.scss";
          @import "src/assets/scss/mixins.scss";
        
.patientChangeProfile{
  width: 100%;
  position: relative;
  background: $White;
  padding: 24px;
  > div {
    position: relative;
    margin-bottom: 24px;
    >p{
      @include small-medium($Black);
      margin-bottom: 4px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    >input{
      border: 1px solid $Gray300;
      border-radius: 4px;
      width: 100%;
      height: 41px;
      padding: 10px 16px;
      @include body-regular($Gray900);
      &.gray {
        background: $Gray100;
        border: 1px solid $Gray300 !important;
      }
      &.borderPrimary {
        border: 1px solid $Primary;
      }
      &::placeholder {
        @include body-regular($Gray3);
      }
    }
    > span {
      position: absolute;
      top: 31px;
      left: 43px;
      @include body-regular($Gray900);
    }
    > img {
      position: absolute;
      top: 34px;
      right: 15px;
      cursor: pointer;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }

}