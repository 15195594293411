
          @import "src/assets/scss/colors.scss";
          @import "src/assets/scss/mixins.scss";
        
.footerGray {
  position: absolute;
  min-width: 1280px;
  width: 100%;
  height: 116px;
  background: $Gray100;
  display: flex;
  justify-content: center;
  padding: 12px 0;
  border-top: 1px solid $Gray400;
  bottom: 0;
  .footerWrapper {
    display: flex;
    width: 1180px;
    justify-content: space-between;
    .footerLeft {
      .everex {
        @include body-small-bold($Gray900);
        margin-bottom: 6.5px;
      }
      .info {
        margin-bottom: 3px;
        @include body-small($Gray700);
        > span {
          @include body-regular($Gray300);
          margin: 0 6px;
        }
        > a {
          @include body-small($Gray700);
          margin-left: 4px;
          text-decoration: underline;
          text-underline-offset: 2px;
        }
      }
      .address {
        margin-bottom: 2.5px;
        @include body-small($Gray700);
        > span {
          @include body-regular($Gray300);
          margin: 0 6px;
        }
      }
      .copyright {
        display: flex;
        align-items: center;
        @include body-small($Gray700);
        > img {
          margin-right: 2px;
        }
      }
    }
    .footerRight {
      > span {
        @include body-small-bold2($Gray900);
        margin-right: 16px;
        cursor: pointer;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}