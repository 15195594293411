
@font-face {
  font-family: 'Pretendard';
  src:
    url("https://resources.mora.kr/static/app/assets/font/pretendard/otf/Pretendard-Regular.otf") format('otf'),
    url("https://resources.mora.kr/static/app/public/font/pretendard/woff/Pretendard-Regular.woff") format('woff'),
    url("https://resources.mora.kr/static/app/public/font/pretendard/woff2/Pretendard-Regular.woff2") format('woff2');
  font-weight: 400;
}
@font-face {
  font-family: 'Pretendard';
  src:
    url("https://resources.mora.kr/static/app/assets/font/pretendard/otf/Pretendard-Medium.otf") format('otf'),
    url("https://resources.mora.kr/static/app/public/font/pretendard/woff/Pretendard-Medium.woff") format('woff'),
    url("https://resources.mora.kr/static/app/public/font/pretendard/woff2/Pretendard-Medium.woff2") format('woff2');
  font-weight: 500;
}
@font-face {
  font-family: 'Pretendard';
  src:
    url("https://resources.mora.kr/static/app/assets/font/pretendard/otf/Pretendard-SemiBold.otf") format('otf'),
    url("https://resources.mora.kr/static/app/public/font/pretendard/woff/Pretendard-SemiBold.woff") format('woff'),
    url("https://resources.mora.kr/static/app/public/font/pretendard/woff2/Pretendard-SemiBold.woff2") format('woff2');
  font-weight: 600;
}
@font-face {
  font-family: 'Pretendard';
  src:
    url("https://resources.mora.kr/static/app/assets/font/pretendard/otf/Pretendard-Bold.otf") format('otf'),
    url("https://resources.mora.kr/static/app/public/font/pretendard/woff/Pretendard-Bold.woff") format('woff'),
    url("https://resources.mora.kr/static/app/public/font/pretendard/woff2/Pretendard-Bold.woff2") format('woff2');
  font-weight: 700;
}



.Toastify__toast-container {
  min-width: 376px;
  margin-top: 66px;
}

.Toastify__toast {
  border-radius: 8px !important;
  min-height: 56px !important;
  padding: 16px 24px !important;
  margin-bottom: 8px !important;
}

.Toastify__toast-body {
  padding: 0 !important;
  margin: 0 !important;
}

.Toastify__toast-body > div {
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 24px !important;
}

.Toastify__toast--success {
  background: rgba(0, 0, 0, 0.8) !important;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.08), 0px 8px 18px rgba(0, 0, 0, 0.2);
}

.Toastify__toast--error {
  background: rgba(231, 76, 60, 0.8) !important;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.08), 0px 8px 18px rgba(0, 0, 0, 0.2);
}

.Toastify__toast-body {
  display: flex !important;
  align-items: center !important;
}


.Toastify__toast-body  .Toastify__toast-icon {
  display: none;
}

.Toastify__toast-body > div:last-child {
  color: #ffffff !important;
}




/*병원 통계*/

.apexcharts-tooltip {
  border: 1px solid #747474 !important;
  border-radius: 4px !important;
  box-shadow: none !important;
  color: #747474;
  font-size: 12px !important;
  font-weight: 500;
  line-height: 18px;
}
.apexcharts-tooltip-series-group {
  padding-bottom: 0 !important;
  justify-content: center !important;
}
.apexcharts-tooltip-marker {
  margin-right: 6px !important;
}
.apexcharts-tooltip-text-y-value {
  font-size: 13px !important;
  font-weight: 600 !important;
  line-height: 19.5px !important;
  margin-left: 0 !important;
}
.apexcharts-tooltip-y-group {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
}


/*고객 상세 플랜 관리, 전체 통계*/

.apexchartspatientStatistics .apexcharts-tooltip{
  /*opacity: 1 !important;*/

  background: rgba(0, 0, 0, 0.75) !important;
  width: 130px;
  height: 92px;
  padding: 8px !important;
}

.apexchartsstatistics .apexcharts-tooltip {
  /*opacity: 1 !important;*/

  background: rgba(0, 0, 0, 0.75) !important;
  width: 130px;
  min-height: 92px;
  padding: 8px !important;
}

.apexchartspatientStatistics .apexcharts-tooltip-marker,
.apexchartsstatistics .apexcharts-tooltip-marker {
  width: 8px;
  height: 8px;
  margin-right: 4px !important;
}

.apexchartspatientStatistics .apexcharts-tooltip-text,
.apexchartsstatistics .apexcharts-tooltip-text {
  width: 86%;
}

.apexchartspatientStatistics .apexcharts-tooltip-text-y-label,
.apexchartsstatistics .apexcharts-tooltip-text-y-label {
  color: #ffffff !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  line-height: 150% !important;
}

.apexchartspatientStatistics .apexcharts-tooltip-title,
.apexchartsstatistics .apexcharts-tooltip-title {
  padding: 0 0 4px 0 !important;
  background: none !important;
  border: 0 !important;
  margin: 0 !important;
  color: #ffffff !important;
}

.apexchartspatientStatistics .apexcharts-tooltip-text-y-value,
.apexchartsstatistics .apexcharts-tooltip-text-y-value {
  color: #ffffff !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  line-height: 150% !important;
}

.apexchartspatientStatistics .apexcharts-tooltip-y-group,
.apexchartsstatistics .apexcharts-tooltip-y-group {
  width: 100% !important;
  flex-direction: row !important;
  justify-content: space-between !important;
  padding: 0 !important;
}

.apexchartspatientStatistics .apexcharts-tooltip-series-group,
.apexchartsstatistics .apexcharts-tooltip-series-group {
  justify-content: flex-start !important;
  padding: 0;
}



