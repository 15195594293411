
          @import "src/assets/scss/colors.scss";
          @import "src/assets/scss/mixins.scss";
        
.assignment {
  display: flex;
  justify-content: space-between;
  width: 1280px;
  padding: 0 50px;
  margin: 52px auto 120px auto;
  .rightWrapper {
    width: 880px;
    padding: 24px;
    border: 1px solid $Gray300;
    border-radius: 10px;
    .planName {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: $Mint100;
      border-radius: 8px;
      padding: 15px 24px;
      margin-bottom: 24px;
      > span {
        @include heading2-thin($Black);
      }
    }
    .patientName {
      text-align: right;
      @include body2-600($Black);
    }
    .patientInfo {
      text-align: right;
      @include body2-400($Gray400);
      margin-bottom: 24px;
      .gender,
      .birth {
        @include body2-400($Gray700);
      }
    }
    .planDetail {
      position: relative;
      display: flex;
      align-items: center;
      width: 100%;
      border-top: 1px solid $Gray300;
      padding: 26px 24px;
      .title {
        width: 96px;
        @include body2-500($Gray900);
        margin-right: 24px;
      }
      .content {
        width: 682px;
        @include heading5Thin($Gray600);
        .count {
          @include heading4Regular($Mint500);

          &.cautionTxt {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            word-wrap: break-word;
            margin-right: 4px;
          }
        }
        .txt {
          width: 600px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          word-wrap: break-word;
        }
        &:last-child {
          display: flex;
          @include heading5Thin($Gray600);
        }
      }
      > img {
        position: absolute;
        right: 26px;
        transition: ease all .3s;
        &.rotate {
          transform: rotateX(180deg);
        }
      }
    }
    .exerciseDetail {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      border-top: 1px solid $Gray4;
      background: $Gray6;
      padding: 16px 16px 0 16px;
      .exerciseItem {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        margin-right: 13px;
        margin-bottom: 16px;
        .grayBlack {
          width: 40px;
          height: 40px;
          border-radius: 4px;
          background: $Gray5;
          margin-right: 8px;
        }
        > img {
          position: absolute;
          width: 40px;
          height: 40px;
          border-radius: 4px;
          margin-right: 8px;
        }
        > p {
          width: 208px;
          margin-top: -4px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          word-wrap: break-word;
          @include body3-500($Gray600);
        }
        &:nth-child(3n) {
          margin-right: 0;
        }
      }

    }
    .toolDetail {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      border-top: 1px solid $Gray4;
      background: $Gray6;
      padding: 16px 16px 0 16px;
      .toolItem {
        width: 64px;
        height: 53px;
        margin-bottom: 12px;
        margin-right: 13px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        > p {
          text-align: center;
          @include body3-500($Gray600);
        }
      }

    }
    .dateDetail {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      border-top: 1px solid $Gray4;
      background: $Gray6;
      padding: 16px 40px 0 40px;
      > span {
        width: 93px;
        @include body3-500($Gray600);
        margin-bottom: 16px;
        > span {
          @include body-regular($Gray300);
          margin: 0 2px;

        }
        &:nth-child(8n),
        &:last-child {
          > span {
            display: none;
          }

        }
      }
    }
    .caution {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      border-bottom: 1px solid $Gray300;
      .cautionContent {
        position: relative;
        display: flex;
        height: 29px;
        margin-bottom: 18px;
      }
      .cautionAll {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        cursor: pointer;
        div {
          height: 37px;
          display: flex;
          align-items: center;
          width: 100%;
          border-bottom: 1px solid $Gray4;
          > span {
            padding: 0 0 0 8px;
            @include body3-500($Gray600);
            transition: ease all .2s;
          }
          &.active {
            > span {
              @include body3-500($Gray900);
            }
          }
        }
      }
      .cautionOptions {
        display: flex;
        flex-wrap: wrap;
        cursor: pointer;
        > div {
          height: 37px;
          display: flex;
          align-items: center;
          width: 100%;
          border-bottom: 1px solid $Gray200;
          > span {
            padding: 0 0 0 8px;
            @include body3-500($Gray600);
            transition: ease all .2s;
          }

          .inputBox {
            height: auto;
            width: 100%;
            position: relative;
            display: flex;
            justify-content: flex-end;
            > textarea {
              border: 1px solid $Gray300;
              border-radius: 4px;
              width: 756px;
              height: 41px;
              padding: 9px 44px 9px 16px;
              resize: none;
              @include body-regular($Gray900);
              -ms-overflow-style: none; /* IE and Edge */
              scrollbar-width: none; /* Firefox */
              &::-webkit-scrollbar {
                display: none;
              }
              &::placeholder {
                @include body-regular($Gray500);
              }
              &.focus {
                border: 1px solid $Mint500;
              }
              &.active {
                border: 1px solid $Mint500;
                @include body-regular($Mint500);
              }

            }
            > img {
              position: absolute;
              top: 13px;
              right: 13px;
              cursor: pointer;
            }
          }
          &.active {
            > span {
              @include body3-500($Gray900);
            }
          }
          &:last-child {
            margin-top: 12px;
            border-bottom: 0;
            &.big {
              height: 77px;
              textarea {
                height: 83px;
              }
            }
          }
        }
      }
      .noContent {
        @include heading5Thin($Gray400);
      }

    }
    .assign {
      @include body2-400($Gray2);
      margin: 40px 0;
      text-align: center;
    }
    .date {
      @include body2-500($Black);
      margin-bottom: 50px;
      text-align: center;
    }
    .planStaff {
      margin-bottom: 40px;
      padding-bottom: 50px;
      border-bottom: 1px solid $Gray300;
      .staffInfo {
        display: flex;
        width: 100%;
        justify-content: flex-end;
        .staff {
          @include body2-500($Gray2);
        }
        .colon {
          @include body2-500($Gray2);
          margin: 0 8px;
        }
        .staffName {
          display: flex;
          align-items: center;
          width: 136px;
          > span {
            @include body2-600($Black);
            margin-left: 8px;
          }
        }
      }
    }

    .hospital {
      @include title2Thin($Black);
      text-align: center;
    }
  }
  .leftWrapper {
    .floatContainer {
      position: sticky;
      top: 148px;
      width: 280px;
      padding: 16px;
      box-shadow: 0 0 2px rgba(0, 0, 0, 0.06), 0 6px 14px rgba(0, 0, 0, 0.15);
      border-radius: 8px;
      .leftTitle {
        @include body2-600($Black);
        margin-bottom: 16px;
      }
      .warning {
        margin: 16px 0;
        padding: 8px 12px;
        background: $Gray100;
        border-radius: 4px;
        > p:first-child {
          @include small-medium($Gray900);
          margin-bottom: 6px;
        }
        > p:last-child {
          @include small-regular($Gray800);
        }
      }
      .checkbox {
        display: flex;
        justify-content: space-between;
        margin-bottom: 16px;
        cursor: pointer;
        > img {
          padding-bottom: 18px;
          padding-right: 8px;
        }
        > span {
          @include body3-500($Black);
        }
      }
      .share {
        @include small-medium($Black);
        margin-bottom: 4px;
      }
      .btns {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 40px;
        > button {
          width: 119px;
          height: 52px;
          border-radius: 4px;
          &.backBtn {
            background: $Mint100;
            @include body2-600($Mint600);
          }
          &.assignBtn {
            background: $Gray300;
            @include body2-600($White);
            cursor: auto;
            &.active {
              background: $Mint500;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}