
          @import "src/assets/scss/colors.scss";
          @import "src/assets/scss/mixins.scss";
        
.headerNav {
  position: sticky;
  background-color: $White;
  width: 100%;
  min-width: 1280px;
  top: 0;
  display: flex;
  align-items: center;
  height: 64px;
  z-index: 10;
  border-bottom: 1px solid $Gray200;
  > div {
    display: flex;
    justify-content: space-between;
    min-width: 1180px;
    margin: 0 auto;
    .nav {
      display: flex;
      align-items: center;
      > img {
        margin-right: 80px;
        cursor: pointer;
      }
      > div {
        display: flex;
        align-items: flex-end;
        margin-right: 24px;
        cursor: pointer;
        > span {
          @include body2-500($Black);
        }
        &.active {
          > span {
            @include body2-600($Primary);
          }
        }
        &.hide {
          display: none;
        }
        &.payment {
          display: flex;
          align-items: center;
          > img {
            margin-left: 4px;
            margin-bottom: 2px;
          }
        }
      }
    }

    .btnImg {
      display: flex;
      justify-content: space-around;
      align-items: center;
      .serviceManual {
        height: fit-content;
        border-radius: 4px;
        background: $Gray200;
        display: flex;
        align-items: center;
        padding: 4px 8px;
        margin-right: 8px;
        > span {
          @include small-medium($Gray800);
          margin-right: 4px;
        }
        > div {
          width: 16px;
          height: 16px;
          background: url('https://resources.mora.kr/static/app/mora-ex/assets/web/icon/G_open_in_new.svg') no-repeat;
        }
        &:hover {
          > span {
            color: $Mint500;
          }
          > div {
            background: url('https://resources.mora.kr/static/app/mora-ex/assets/web/icon/LP_open_in_new.svg') no-repeat;
          }
        }
        &:active {
          > span {
            color: $Mint700;
          }
          > div {
            background: url('https://resources.mora.kr/static/app/mora-ex/assets/web/icon/DP_open_in_new.svg') no-repeat;
          }
        }
      }
      .noti {
        width: 44px;
        height: 44px;
        display: flex;
        align-items: center;
        position: relative;
        cursor: pointer;
        margin-right: 8px;
        background: url('https://resources.mora.kr/static/app/mora-ex/assets/web/G_bell.svg') no-repeat;
        > img {
          position: absolute;
          bottom: 23px;
          left: 25px;
        }
        &:hover {
          background: url('https://resources.mora.kr/static/app/mora-ex/assets/web/P_bell.svg') no-repeat;
        }
        &.active {
          background: url('https://resources.mora.kr/static/app/mora-ex/assets/web/P_bell.svg') no-repeat;
        }
        &:active {
          background: url('https://resources.mora.kr/static/app/mora-ex/assets/web/P_bell.svg') no-repeat;
        }
      }
      .myPage {
        width: 44px;
        height: 44px;
        background: url('https://resources.mora.kr/static/app/mora-ex/assets/web/G_user.svg') no-repeat;
        margin-right: 8px;
        cursor: pointer;
        transition: ease all .1s;
        &:hover {
          background: url('https://resources.mora.kr/static/app/mora-ex/assets/web/P_user.svg') no-repeat;
        }
        &.active {
          background: url('https://resources.mora.kr/static/app/mora-ex/assets/web/P_user.svg') no-repeat;
        }
        &:active {
          background: url('https://resources.mora.kr/static/app/mora-ex/assets/web/P_user.svg') no-repeat;
        }
      }
      .logout {
        width: 44px;
        height: 44px;
        background: url('https://resources.mora.kr/static/app/mora-ex/assets/web/G_logout.svg') no-repeat;
        cursor: pointer;
      }
      .hide {
        display: none;
      }
    }
  }
}