
          @import "src/assets/scss/colors.scss";
          @import "src/assets/scss/mixins.scss";
        
.inputSearchBox {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: space-between;
  .inputWrapper {
    width: 100%;
    input {
      width: 100%;
      border: 1px solid $Gray4;
      border-radius: 4px;
      @include body-regular($Gray900);
      padding: 11px 16px 11px 43px;
      &::placeholder {
        @include body-regular($Gray3);
      }

      &.focus {
        border: 1px solid $Primary !important;
      }
      &:hover {
        border: 1px solid $Gray400;
      }
    }
    .searchImg {
      position: absolute;
      width: 18px;
      height: 19px;
      top: 11px;
      left: 17px;
      cursor: pointer;
    }
    .closeImg {
      width: 16px;
      height: 16px;
      position: absolute;
      top: 13px;
      right: 18px;
      cursor: pointer;
      background: url('https://resources.mora.kr/static/app/mora-ex/assets/web/close_round.svg') no-repeat;
      transition: ease all .3s;
      &:hover {
        background: url('https://resources.mora.kr/static/app/mora-ex/assets/web/P_close_round.svg') no-repeat;
      }
      &.top18 {
        top: 18px;
      }
    }
  }
  .inputWrapper.borderPrimary {
    border: 1px solid $Primary;
  }




}