
          @import "src/assets/scss/colors.scss";
          @import "src/assets/scss/mixins.scss";
        
.modalOrgan {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 999;
  > div {
    width: 460px;
    height: 610px;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: $White;
    border-radius: 16px;
    box-shadow: -4px -4px 8px rgba(0, 0, 0, 0.08), 8px 8px 16px rgba(0, 0, 0, 0.16);
    .header {
      position: relative;
      height: 54px;
      background: $Gray6;
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
      .back {
        position: absolute;
        left: 17px;
        top: 15px;
        cursor: pointer;
      }
      > p {
        line-height: 54px !important;
        text-align: center;
        @include heading4($Black);
      }
      .close {
        position: absolute;
        right: 17px;
        top: 15px;
        cursor: pointer;
      }
    }
    .bodyFindOrgan {
      width: 100%;
      padding: 24px;
      .inputWrapper {
        position: relative;
        display: flex;
        justify-content: space-between;
        > input {
          width: 316px;
          height: 41px;
          border: 1px solid $Gray300;
          border-radius: 4px;
          padding: 10px 16px;
          @include body-regular($Gray900);
          transition: ease all .3s;
          &::placeholder {
            color: $Gray500;
          }
          &:hover {
            border: 1px solid $Gray400;
          }
          &.active {
            border: 1px solid $Mint500 !important;
          }
        }
        > img {
          position: absolute;
          right: 110px;
          top: 13px;
          cursor: pointer;
        }
        > button {
          width: 88px;
          height: 41px;
          background: $Gray300;
          @include body3-600($White);
          border-radius: 4px;
          cursor: auto;
          transition: ease all .3s;
          &.active {
            background: $Mint100;
            color: $Mint600;
            cursor: pointer;
          }
        }
      }
      > p {
        @include body-regular($Gray700);
        margin-top: 15px;
        > span {
          @include small-medium($Gray800);
          margin-left: 16px;
          text-decoration: underline;
          text-underline-offset: 3px;
          cursor: pointer;
        }
      }
      .listBox {
        margin-top: 30px;
        width: 100%;
        height: 400px;
        border: 1px solid $Gray300;
        border-radius: 4px;
        overflow-y: auto;
        .item {
          width: 100%;
          padding: 12px 16px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-bottom: 1px solid $Gray200;
          .itemName {
            width: 305px;
            > h5 {
              display: flex;
              align-items: center;
              > img {
                margin-right: 4px;
              }
              > p {
                @include body3-600($Black);
              }
            }
            > p {
              @include body-small($Gray700);
            }
          }
          > button {
            width: 56px;
            height: 30px;
            background: $Mint500;
            border-radius: 4px;
            @include small-semi-bold($White);
          }
        }
        > button {
          width: 396px;
          height: 52px;
          background: $Gray200;
          margin: 8px 0 40px 7px;
          border-radius: 4px;
          @include body2-600($Gray800);
        }
        > p {
          @include body2-500($Gray600);
        }
        &::-webkit-scrollbar {
          width: 3px;
          height: 3px;
        }
        &::-webkit-scrollbar-thumb {
          background: $Mint400;
          border-radius: 4px;
          background-clip: padding-box;
          border: 1px solid transparent;
        }

        &::-webkit-scrollbar {
          width: 6px;
        }
        &.emptyList {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
    .bodyAddOrgan {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .searchContainer {
        position: relative;
        padding: 24px;
        > input {
          width: 100%;
          height: 41px;
          padding: 10px 16px;
          border: 1px solid $Gray300;
          border-radius: 4px;
          @include body-regular($Gray900);
          transition: ease all .3s;
          &::placeholder {
            color: $Gray500;
          }
          &:hover {
            border: 1px solid $Gray400;
          }
          &.active {
            border: 1px solid $Mint500 !important;
          }
        }
        > img {
          position: absolute;
          right: 41px;
          top: 36px;
          cursor: pointer;
        }
        .addressBox {
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 41px;
          margin-top: 8px;
          > input {
            width: 316px;
            height: 100%;
            padding: 10px 16px;
            border: 1px solid $Gray300;
            border-radius: 4px;
            @include body-regular($Gray900);
            &::placeholder {
              color: $Gray500;
            }
          }
          > button {
            width: 88px;
            height: 100%;
            background: $Mint500;
            border-radius: 4px;
            @include body3-600($White);
          }
        }
        .addressSearchBox {
          margin-top: 8px;
        }

        .alreadyRegistered {
          width: 100%;
          margin-top: 16px;
          > h6 {
            text-align: center;
            @include body3-500($Red500);
          }
          > p {
            text-align: center;
            @include body3-500($Gray900);
          }
        }

        //.dropdownWrapper {
        //  display: flex;
        //  justify-content: space-between;
        //  align-items: center;
        //  .address {
        //    width: 202px;
        //    height: 41px;
        //    display: flex;
        //    justify-content: space-between;
        //    align-items: center;
        //    padding: 8px 16px;
        //    border: 1px solid $Gray300;
        //    border-radius: 4px;
        //    margin-top: 8px;
        //    cursor: pointer;
        //    transition: ease all .3s;
        //    > span {
        //      @include body-regular($Gray900);
        //    }
        //    > img {
        //      width: 20px;
        //      height: 20px;
        //    }
        //    &:hover {
        //      border: 1px solid $Gray400;
        //    }
        //    &.activeNavy {
        //      border: 1px solid $VuNavy400;
        //    }
        //    &.activeMint {
        //      border: 1px solid $Mint500;
        //    }
        //  }
        //}
        //.dropdownContainer {
        //  position: absolute;
        //  width: 202px;
        //  height: auto;
        //  max-height: 345px;
        //  box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.12);
        //  background: $White;
        //  border-radius: 4px;
        //  margin-top: 2px;
        //  overflow-y: auto;
        //  > p {
        //    width: 100%;
        //    height: 41px;
        //    padding: 10px 16px;
        //    cursor: pointer;
        //    @include body-regular($Gray800);
        //    transition: ease all .2s;
        //    &:hover {
        //      background: $Gray100;
        //    }
        //    &:active {
        //      background: $Gray200;
        //    }
        //    &.active {
        //      background: $Mint100;
        //    }
        //  }
        //  &::-webkit-scrollbar{
        //    width: 5px;
        //  }
        //  &::-webkit-scrollbar-thumb{
        //    /* 스크롤바 막대 높이 설정 */
        //    //height: 40px;
        //    background: $Mint400;
        //    //opacity: 0.16;
        //    /* 스크롤바 둥글게 설정 */
        //    border-radius: 10px;
        //    background-clip: padding-box;
        //    border: 1px solid transparent;
        //  }
        //  &.cityDropdown {
        //    right: 24px;
        //  }
        //
        //}
      }
      .btnWrapper {
        width: 100%;
        height: 88px;
        background: $Gray5;
        padding: 16px;
        border-bottom-left-radius: 16px;
        border-bottom-right-radius: 16px;
        > button {
          width: 100%;
          height: 100%;
          background: $Gray300;
          border-radius: 4px;
          @include body2-600($White);
          cursor: auto;
          transition: ease-out all .2s;
          &.active {
            background: $Mint500;
            cursor: pointer;

          }
        }
      }
    }
    //.btns {
    //  width: 100%;
    //  height: 88px;
    //  background: $Gray5;
    //  padding: 16px;
    //  display: flex;
    //  justify-content: space-between;
    //  align-items: center;
    //  border-bottom-left-radius: 16px;
    //  border-bottom-right-radius: 16px;
    //  .btnSmall {
    //    width: 22%;
    //    height: 52px;
    //    background: $White;
    //    border-radius: 4px;
    //    @include body2-600($Mint600);
    //    background: $Mint100;
    //  }
    //  .btnBig {
    //    width: 76%;
    //    height: 52px;
    //    background: $Gray300;
    //    border-radius: 4px;
    //    @include body2-600($White);
    //    cursor: auto;
    //    &.active {
    //      background: $Primary;
    //      cursor: pointer;
    //    }
    //  }
    //}
  }
}