
          @import "src/assets/scss/colors.scss";
          @import "src/assets/scss/mixins.scss";
        
.dropdownMultipleChoice {
  width: 100%;
  height: 41px;
  min-height: 41px;
.filterWrapper {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
  .dropdownBig {
    width: 100%;
    z-index: 3;
    .listSelected {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: $White;
      height: 41px;
      min-height: 41px;
      padding: 12px 16px 12px 14px;
      width: 100%;
      cursor: pointer;
      border-radius: 4px;
      border: 1px solid $Gray300;
      &:active {
        border: 1px solid $Primary !important;
        box-shadow: -4px -4px 8px rgba(0, 0, 0, 0.04), 8px 8px 16px rgba(0, 0, 0, 0.08);
      }
      >span {
        width: 200px;
        color: $Gray800;
        @include body-regular($Gray800);
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
      > img {
        width: 8px;
        height: 5px;
        transition: ease-out all .5s ;
        &.rotate {
          transform: rotateX(180deg);
        }
      }
    }
    .listSelected.borderPrimary {
      border: 1px solid $Primary;
      box-shadow: -4px -4px 8px rgba(0, 0, 0, 0.04), 8px 8px 16px rgba(0, 0, 0, 0.08);
    }
    .filterLists {
      display: flex;
      flex-direction: column;
      border-radius: 4px;
      margin-top: 2px;
      width: 100%;
      max-height: 271px;
      background-color: $White;
      filter: drop-shadow(0px 6px 20px rgba(0, 0, 0, 0.12));
      .listBox {
        .items {
          display: flex;
          align-items: center;
          height: 41px;
          padding: 10px 14px;
          cursor: pointer;
          > img {
            margin-right: 8px;
            cursor: pointer;
          }
          p {
            cursor: pointer;
            @include body-regular($Gray800);
          }
          &:hover {
            background: $Gray100;
          }
          &:active {
            background: $Gray200;
          }
        }
        &.scroll {
          height: 186px;
          overflow-x: hidden;
          &::-webkit-scrollbar {
            /* 스크롤바 막대 너비 설정 */
            width: 10px;
          }

          &::-webkit-scrollbar-thumb {
            /* 스크롤바 막대 높이 설정 */
            background: $Mint500;
            /* 스크롤바 둥글게 설정 */
            border-radius: 100px;
            background-clip: padding-box;
            border: 3px solid transparent;
          }

          &::-webkit-scrollbar-track {
            /* 스크롤바 막대 높이 설정 */
            background: $Gray300;
            /* 스크롤바 둥글게 설정 */
            border-radius: 100px;
            background-clip: padding-box;
            border: 3px solid transparent;
          }
        }
      }
      .reset {
        width: 100%;
        height: 41px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        > div {
          width: 20px;
          height: 20px;
          margin-right: 6px;
          background: url('https://resources.mora.kr/static/app/mora-ex/assets/web/G_replay.svg');
        }
        > span {
          @include body3-500($Gray300);
        }
        &.active {
          > div {
            background: url('https://resources.mora.kr/static/app/mora-ex/assets/web/B_replay.svg');
          }
          > span {
            @include body3-500($Gray600);
          }
        }
      }
      .done {
        width: 100%;
        height: 41px;
        background: $Gray300;
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        cursor: pointer;
        > span {
          @include body3-600($White);
        }
        &.active {
          background: $Mint100;
          > span {
            @include body3-600($Mint600);
          }
        }
      }

      }
    }
  }
}