
          @import "src/assets/scss/colors.scss";
          @import "src/assets/scss/mixins.scss";
        
.modalAddress {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 999;
  > div {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 500px;
    height: 560px;
    border-radius: 8px;
    background: $White;
    box-shadow: -4px -4px 8px rgba(0, 0, 0, 0.08), 8px 8px 16px rgba(0, 0, 0, 0.16);
    .body {
      width: 100%;
      padding: 12px 8px;
      display: flex;
      flex-direction: column;
      align-content: center;
      justify-content: center;
      background: $Gray100;
      border-top-right-radius: 8px;
      border-top-left-radius: 8px;
      .title {
        text-align: center;
        @include heading4($Black);
      }
    }

  }

}