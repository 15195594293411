
          @import "src/assets/scss/colors.scss";
          @import "src/assets/scss/mixins.scss";
        
.programExerciseSelect {
  display: flex;
  width: 1280px;
  padding: 0 50px;
  margin: 40px auto 120px auto;
  .leftWrapper {
    width: 880px;
    margin: 0 20px 0 0;
    .exerciseBox {
      display: flex;
      .filterContainer {
        width: 280px;
        margin: 0 20px 0 0;
        .rehabFilter {
          width: 100%;
          margin-bottom: 16px;
          .rehabList {
            width: 100%;
            display: flex;
            flex-direction: column;
            padding: 16px 0;
            > p {
              display: flex;
              align-items: center;
              justify-content: space-between;
              @include body2-400($Gray900);
              margin-bottom: 8px;
              cursor: pointer;
              &.active {
                @include body2-600($Primary);
              }
              &:last-child {
                margin-bottom: 0;
              }
            }
          }
        }
        .bodyPartFilter {
          width: 100%;
          .bodyPartTitle {
            width: 100%;
            height: 49px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid $Black;
            padding-right: 8px;
            > p {
              @include heading4Regular($Black);
            }
            .reset {
              display: flex;
              align-items: center;
              > div {
                width: 20px;
                height: 20px;
                margin-right: 6px;
                background: url('https://resources.mora.kr/static/app/mora-ex/assets/web/G_replay.svg') no-repeat;
              }
              > span {
                @include body3-500($Gray300);
              }
              &.active {
                cursor: pointer;
                > div {
                  background: url('https://resources.mora.kr/static/app/mora-ex/assets/web/B_replay.svg') no-repeat;
                }
                > span {
                  @include body3-500($Gray600);
                }
              }
              &.active:active {
                > div {
                  background: url('https://resources.mora.kr/static/app/mora-ex/assets/web/P_replay.svg') no-repeat;
                }
                > span {
                  @include body3-500($Mint700);
                }
              }
            }
          }
          .bookmark {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 16px 0 0 0;
            background: $Gray100;
            border-radius: 4px;
            padding: 4px 8px;
            .bookmarkTitle {
              display: flex;
              align-items: center;
              > img {
                margin-right: 8px;
              }
              > span {
                @include body3-500($Gray900);
              }
            }
            .toggle {
              margin-right: 8px;
              .switch {
                position: relative;
                display: inline-block;
                width: 36px;
                height: 20px;
                vertical-align: middle;
                border-radius: 50%;
                > input {
                  display: none;
                }
              }
              .slider {
                position: absolute;
                cursor: pointer;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: $Gray4;
                -webkit-transition: .4s;
                transition: .4s;
              }
              .slider:before {
                position: absolute;
                content: "";
                height: 16px;
                width: 16px;
                background: $White;
                -webkit-transition: .4s;
                transition: .4s;
              }
              input:checked + .slider {
                background-color: $Primary;
              }
              input:checked + .slider:before {
                -webkit-transform: translateX(16px);
                -ms-transform: translateX(16px);
                transform: translateX(16px);
                border: 2px solid $Primary;
              }
              .slider {
                &.round {
                  width: 36px;
                  height: 20px;
                  border-radius: 34px;
                  &:before {
                    border-radius: 50%;
                    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.08);
                    border: solid 2px $Gray4;
                  }
                }
              }
            }
          }
          .filterWrapper {
            border-bottom: 1px solid $Gray300;
            .filterItem {
              height: 56px;
              display: flex;
              justify-content: space-between;
              align-items: center;
              cursor: pointer;
              > span {
                @include body2-600($Black);
              }
              > img {
                transition: ease all .3s;
                &.rotate {
                  transform: rotateX(180deg);
                }
              }
            }
            .bodyList {
              width: 100%;
              padding: 0 0 16px 0;
              .filterItemBox {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                margin-bottom: 8px;
                .itemBox {
                  display: flex;
                  align-items: center;
                  cursor: pointer;
                  > div {
                    width: 24px;
                    height: 24px;
                    margin-right: 8px;
                    background: url('https://resources.mora.kr/static/app/mora-ex/assets/web/G_checkSquare.svg') no-repeat;
                    &.marginBottom {
                      margin-bottom: 20px;
                    }
                  }
                  > span {
                    @include body3-500($Gray900);
                    > span {
                      @include small-regular($Gray600);
                    }
                    &.lvTxt {
                      display: flex;
                      &.green::before {
                        background: $Green500;
                      }
                      &.whiteGreen::before {
                        background: $Green300;
                      }
                      &.yellow::before {
                        background: $Yellow500;
                      }
                      &.orange::before {
                        background: $Orange;
                      }
                      &.red::before {
                        background: $Red500;
                      }
                    }
                    &.lvTxt::before {
                      display: block;
                      content: '';
                      width: 8px;
                      height: 8px;
                      border-radius: 50%;
                      margin: 7px 8px 0 0;
                    }
                  }
                }
                > span {
                  @include body3-500($Gray500);
                }
                &.active {
                  .itemBox {
                    > div {
                      background: url('https://resources.mora.kr/static/app/mora-ex/assets/web/P_checkSquare.svg') no-repeat;
                    }
                    > span {
                      @include body3-500($Mint500);
                    }
                  }
                }
                &.displayNone {
                  display: none;
                }
              }

            }
          }
          .muFilterWrapper {
            border-bottom: 1px solid $Gray300;
            .filterItem {
              height: 40px;
              align-items: flex-end;
              display: flex;
              justify-content: space-between;
              cursor: pointer;
              margin-bottom: 8px;
              > span {
                @include body2-600($Black);
              }
              > img {
                transition: ease all .3s;
                margin-bottom: 8px;
                &.rotate {
                  transform: rotateX(180deg);
                }
              }
            }
            .muFilterList {
              max-height: 440px;
              overflow-y: auto;
              padding-right: 8px;
              margin-bottom: 8px;
              &::-webkit-scrollbar {
                /* 스크롤바 막대 너비 설정 */
                width: 4px;
              }

              &::-webkit-scrollbar-thumb {
                /* 스크롤바 막대 높이 설정 */
                background: $Mint500;
                /* 스크롤바 둥글게 설정 */
                border-radius: 100px;
                //background-clip: padding-box;
                border: 3px solid transparent;
              }

              &::-webkit-scrollbar-track {
                /* 스크롤바 막대 높이 설정 */
                background: $Gray300;
                /* 스크롤바 둥글게 설정 */
                border-radius: 100px;
                //background-clip: padding-box;
                border: 3px solid transparent;
              }
              .muInput {
                margin-bottom: 12px;
              }
              .bodyList {
                width: 100%;
                .muPartWrapper {
                  .muPart {
                    margin: 12px 0;
                    @include body3-500($Gray900);
                  }
                  .filterItemBox {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-start;
                    margin-bottom: 8px;
                    .itemBox {
                      display: flex;
                      align-items: center;
                      cursor: pointer;
                      > div {
                        width: 24px;
                        height: 24px;
                        margin-right: 8px;
                        background: url('https://resources.mora.kr/static/app/mora-ex/assets/web/G_checkSquare.svg') no-repeat;
                        &.marginBottom {
                          margin-bottom: 20px;
                        }
                      }
                      > span {
                        @include body3-500($Gray900);
                        > span {
                          @include small-regular($Gray600);
                        }
                        &.lvTxt {
                          display: flex;
                          &.green::before {
                            background: $Green500;
                          }
                          &.whiteGreen::before {
                            background: $Green300;
                          }
                          &.yellow::before {
                            background: $Yellow500;
                          }
                          &.orange::before {
                            background: $Orange;
                          }
                          &.red::before {
                            background: $Red500;
                          }
                        }
                        &.lvTxt::before {
                          display: block;
                          content: '';
                          width: 8px;
                          height: 8px;
                          border-radius: 50%;
                          margin: 7px 8px 0 0;
                        }
                      }
                    }
                    > span {
                      @include body3-500($Gray500);
                    }
                    &.active {
                      .itemBox {
                        > div {
                          background: url('https://resources.mora.kr/static/app/mora-ex/assets/web/P_checkSquare.svg') no-repeat;
                        }
                        > span {
                          @include body3-500($Mint500);
                        }
                      }
                    }
                    &.displayNone {
                      display: none;
                    }
                  }
                }


              }
            }

          }
        }
      }
      .listContainer {
        width: 580px;
        .listContainerTop {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .inputBox {
            width: 328px;
            height: 41px;
          }
          .dropdownWrapper {
            display: flex;
            width: 81px;
            margin: 0 0 0 auto;
          }
        }

        .listWrapper {
          min-height: 500px;
          width: 100%;
          .exerciseList {
            margin-bottom: 40px;
          }
          .empty {
            width: fit-content;
            margin: 150px auto;
            > img {
              margin-bottom: 16px;
            }
            > p {
              @include body2-500($Gray2);
              text-align: center;
            }
          }
        }
      }
    }
  }
  .rightWrapper {
    .floatContainer {
      position: sticky;
      top: 148px;
      width: 280px;
      padding: 16px;
      box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 6px 14px rgba(0, 0, 0, 0.15);
      border-radius: 8px;

      .addedExerciseTop {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 8px;
        .arrow {
          width: 24px;
          height: 24px;
          cursor: pointer;
          background: url('https://resources.mora.kr/static/app/mora-ex/assets/web/G_arrowBack.svg') no-repeat;
          &:active {
            background: url('https://resources.mora.kr/static/app/mora-ex/assets/web/P_arrowBack.svg') no-repeat;
          }
        }
        .addedExerciseInfo {
          @include body3-600($Black);
          > span {
            @include body-regular($Gray500);
            margin: 0 4px;
          }
        }
        > button {
          width: 53px;
          height: 30px;
          background: $Mint100;
          border-radius: 4px;
          @include small-semi-bold($Primary);
          &.inactive {
            background: $Gray300;
            cursor: auto;
            @include small-semi-bold($White);
            &:active {
              background: $Gray300;
              cursor: auto;
              @include small-semi-bold($White);
            }
          }
          &:active {
            background: $Mint200;
            cursor: pointer;
            @include small-semi-bold($Mint600);
          }
        }
      }
      .addedExerciseList {
        background: $Gray100;
        max-height: 580px;
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
        &.scroll {
          overflow-y: auto;
        }
        &.scrollHidden {
          &::-webkit-scrollbar {
            display: none; /* Chrome, Safari, Opera*/
          }
        }
        .emptyAddedExercise {
          height: 380px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: $White;
          > p {
            @include body3-500($Gray800);
          }
        }
        .editMenu {
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-bottom: 1px solid $Gray200;
          padding: 13px 8px;
          background: $White;
          .itemSelect {
            display: flex;
            align-items: center;
            >img {
              margin-right: 4px;
              cursor: pointer;
            }
            > span {
              @include body-small-bold($Gray600);
            }
          }
          .editBtns {
            > button {
              width: 53px;
              height: 31px;
              background: $Gray200;
              border-radius: 4px;
              @include small-semi-bold($Gray300);
              cursor: auto;
              &.btnCopy {
                margin-right: 8px;
              }
            }
            &.active {
              > button {
                @include small-semi-bold($Gray600);
                cursor: pointer;
                &:active {
                  @include small-semi-bold($Mint700);
                }
              }
            }
          }
        }
        .editListContainer {
          max-height: 530px;

          &.scroll {
            overflow-y: auto;
          }
          &::-webkit-scrollbar {
            /* 스크롤바 막대 너비 설정 */
            width: 4px;
          }

          &::-webkit-scrollbar-thumb {
            /* 스크롤바 막대 높이 설정 */
            background: $Mint300;
            /* 스크롤바 둥글게 설정 */
            border-radius: 100px;
            //background-clip: padding-box;
            border: 3px solid transparent;
          }

          &::-webkit-scrollbar-track {
            /* 스크롤바 막대 높이 설정 */
            background: $Gray200;
            /* 스크롤바 둥글게 설정 */
            border-radius: 100px;
            //background-clip: padding-box;
            border: 3px solid transparent;
          }
          &.scrollHidden {
            -ms-overflow-style: none; /* IE and Edge */
            scrollbar-width: none; /* Firefox */
            &::-webkit-scrollbar {
              display: none; /* Chrome, Safari, Opera*/
            }
          }
        }
        .exItemWrapper {
          min-height: 73px;
          max-height: 580px;
          overflow-y: auto;

          &::-webkit-scrollbar {
            /* 스크롤바 막대 너비 설정 */
            width: 4px;
          }

          &::-webkit-scrollbar-thumb {
            /* 스크롤바 막대 높이 설정 */
            background: $Mint300;
            /* 스크롤바 둥글게 설정 */
            border-radius: 100px;
            //background-clip: padding-box;
            border: 3px solid transparent;
          }

          &::-webkit-scrollbar-track {
            /* 스크롤바 막대 높이 설정 */
            background: $Gray200;
            /* 스크롤바 둥글게 설정 */
            border-radius: 100px;
            //background-clip: padding-box;
            border: 3px solid transparent;
          }
          .addedExerciseItem {
            position: relative;
            display: flex;
            border-bottom: 1px solid $Gray200;
            padding: 12px 8px;
            background: $White;
            .grayBox {
              width: 48px;
              height: 48px;
              margin-right: 8px;
              border-radius: 4px;
              background: $Gray6;
            }
            > img {
              position: absolute;
              width: 48px;
              height: 48px;
              margin-right: 8px;
              border-radius: 4px;
            }
            .addedExerciseContent {
              display: flex;
              flex-direction: column;
              > p {
                width: 172px;
                @include body3-500($Gray900);
                margin-bottom: 11px;
                margin-top: -4px;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                .tooltiptext {
                  visibility: hidden;
                  opacity: 0;
                  width: fit-content;
                  max-width: 180px;
                  background-color: $Black;
                  border-radius: 4px;
                  position: absolute;
                  z-index: 1;
                  left: 26%;
                  top: -18%;
                  transition: opacity .5s;
                  padding: 2px 4px;
                  white-space: normal;
                  @include tiny2($White);
                  &.long {
                    top: -28px;
                    &::after {
                      bottom: -9px;
                    }
                  }
                  &::after {
                    content: " ";
                    position: absolute;
                    bottom: -43%;
                    left: 50%;
                    transform: rotate(270deg);
                    margin-top: -5px;
                    border-width: 5px;
                    border-style: solid;
                    border-color: transparent $Black transparent transparent;
                  }
                }
                &:hover .tooltiptext {
                  visibility: visible;
                  opacity: 1;
                }
              }
              .exCount {
                display: flex;
                align-items: center;
                .unit {
                  @include small-regular($Gray600);
                  > span {
                    @include small-regular($Gray500);
                    margin: 0 4px;
                  }
                }
              }
            }
            &:first-child {
              .tooltiptext {
                top: 42% !important;
                &::after {
                  bottom: 96% !important;
                  transform: rotate(90deg) !important;
                }
              }
            }
          }
        }


      }

      .programInfo {
        @include heading4Regular($Black);
        margin-bottom: 8px;
      }
      .programInfoDetail {
        .programInput {
          position: relative;
          width: 100%;
          margin: 8px 0;
          > input {
            width: 100%;
            height: 41px;
            padding: 10px 16px;
            border: 1px solid #DDDDDD;
            border-radius: 4px;
            @include body-regular($Black);
            &::placeholder {
              @include body-regular($Gray500);
            }

            &.inputDiagnosis {
              margin: 0;
            }
            &.borderPrimary {
              border: 1px solid $Primary;
            }
            &.paddingRight {
              padding-right: 36px;
            }
          }
          > img {
            position: absolute;
            top: 13px;
            right: 12px;
            cursor: pointer;
          }
          > span {
            display: flex;
            justify-content: flex-end;
            @include small-regular($Gray700);
            margin-top: 2px;
          }
        }

      }

      .exerciseInfo {
        display: flex;
        justify-content: space-between;
        @include heading4Regular($Black);
        margin: 24px 0 8px 0;
        > button {
          width: 93px;
          height: 30px;
          border-radius: 4px;
          cursor: auto;
          &.detail {
            background: $Gray200;
            @include small-semi-bold($Gray300);
            cursor: pointer;
            &.active {
              background: $Mint100;
              @include small-semi-bold($Mint600);
            }
          }
        }
        &.small {
          @include body2-600($Black);
          margin-bottom: 16px;
        }
      }
      .count {
        display: flex;
        align-items: flex-start;
        margin-bottom: 8px;
        > span {
          @include body-regular($Gray900);
          margin-left: 8px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          word-wrap: break-word;
        }
      }
      .btnWrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        > button {
          width: 100%;
          height: 52px;
          border-radius: 4px;
          margin-top: 40px;
          cursor: auto;
          &.next {
            background: $Gray300;
            @include body2-600($White);
            &.active {
              background: $Primary;
              @include body2-600($White);
              cursor: pointer;
            }
          }
        }
      }
      &.blackBorder {
        border: 1px solid $Black;
      }
    }

  }
}