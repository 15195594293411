
          @import "src/assets/scss/colors.scss";
          @import "src/assets/scss/mixins.scss";
        
.notice {
  width: 100%;
  .noticeContent {
    width: 1180px;
    margin: 0 auto;
    > h1 {
      text-align: center;
      @include head3($Black);
      margin: 50px auto 10px auto;
    }
    .searchContainer {
      margin: 0 0 24px auto;
      width: 328px;
    }
    .table {
      width: 100%;
      min-height: 630px;
      padding-bottom: 120px;
      .column {
        width: 100%;
        display: flex;
        padding: 8px 16px;
        border-bottom: 1px solid $Black;
        > p {
          @include body3-600($Black);
          &:first-child {
            width: 108px;
          }
        }
      }
      .contentWrapper {
        margin-bottom: 40px;
        min-height: 409px;
        .content {
          width: 100%;
          display: flex;
          padding: 8px 16px;
          border-bottom: 1px solid $Gray200;
          cursor: pointer;
          transition: ease all .2s;
          .date {
            width: 108px;
            @include body3-500($Gray600);
          }
          .title {
            @include body3-500($Black);
            > span {
              @include body3-500($Mint500);
            }
          }
          &:hover {
            box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.08);
          }
          &:active {
            background: $Gray100;
            box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.08);
          }
        }
        .noContent {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          height: 540px;
          margin: auto;
          > img {
            width: 200px;
            height: 200px;
            &.width240 {
              width: 240px;
            }
          }
          > p {
            text-align: center;
            margin-top: 16px;
            @include body2-500($Gray600);
          }
        }
      }

    }
  }

}