
          @import "src/assets/scss/colors.scss";
          @import "src/assets/scss/mixins.scss";
        
.jobIcon {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  border-radius: 50%;
  &.doctor {
    width: 12px;
    height: 12px;
    background: $Primary400;
  }
  &.doctorCertified {
    width: 14px;
    height: 14px;
    background: url('https://resources.mora.kr/static/app/mora-ex/assets/web/doctor_certified.svg') no-repeat;
  }
  &.physicalTherapist {
    width: 12px;
    height: 12px;
    background: $PurpleJob;
  }
  &.physicalTherapistCertified {
    width: 14px;
    height: 14px;
    background: url('https://resources.mora.kr/static/app/mora-ex/assets/web/therapist_certified.svg') no-repeat;
  }
  &.fitnessExpert {
    width: 12px;
    height: 12px;
    background: $YellowJob;
  }
  &.orientalDoctor {
    width: 12px;
    height: 12px;
    background: $blueJob;
  }
  &.orientalDoctorCertified {
    width: 14px;
    height: 14px;
    background: url('https://resources.mora.kr/static/app/mora-ex/assets/web/oriental_doctor_certified.svg') no-repeat;
  }
  &.other {
    width: 12px;
    height: 12px;
    background: $RedJob;
  }
  .tooltiptext {
    visibility: hidden;
    opacity: 0;
    width: max-content;
    top: 168%;
    background-color: $Black;
    border-radius: 4px;
    position: absolute;
    z-index: 1;
    transition: opacity .5s;
    padding: 4px 12px;
    white-space: normal;
    @include body-regular($White);
    &::after {
      content: " ";
      position: absolute;
      bottom: 100%;
      left: 42%;
      transform: rotate(90deg);
      border-width: 5px;
      border-style: solid;
      border-color: transparent $Black transparent transparent;
    }
  }
  &:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }
}