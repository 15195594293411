
          @import "src/assets/scss/colors.scss";
          @import "src/assets/scss/mixins.scss";
        
.setPassword {
  min-width: 1280px;
  display: flex;
  .container {
    display: flex;
    flex-direction: column;
    margin: 80px auto 0 auto;
    align-items: flex-start;
    .title {
      @include heading2-thin($Gray900);
      margin-bottom: 8px;
    }

    .steps {
      width: 328px;
      height: 4px;
      display: flex;
      justify-content: space-between;
      margin-bottom: 24px;
      > div {
        width: 162px;
        height: 100%;
        background: $Gray700;
        border-radius: 40px;
      }
    }

    .content {
      margin-bottom: 24px;
      width: 328px;
      .pwd {
        @include small-medium($Black);
        margin-bottom: 4px;
      }
      > input {
        width: 100%;
        height: 41px;
        border-radius: 4px;
        border: 1px solid $Gray4;
        padding: 16px;
        @include body2-500($Black);
        margin-bottom: 8px;
        &::placeholder {
          color: $Gray3;
        }
      }
      .err {
        display: flex;
        align-items: center;
        @include small-medium($Alert);
        margin-top: 4px;
        padding-left: 16px;
      }
    }
    .content:nth-last-child(2) {
      margin-bottom: 40px;
    }
  }
}