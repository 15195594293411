
          @import "src/assets/scss/colors.scss";
          @import "src/assets/scss/mixins.scss";
        
.calendarSmall {
  > div {
    position: absolute;
    width: 248px;
    min-height: 267px;
    border-radius: 4px;
    border: 1px solid #07BEB8;
    right: 255px;
    bottom: -10px;
    background: #FFFFFF;
    padding: 10px 18px;

  }
}

