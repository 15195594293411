
          @import "src/assets/scss/colors.scss";
          @import "src/assets/scss/mixins.scss";
        
.exerciseDragItem {
  position: relative;
  display: flex;
  border-bottom: 1px solid $Gray200;
  padding: 12px 8px;
  background: $White;
  .circleImg {
    margin-right: 8px;
    cursor: pointer;
  }
  > div {
    display: flex;
    .imgBack {
      position: absolute;
      width: 48px;
      height: 48px;
      margin-right: 8px;
      border-radius: 4px;
      background: $Gray200;
      left: 37px;
    }
    .thumbnail {
      width: 48px;
      height: 48px;
      margin-right: 8px;
      border-radius: 4px;
      z-index: 1;
    }

    .addedExerciseContent {
      display: flex;
      flex-direction: column;
      > p {
        width: 124px;
        @include body3-500($Gray900);
        margin-bottom: 3px;
        margin-top: -4px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        .tooltiptext {
          visibility: hidden;
          opacity: 0;
          width: fit-content;
          max-width: 144px;
          background-color: $Black;
          border-radius: 4px;
          position: absolute;
          z-index: 1;
          left: 38%;
          top: -18%;
          transition: opacity .5s;
          padding: 2px 4px;
          white-space: normal;
          @include tiny2($White);
          &.long {
            top: -28px;
            &::after {
              bottom: -9px;
            }
          }
          &::after {
            content: " ";
            position: absolute;
            bottom: -43%;
            left: 50%;
            transform: rotate(270deg);
            margin-top: -5px;
            border-width: 5px;
            border-style: solid;
            border-color: transparent $Black transparent transparent;
          }
        }
        &:hover .tooltiptext {
          visibility: visible;
          opacity: 1;
        }
      }
      .editSelections {
        display: flex;
        align-items: center;
        .exCount {
          display: flex;
          align-items: center;
          width: 50px;
        }
        > span {
          @include small-regular($Gray500);
          margin: 0 4px;
        }
        .set {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 50px;
          height: 26px;
          border: 1px solid $Gray300;
          padding: 5px;
          border-radius: 4px;
          > input {
            max-width: 16px;
            border: none;
            padding: 0;
            text-align: end;
            @include small-regular($Gray900);
          }
          > span {
            @include small-regular($Gray900);
          }
          &.active {
            border: 1px solid $Mint500;
          }
        }
      }
    }
  }
  &:first-child {
    .tooltiptext {
      top: 42% !important;
      &::after {
        bottom: 96% !important;
        transform: rotate(90deg) !important;
      }
    }
  }
}