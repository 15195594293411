
          @import "src/assets/scss/colors.scss";
          @import "src/assets/scss/mixins.scss";
        
.noti {
  width: 100%;
  .container {
    width: 100%;
    min-width: 1280px;
    min-height: 900px;
    .wrapper {
      width: 1280px;
      display: flex;
      justify-content: center;
      margin: 0 auto;
      padding: 40px 0 120px 0;
      .content {
        position: relative;
        width: 822px;
        min-height: 589px;
        background: $Gray100;
        border-radius: 8px;
        margin-left: 20px;
        padding: 16px;
        .header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          border-bottom: 1px solid $Gray4;
          padding-bottom: 16px;
          .contentTitle {
            @include heading3Thin;
          }
          .read {
            text-decoration: underline;
            @include body3-500($Gray800);
            cursor: pointer;
          }
        }
        .bodyWrapper {
          height: calc(100% - 50px);
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .body {
            margin: 24px 0 40px 0;
            .item {
              display: flex;
              align-items: center;
              justify-content: space-between;
              background: $White;
              width: 100%;
              border-radius: 8px;
              padding: 8px 16px;
              margin: 0 0 16px 0;
              cursor: pointer;
              > div {
                display: flex;
                align-items: center;
                .tag {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  width: 48px;
                  height: 22px;
                  background: $Gray6;
                  border-radius: 4px;
                  margin-right: 72px;
                  > span {
                    @include small-semi-bold($Gray800);
                  }
                  &.blue {
                    background: $Mint200;
                  }
                  &.green {
                    background: $Green200;
                  }
                  &.yellow {
                    background: $Yellow200;
                  }
                }
                .itemContent {
                  .notiTitle {
                    @include body-bold($Mint500);
                    margin-bottom: 4px;
                    padding-top: 3px;
                    > span {
                      margin-left: 8px;
                      @include body-regular($Gray600);
                    }
                    &.thin {
                      @include body3-500($Gray600);
                    }
                  }
                }
              }
              .date {
                @include small-bold($Black);
                &.thin {
                  @include small-regular($Gray600);
                }
              }
              &.unread {
                background: $Mint100;
                transition: ease all .1s;
                &:hover {
                  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.08);
                }
              }
              &:last-child {
                margin: 0 0 0 0;
              }

            }
          }
          .bodyEmpty {
            width: 100%;
            height: 100%;
            align-items: center;
            display: flex;
            justify-content: center;
            border-radius: 8px;
            > div {
              display: flex;
              flex-direction: column;
              > img {
                width: 230px;
                height: 230px;
                margin-bottom: 16px;
              }
              > p {
                width: 100%;
                @include body2-500($Gray600);
                text-align: center;
              }
            }
          }
        }

      }
    }
  }
}