
          @import "src/assets/scss/colors.scss";
          @import "src/assets/scss/mixins.scss";
        
.app{
  width: 100%;
  min-width: 1200px;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
* {
  box-sizing: border-box;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

button {
  border: 0;
  background: none;
  cursor: pointer;
}

input, textarea, button {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  font-family:Pretendard,'Apple SD Gothic Neo',Sans-serif;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
  color: black;
}

* {
  outline: 0;
}

body {
  font-family: Pretendard, 'Apple SD Gothic Neo', Sans-serif;
  height: 100%;
  margin: 0;
  padding: 0;
  line-height: 1;
  //overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /*-webkit-touch-callout: none;*/
  /*-webkit-user-select: none;*/
  /*-moz-user-select: none;*/
  /*-ms-user-select: none;*/
  /*user-select: none;*/
  /*master: hidden, develop: auto*/

}

.pointer {
  cursor: pointer;
}

//@include mobile {
//  body {
//    overflow-x: auto;
//  }
//  .app {
//    min-width: 360px;
//    overflow-x: auto;
//  }
//}
//
//@include tablet {
//  body {
//    overflow-x: auto;
//  }
//}
//@include desktop {
//  body {
//    overflow-x: auto;
//  }
//}