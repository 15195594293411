
          @import "src/assets/scss/colors.scss";
          @import "src/assets/scss/mixins.scss";
        
.rehabCardExercise {
  position: relative;
  width: 272px;
  height: 350px;
  cursor: pointer;
  border-radius: 14px;
  margin: 0 16px 16px 0;
  transition: ease-out all .3s ;
  .exerciseItemWrapper{
    position: relative;
    .iconBox {
      position: absolute;
      z-index: 1;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 9px;
      right: 10px;
      > div {
        width: 28px;
        height: 28px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 10px 0 0 0;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .exerciseItem {
      width: 272px;
      height: 272px;
      border-radius: 8px;
      transition: ease-out all .3s ;
      background: $Gray5;
      > video {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 8px;
      }
      > img {
        border-radius: 8px;
        width: 100%;
        height: 100%;
      }
      .exerciseTitleBox{
        width: 100%;
        min-height: 75px;
        position: absolute;
        display: flex;
        align-items: flex-start;
        padding: 8px;
        margin-top: -2px;
        //border-radius: 8px;
        border-bottom-right-radius: 8px;
        border-bottom-left-radius: 8px;
        > img {
          margin-right: 9px;
        }
        .exNoteBox {
          > p {
            max-width: 215px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            @include small-medium($Mint500);
            span{
              @include small-medium($Mint500);
            }
          }
          > h6{
            max-width: 215px;
            @include body3-600($Gray900);
            margin-bottom: 8px;
            font-weight: 600;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            word-wrap: break-word;
          }
          .workoutSubTitleBox{
            > p {
              display: flex;
              align-items: baseline;
              @include small-semi-bold($Gray600);
              &.green::before {
                background: $Success;
              }
              &.whiteGreen::before {
                background: $Green300;
              }
              &.yellow::before {
                background: $Favorite;
              }
              &.orange::before {
                background: $Orange600;
              }
              &.red::before {
                background: $Alert;
              }
            }
            > p::before {
              content: '';
              width: 8px;
              height: 8px;
              border-radius: 50%;
              margin-right: 4px;
            }
          }
        }


      }
    }

  }
  &:hover {
    box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.08);
  }
}
.rehabCardExercise:nth-child(3n) {
  margin-right: 0;
}