/*푸터 약관 */

.moraexServiceFooter {
  width: 100%;
}

.moraexServiceFooter .AgreementTitle {
  border-bottom: 1px solid #222222;
  margin: 80px auto 64px auto;
  width: 1180px;
}

.moraexServiceFooter .AgreementTitle > h1 {
  padding: 10px 0;
  font-family: 'Pretendard';
  font-size: 40px;
  font-weight: 700;
  line-height: 150%;
  color: #222222;
}

.moraexServiceFooter .termsAndConditionsContent {
  width: 1180px;
  height: 100%;
  margin: 0 auto 120px auto;
}

.moraexServiceFooter .termsAndConditionsContent .title {
  font-family: 'Pretendard';
  font-size: 20px;
  font-weight: 600;
  line-height: 150%;
  color: #222222;
  margin: 15px 0 20px 0;
}

.moraexServiceFooter .termsAndConditionsContent .title:first-child {
  margin: 0 0 20px 0;
}


.moraexServiceFooter .termsAndConditionsContent .subTitle {
  font-family: 'Pretendard';
  font-size: 16px;
  font-weight: 700;
  line-height: 25.6px;
  color: #545454;
  margin: 15px 0;
}

.moraexServiceFooter .termsAndConditionsContent .content {
  font-family: 'Pretendard';
  font-size: 16px;
  font-weight: 400;
  line-height: 25.6px;
  color: #545454;
}

.moraexServiceFooter .termsAndConditionsContent .subContent {
  font-family: 'Pretendard';
  font-size: 16px;
  font-weight: 400;
  line-height: 25.6px;
  color: #747474;
  padding-left: 15px;
}

/*본인인증 약관*/
.moraexServiceAuth {
  width: 100%;
  height: 120px;
  overflow-x: hidden;
}

.moraexServiceAuth .termsAndConditionsContent {
  width: 100%;
  height: 120px;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 10px 16px;
}

.moraexServiceAuth .termsAndConditionsContent::-webkit-scrollbar{
  width: 5px;
}

.moraexServiceAuth .termsAndConditionsContent::-webkit-scrollbar-thumb{
  /* 스크롤바 막대 높이 설정 */
  /*  height: 40px;*/
  background: #07BEB8;
    /*opacity: 0.16;*/
  /* 스크롤바 둥글게 설정 */
  border-radius: 10px;
  background-clip: padding-box;
  border: 1px solid transparent;
}

.moraexServiceAuth .termsAndConditionsContent .title {
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  color: #545454;
  margin: 15px 0 12px 0;
}

.moraexServiceAuth .termsAndConditionsContent .title:first-child {
  margin: 0 0 12px 0;
}

.moraexServiceAuth .termsAndConditionsContent .subTitle {
  font-family: 'Pretendard';
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: #747474;
  margin: 10px 0;
}

.moraexServiceAuth .termsAndConditionsContent .content {
  font-family: 'Pretendard';
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: #747474;
}

.moraexServiceAuth .termsAndConditionsContent .subContent {
  font-family: 'Pretendard';
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: #747474;
  padding-left: 15px;
}