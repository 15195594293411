
          @import "src/assets/scss/colors.scss";
          @import "src/assets/scss/mixins.scss";
        
.staff {
  width: 100%;
  .content {
    width: 1180px;
    min-height: 800px;
    margin: 40px auto 120px auto;
    padding: 16px 16px 34px 16px;
    background: $Gray100;
    border-radius: 4px;
    .staffTop {
      display: flex;
      justify-content: space-between;
      margin-bottom: 24px;
      .inputWrapper {
        width: 280px;
      }
      .btnWrapper {
        display: flex;
        align-items: center;
        > p {
          @include body3-500($Gray800);
          margin-right: 24px;
          > span {
            margin-left: 4px;
            @include body3-600($Mint500);
          }
        }
        > button {
          width: 112px;
          height: 41px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          background: $Primary;
          padding: 10px 20px 10px 17px;
          border-radius: 4px;
          transition: ease all .3s;
          > span {
            @include body3-600($White);
          }
          &:hover {
            background: $Mint400;
          }
          &:active {
            background: $Mint700;
          }
        }
      }

    }
    .listWrapper {
      .staffList {
        min-height: 650px;
        margin-bottom: 10px;
        .listHeader {
          display: flex;
          width: 100%;
          height: 53px;
          padding: 16px 24px;
          > p {
            @include body-regular($Gray1);
            text-align: center;
            margin-right: 93px;
          }
          .name {
            width: 200px;
          }
          .auth {
            width: 120px;
          }
          .date {
            width: 180px;
          }
          .status {
            width: 164px;
            margin-right: 15px;
          }
          .note {
            width: 110px;
            margin-right: 0;
            text-align: end;
          }
        }

        .myContent {
          width: 100%;
          height: 73px;
          display: flex;
          align-items: center;
          background: $White;
          border-radius: 8px;
          padding: 16px 24px;
          transition: ease all .1s;
          .name {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 200px;
            margin-right: 93px;
            > span {
              @include body3-500($Gray800);
              margin-left: 4px;
            }
          }
          .auth {
            position: relative;
            width: 120px;
            margin-right: 93px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .authSelected {
              width: 100%;
              height: 41px;
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding: 10px 16px 10px 14px;
              border: 1px solid $Gray300;
              border-radius: 4px;
              cursor: pointer;
              > span {
                @include body-regular($Gray800);
              }
              > img {
                transition: ease all .3s;
                &.rotate {
                  transform: rotateX(180deg);
                }
              }
              &.inactive {
                background: $Gray100;
                border: 1px solid $Gray300 !important;
                cursor: auto;
                > span {
                  @include body-regular($Gray500);
                }
              }
              &:hover {
                border: 1px solid $Gray400;
              }
              &.active {
                border: 1px solid $Primary;
              }
            }
            .authOptions {
              position: absolute;
              top: 45px;
              left: 0;
              width: 260px;
              background: $White;
              filter: drop-shadow(0px 6px 20px rgba(0, 0, 0, 0.12));
              border-radius: 4px;
              z-index: 1;
              cursor: pointer;
              > div {
                width: 100%;
                height: 59px;
                padding: 10px 14px;
                transition: ease all .3s;
                .option {
                  @include body-regular($Gray800);
                  border-top: 4px;
                }
                .description {
                  @include small-regular($Gray600);
                  border-bottom: 4px;
                }
                &:hover {
                  background: $Gray100;
                }
                &.active {
                  background: $Mint100;
                }
                &:active {
                  background: $Gray200;
                }
              }
            }
          }
          .date {
            width: 180px;
            margin-right: 93px;
            display: flex;
            justify-content: center;
            align-items: center;
            > span {
              @include body3-500($Gray800);
            }
          }
          .status {
            width: 164px;
            margin-right: 15px;
            display: flex;
            justify-content: center;
            align-items: center;
            > span {
              @include small-medium($Gray900);
              padding: 2px 6px;
              border-radius: 4px;
              background: $Mint300;
            }
          }
          .note {
            position: relative;
            width: 110px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            .noteImg {
              width: 32px;
              height: 24px;
              //background: url('https://resources.mora.kr/static/app/mora-ex/assets/web/G_note.svg') no-repeat;
              transition: ease all .3s;
              //&.active {
              background: url('https://resources.mora.kr/static/app/mora-ex/assets/web/B_note.svg') no-repeat;
              cursor: pointer;
              //}
              &:hover,
              &.notePrimary {
                background: url('https://resources.mora.kr/static/app/mora-ex/assets/web/P_note.svg') no-repeat;
                cursor: pointer;
              }
              &.inactive:hover {
                background: url('https://resources.mora.kr/static/app/mora-ex/assets/web/B_note.svg') no-repeat;
              }
              //&.active:hover,
              //&.notePrimary {
              //  background: url('https://resources.mora.kr/static/app/mora-ex/assets/web/P_note.svg') no-repeat;
              //  cursor: pointer;
              //}
            }
            .noteOptions {
              z-index: 1;
              position: absolute;
              top: 24px;
              border-radius: 4px;
              background: $White;
              filter: drop-shadow(0px 6px 20px rgba(0, 0, 0, 0.12));
              > div {
                width: max-content;
                height: 52px;
                padding: 14px 16px;
                @include body2-400($Gray800);
                cursor: pointer;
              }
            }

          }
          .signupAction {
            width: 110px;
            display: flex;
            justify-content: center;
            align-items: center;
            > button {
              width: 53px;
              height: 30px;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 4px;
              @include small-semi-bold($White);
              transition: ease all .3s;
              &.green {
                background: $Green400;
                margin-right: 4px;
                &:active {
                  background: $Green500;
                }
              }
              &.red {
                background: $Red400;
                &:active {
                  background: $Red500;
                }
              }
            }
          }
          &:hover {
            box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.08);
          }
          &.inactive {
            display: none;
          }
          &:last-child {
            margin-bottom: 0;
          }
          &.meContent {
            border-bottom: 1px solid $Gray200;
          }
        }
        hr {
          border: 0;
          height: 1px;
          background-color: $Gray200;
          margin: 16px 0;
        }

        .listContentWrapper {
          margin-bottom: 16px;
          .listContent {
            width: 100%;
            height: 73px;
            display: flex;
            align-items: center;
            background: $White;
            border-radius: 8px;
            padding: 16px 24px;
            transition: ease all .1s;
            .name {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 200px;
              margin-right: 93px;
              > span {
                @include body3-500($Gray800);
                margin-left: 4px;
              }
            }
            .auth {
              position: relative;
              width: 120px;
              margin-right: 93px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              .authSelected {
                width: 100%;
                height: 41px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 10px 16px 10px 14px;
                border: 1px solid $Gray300;
                border-radius: 4px;
                cursor: pointer;
                > span {
                  @include body-regular($Gray800);
                }
                > img {
                  transition: ease all .3s;
                  &.rotate {
                    transform: rotateX(180deg);
                  }
                }
                &.inactive {
                  background: $Gray100;
                  border: 1px solid $Gray300 !important;
                  cursor: auto;
                  > span {
                    @include body-regular($Gray500);
                  }
                }
                &:hover {
                  border: 1px solid $Gray400;
                }
                &.active {
                  border: 1px solid $Primary;
                }
              }
              .authOptions {
                position: absolute;
                top: 45px;
                left: 0;
                width: 260px;
                background: $White;
                filter: drop-shadow(0px 6px 20px rgba(0, 0, 0, 0.12));
                border-radius: 4px;
                z-index: 1;
                cursor: pointer;
                > div {
                  width: 100%;
                  height: 59px;
                  padding: 10px 14px;
                  transition: ease all .3s;
                  .option {
                    @include body-regular($Gray800);
                    border-top: 4px;
                  }
                  .description {
                    @include small-regular($Gray600);
                    border-bottom: 4px;
                  }
                  &:hover {
                    background: $Gray100;
                  }
                  &.active {
                    background: $Mint100;
                  }
                  &:active {
                    background: $Gray200;
                  }
                }
              }
            }
            .date {
              width: 180px;
              margin-right: 93px;
              display: flex;
              justify-content: center;
              align-items: center;
              > span {
                @include body3-500($Gray800);
              }
            }
            .status {
              width: 164px;
              margin-right: 15px;
              display: flex;
              justify-content: center;
              align-items: center;
              > span {
                @include small-medium($Gray900);
                padding: 2px 6px;
                border-radius: 4px;
                &.yellow {
                  background: $Yellow200;
                }
                &.blue {
                  background: $Mint200;
                }
                &.mint {
                  background: $Mint300;
                }
              }
            }
            .note {
              position: relative;
              width: 110px;
              display: flex;
              justify-content: flex-end;
              align-items: center;
              .noteImg {
                width: 32px;
                height: 24px;
                //background: url('https://resources.mora.kr/static/app/mora-ex/assets/web/G_note.svg') no-repeat;
                transition: ease all .3s;
                //&.active {
                  background: url('https://resources.mora.kr/static/app/mora-ex/assets/web/B_note.svg') no-repeat;
                  cursor: pointer;
                //}
                &:hover,
                &.notePrimary {
                  background: url('https://resources.mora.kr/static/app/mora-ex/assets/web/P_note.svg') no-repeat;
                  cursor: pointer;
                }
                &.inactive {
                  cursor: auto;
                  background: url('https://resources.mora.kr/static/app/mora-ex/assets/web/G_note.svg') no-repeat;
                }
                //&.active:hover,
                //&.notePrimary {
                //  background: url('https://resources.mora.kr/static/app/mora-ex/assets/web/P_note.svg') no-repeat;
                //  cursor: pointer;
                //}
              }
              .noteOptions {
                z-index: 1;
                position: absolute;
                top: 24px;
                border-radius: 4px;
                background: $White;
                filter: drop-shadow(0px 6px 20px rgba(0, 0, 0, 0.12));
                > div {
                  width: max-content;
                  height: 52px;
                  padding: 14px 16px;
                  @include body2-400($Gray800);
                  cursor: pointer;
                }
              }

            }
            .signupAction {
              width: 110px;
              display: flex;
              justify-content: center;
              align-items: center;
              > button {
                width: 53px;
                height: 30px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 4px;
                @include small-semi-bold($White);
                transition: ease all .3s;
                &.green {
                  background: $Green400;
                  margin-right: 4px;
                  &:active {
                    background: $Green500;
                  }
                }
                &.red {
                  background: $Red400;
                  &:active {
                    background: $Red500;
                  }
                }
              }
            }
            &:hover {
              box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.08);
            }
            &.inactive {
              display: none;
            }
            &:last-child {
              margin-bottom: 0;
            }
            &.meContent {
              border-bottom: 1px solid $Gray200;
            }
          }
        }
        &.loading {
          min-height: 1200px;
          .listContentWrapper {
            .name {
              @include skeletonBox(100, 21);
              margin-left: 50px;
              margin-right: 143px;
            }
            .auth {
              @include skeletonBox(120, 41);
            }
            .date {
              @include skeletonBox(100, 21);
              margin-left: 40px;
              margin-right: 143px;
            }
            .status {
              @include skeletonBox(57, 21);
              margin-left: 46px;
              margin-right: 98px;
            }
            .note {
              @include skeletonBox(30, 21);
              margin-left: 52px;
            }
          }

        }
      }
    }

    .addCardInputBox{
      width: 100%;
      background: $White;
      padding: 40px 24px;
      .infoBox {
        margin-bottom: 24px;
        >p{
          @include body3-500($Black);
          margin-bottom: 8px;
        }
        >input{
          border: 1px solid $Gray4;
          border-radius: 8px;
          width: 100%;
          height: 56px;
          padding: 16px;
          @include body2-400;
        }
        >input::placeholder {
          @include body2-400($Gray3);
        }
        > input:disabled {
          background: $Gray5;
          border: 1px solid $Gray4;
        }
        &:nth-child(3) {
          margin-bottom: 0;
        }

      }
      > p {
        width: 100%;
        text-align: center;
        @include body3-500($Red400);
        margin-top: 24px;
        > span {
          margin-left: 8px;
          color: $Mint500;
        }
      }
    }
  }
  .addCardInputBox{
    width: 100%;
    background: $White;
    padding: 40px 24px;
    .infoBox {
      margin-bottom: 24px;
      >p{
        @include body3-500($Black);
        margin-bottom: 8px;
      }
      >input{
        border: 1px solid $Gray4;
        border-radius: 8px;
        width: 100%;
        height: 56px;
        padding: 16px;
        @include body2-400;
      }
      >input::placeholder {
        @include body2-400($Gray3);
      }
      > input:disabled {
        background: $Gray5;
        border: 1px solid $Gray4;
      }
      &:nth-child(3) {
        margin-bottom: 0;
      }
    }
    > p {
      width: 100%;
      text-align: center;
      @include body3-500($Red400);
      margin-top: 24px;
      > span {
        margin-left: 8px;
        color: $Mint500;
      }
    }
  }
}