
          @import "src/assets/scss/colors.scss";
          @import "src/assets/scss/mixins.scss";
        
.headerGray {
  position: fixed;
  top: 0;
  width: 100%;
  min-width: 1280px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-self: center;
  background: #F9F9F9;
  border-bottom: 1px solid #F2F2F2;
  padding: 8px 0;
  z-index: 1;
  > img {
    width: 77px;
    height: 20px;
    object-fit: cover;
    cursor: pointer;
  }
}