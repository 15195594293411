
          @import "src/assets/scss/colors.scss";
          @import "src/assets/scss/mixins.scss";
        
.modalSmall {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 999;
  > div {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 296px;
    border-radius: 8px;
    background: $White;
    box-shadow: -4px -4px 8px rgba(0, 0, 0, 0.08), 8px 8px 16px rgba(0, 0, 0, 0.16);
    .body {
      width: 100%;
      padding: 32px 16px;
      display: flex;
      flex-direction: column;
      align-content: center;
      justify-content: center;
      .title {
        text-align: center;
        @include heading4Regular($Black);
        margin: 0 0 8px 0;
      }

      .sub {
        text-align: center;
        @include body3-500($Gray800);
      }
    }
    .footer {
      //display: flex;
      padding: 0 16px 16px 16px;

      .btnFirst {
        width: 100%;
        height: 41px;
        background: $Primary;
        @include body3-600($White);
        border-radius: 4px;
        transition: ease all .3s;
        margin: 0 0 8px 0;
        &:hover {
          background: $PrimaryLight;
        }
        &:active {
          background: $PrimaryDark;
        }
      }

      .btnSecond {
        width: 100%;
        height: 41px;
        background: $Mint100;
        @include body3-600($Primary);
        border-radius: 4px;
      }
    }
  }
  //&.headerDimmed {
  //  height: calc(100% - 64px);
  //  margin-top: 64px;
  //  > div {
  //    top: calc(50% - 64px);
  //  }
  //}
}