.react-calendar {
  box-shadow: 0px 6px 14px 0px rgba(0, 0, 0, 0.15), 0px 0px 2px 0px rgba(0, 0, 0, 0.06);
}

.react-calendar__navigation {
  position: relative;
  margin-bottom: 16px;
}

.react-calendar__navigation__prev2-button,
.react-calendar__navigation__next2-button {
  display: none;
}

.react-calendar__navigation__label__labelText {
  font-size: 14px;
  font-weight: 600;
  line-height: 150%;
}
.react-calendar__navigation__arrow {
  position: absolute;
  font-size: 20px;
  color: #5C5C5C;
}
.react-calendar__navigation__label {
  padding: 0;
}
.react-calendar__navigation__prev-button {
  right: 29px;
  top: -4px;
}

.react-calendar__navigation__next-button {
  right: 2px;
  top: -4px;
}

.react-calendar__month-view__weekdays__weekday {
  text-align: center;
}

.react-calendar__month-view__weekdays__weekday abbr {
  text-decoration: none;
  font-size: 12px;
  font-weight: 500;
  line-height: 150%;
  color: #747474;
}
.react-calendar__month-view__days {
  margin-top: 6px;
}
.react-calendar__tile {
  font-size: 12px;
  font-weight: 500;
  color: #2C2C2C;
  padding: 8px;
}

.react-calendar__tile:disabled {
  text-decoration: line-through;
  color: #C9C9C9 !important;
  background: #ffffff;
  cursor: auto;
}


.react-calendar__month-view__days__day--neighboringMonth {
  color: #C9C9C9;
}

/*.active */
.react-calendar__tile--active {
  color: #ffffff;
  padding: 4px;
  background: #222222;
  border-radius: 50%;
}

.disable {
  pointer-events: none;
  text-decoration: line-through;
  color: #C9C9C9 !important;
  background: #ffffff !important;
}

.disableCircle {
  pointer-events: none;
  color: #ffffff !important;
  background: #C9C9C9;
  border-radius: 50%;
  text-decoration: line-through;
}

