
          @import "src/assets/scss/colors.scss";
          @import "src/assets/scss/mixins.scss";
        
.patientDetail {
  width: 100%;
  .titleContainer {
    width: 1280px;
    padding: 40px 50px 40px 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
    > div {
      width: 100%;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .step {
        display: flex;
        align-items: center;
        margin-bottom: 57px;
        .step1 {
          @include body3-500($Gray2);
          cursor: pointer;
        }
        img {
          margin: 0 4px;
        }
        .step2 {
          @include body3-600($Gray900);
        }
      }

      .patientProfile {
        width: 100%;
        height: 196px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        > div {
          display: flex;
          flex-direction: column;
          width: 580px;
          height: 100%;
          border-radius: 4px;
          border: 1px solid $Gray300;
          padding: 16px;
        }
        .profileContainer {
          .nameContainer {
            width: 100%;
            height: 40px;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            border-bottom: 1px solid $Gray200;
            .nameWrap {
              display: flex;
              align-items: center;
              .patientName {
                @include heading3Thin($Black);
                margin-right: 8px;
              }
              .gender {
                @include body3-500($Gray600);
                margin-right: 4px;
              }
              .bar {
                @include body-regular($Gray400);
                margin-right: 4px;
              }
              .birth {
                @include body3-500($Gray600);
                margin-right: 4px;
              }
            }
            .profileBtns {
              display: flex;
              align-items: center;
              > img {
                cursor: pointer;
              }
              > img:first-child {
                margin-right: 8px;
              }
              > img:last-child {
                margin-right: 8px;
                width: 25px;
                height: 25px;
                margin-bottom: -2px;
              }
            }
          }
          .patientDetailContainer {
            margin-top: 16px;
            > p {
              display: flex;
              @include body-regular($Gray600);
              margin-bottom: 8px;
              > span {
                @include body3-500($Gray800);
                margin-left: 8px;
              }
              &:last-child {
                margin-bottom: 0;
              }
              &::before {
                content: '';
                width: 4px;
                height: 4px;
                display: block;
                border-radius: 50%;
                background: $Gray600;
                margin: 10px 4px 0 0;
              }
            }
          }
        }
        .memoContainer {
          position: relative;
          .memoTop {
            display: flex;
            justify-content: space-between;
            width: 100%;
            height: 34px;
            margin-bottom: 8px;
            .memoTitle {
              @include body2-500($Black);
            }
            .saveBtn {
              display: flex;
              align-items: center;
              @include small-medium($Gray300);
              text-decoration: underline;
              &.dark {
                @include small-medium($Gray600);
                cursor: pointer;
              }
            }
          }
          > textarea {
            width: 100%;
            height: 122px;
            border-radius: 8px;
            border: 1px solid $Yellow300;
            background: $Yellow100;
            padding: 16px 48px 16px 16px;
            resize: none;
            @include body-regular($Gray900);
            -ms-overflow-style: none; /* IE and Edge */
            scrollbar-width: none; /* Firefox */
            &::-webkit-scrollbar {
              display: none;
            }
            &::placeholder {
              @include body-regular($Gray500);
            }
            &.borderDeepYellow {
              border: 1px solid $Yellow500;
            }

          }
          > span {
            position: absolute;
            bottom: 29px;
            right: 33px;
            @include small-regular($Gray300);
            &.dark {
              @include small-regular($Gray700);
            }
          }
          > img {
            width: 20px;
            height: 20px;
            position: absolute;
            top: 74px;
            right: 32px;
            cursor: pointer;
          }
        }
      }
      .planAssignmentBtn {
        position: absolute;
        width: 134px;
        right: 0;
        cursor: pointer;
      }
    }
  }
  .content {
    display: flex;
    margin: 0 auto;
    width: 1280px;
    min-width: 1280px;
    height: auto;
    flex-direction: column;
    > div {
      display: flex;
      width: 1180px;
      min-height: 638px;
      padding: 24px;
      background: $Gray100;
      border-radius: 4px;
      border: 1px solid $Gray400;
      margin: 0 auto 120px auto;
      .planDetail {
        .planName {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 8px;
          > span {
            @include title2Thin($Gray900);
          }
          .planBtns {
            display: flex;
            > button {
              display: flex;
              align-items: center;
              @include small-semi-bold($Gray600);
              padding: 7px 12px;
              background: $Gray200;
              border-radius: 4px;
              > img {
                margin-right: 4px;
              }
              &:first-child {
                margin-right: 8px;
              }
              &.inactive {
                @include small-semi-bold($Gray300);
                cursor: auto;
              }
            }
          }
        }
        .planStatus {
          margin-top: 8px;
          @include small-semi-bold($Gray800);
          border-radius: 4px;
          padding: 2px 6px;
          &.gray {
            background: $Gray300;
          }
          &.blue {
            background: $Mint200;
          }
          &.yellow {
            background: $Yellow200;
          }
          &.lightBlue {
            background: $Mint100;
          }
          &.pink {
            background: $Red100;
          }
        }
        .planWrap {
          display: flex;
          align-items: center;
          width: 100%;
          height: 37px;
          border-bottom: 1px solid $Black;
          margin-top: 16px;
          > span {
            @include body-regular($Gray600);
          }
          > span.bold {
            @include body3-500($Gray900);
            margin: 0 24px 0 8px;
          }
          > span.marginRight {
            margin-right: 10px;
          }
          .shareWrap {
            height: 21px;
            display: flex;
            align-items: center;
            border-radius: 4px;
            padding: 0 8px;
            margin-right: 24px;
            cursor: pointer;
            .share {
              display: inline-flex;
              flex-direction: column;
              align-items: center;
              position: relative;
              margin: 0 0 0 4px;
              > span {
                @include body-regular($Gray600);
              }
              > span.bold {
                @include body3-500($Gray900);
              }
              > span.mint {
                @include body3-500($Mint500);
              }
              .tooltiptextName {
                visibility: hidden;
                opacity: 0;
                width: max-content;
                top: 109%;
                border-radius: 4px;
                position: absolute;
                z-index: 1;
                background: $White;
                transition: opacity .3s;
                padding: 4px 12px;
                white-space: normal;
                @include body-regular($White);
                box-shadow: 0px 6px 14px 0px rgba(0, 0, 0, 0.15), 0px 0px 2px 0px rgba(0, 0, 0, 0.06);
                .staffName {
                  display: flex;
                  align-items: center;
                  margin-bottom: 4px;
                  .planSharedStaffName {
                    @include body-regular($Gray700);
                    margin-left: 8px;
                  }
                  &:last-child {
                    margin-bottom: 0;
                  }
                }
                &::after {
                  content: " ";
                  position: absolute;
                  bottom: 100%;
                  left: 42%;
                  transform: rotate(90deg);
                  border-width: 5px;
                  border-style: solid;
                  border-color: transparent $White transparent transparent;
                }
              }
              .show {
                visibility: visible;
                opacity: 1;
              }
            }
            > img {
              width: 16px;
              height: 17px;
            }
            &:hover {
              background: $Mint100;
            }
          }

        }
        .exData {
          > p {
            display: flex;
            align-items: center;
            @include heading4Regular($Black);
            margin: 40px 0 24px 0;
            &:before {
              display: block;
              content: '';
              width: 6px;
              height: 6px;
              background: $Gray600;
              border-radius: 50%;
              margin: 0 8px 0 0;
            }
          }
          .exDataContainer {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 413px;
            background: $White;
            border: 1px solid $Gray300;
            border-radius: 4px;
            margin-bottom: 40px;
            padding: 24px;
            .exGraph {
              width: 748px;
              height: 100%;
              margin-right: 24px;
            }
            .exNumbers {
              width: 312px;
              display: flex;
              flex-direction: column;
              > div {
                width: 100%;
                height: 117px;
                margin-bottom: 16px;
                background: $Gray100;
                border-radius: 4px;
                padding: 16px;
                .exNumberTitle {
                  @include body3-500($Gray900);
                }
                .exNumber {
                  @include headingSmall($Black);
                  margin-bottom: 8px;
                }
                .exNumberBar {
                  position: relative;
                  width: 100%;
                  height: 8px;
                  .backBar {
                    width: 100%;
                    height: 100%;
                    background: $Gray200;
                    border-radius: 4px;
                  }
                  .colorBar {
                    position: absolute;
                    top: 0;
                    height: 100%;
                    border-radius: 4px;
                    &.red {
                      background: $Red400;
                    }
                    &.green {
                      background: $Green400;
                    }

                    &.pain0 {
                      background: $Pain0;
                    }
                    &.pain1 {
                      background: $Pain1;
                    }
                    &.pain2 {
                      background: $Pain2;
                    }
                    &.pain3 {
                      background: $Pain3;
                    }
                    &.pain4 {
                      background: $Pain4;
                    }
                    &.pain5 {
                      background: $Pain5;
                    }
                    &.pain6 {
                      background: $Pain6;
                    }
                    &.pain7 {
                      background: $Pain7;
                    }
                    &.pain8 {
                      background: $Pain8;
                    }
                    &.pain9 {
                       background: $Pain9;
                     }
                    &.zIndex {
                      z-index: 2;
                    }
                  }
                  .previousBar {
                    position: absolute;
                    top: 0;
                    height: 100%;
                    background: $Gray300;
                    border-radius: 4px;
                    &.flatRight {
                      border-top-right-radius: 0;
                      border-bottom-right-radius: 0;
                      z-index: 1;
                    }
                    &.zIndex {
                      z-index: 2;
                    }

                  }
                }
                &:last-child {
                  margin-bottom: 0;
                  height: 101px;
                }
              }
            }
          }
          .rehabContainer {
            width: 100%;
            display: flex;
            flex-direction: column;
            background: $White;
            border: 1px solid $Gray300;
            border-radius: 4px;
            padding: 24px;
            .rehabDate {
              position: relative;
              width: 100%;
              height: 56px;
              display: flex;
              align-items: center;
              padding: 12px 8px;
              > img {
                margin-right: 8px;
                &.arrowRight {
                  position: absolute;
                  right: 0;
                }
              }
              > span {
                width: 141px;
                padding: 8px;
                @include body2-500($Gray600);
                text-align: center;
                cursor: pointer;
                &.active {
                  background: $Mint100;
                  border: 1px solid $Mint500;
                  border-radius: 4px;
                  @include body2-600($Mint500);
                }
              }
            }
            .rehabDetail {
              width: 100%;
              display: flex;
              justify-content: space-between;
              margin-top: 40px;
              .rehabList {
                width: 748px;
                height: 548px;
                overflow-y: auto;
                &::-webkit-scrollbar {
                  /* 스크롤바 막대 너비 설정 */
                  width: 10px;
                }

                &::-webkit-scrollbar-thumb {
                  /* 스크롤바 막대 높이 설정 */
                  background: $Mint300;
                  /* 스크롤바 둥글게 설정 */
                  border-radius: 100px;
                  background-clip: padding-box;
                  border: 3px solid transparent;
                }

                &::-webkit-scrollbar-track {
                  /* 스크롤바 막대 높이 설정 */
                  background: $Gray200;
                  /* 스크롤바 둥글게 설정 */
                  border-radius: 100px;
                  background-clip: padding-box;
                  border: 3px solid transparent;
                }
                .rehabItem {
                  width: 100%;
                  height: 48px;
                  display: flex;
                  align-items: center;
                  border-bottom: 1px solid $Gray200;
                  .rehabThumbnail {
                    position: relative;
                    display: flex;
                    align-items: center;
                    width: 595px;
                    > img {
                      width: 40px;
                      height: 40px;
                      border-radius: 4px;
                      margin-right: 8px;
                      z-index: 1;
                    }
                    .grayBack {
                      width: 40px;
                      height: 40px;
                      background: $Gray100;
                      position: absolute;
                    }
                    .rehabItemTitle {
                      display: flex;
                      flex-direction: column;
                      .rehabItemName {
                        @include body-regular($Gray900);
                      }
                      .rehabItemLv {
                        display: flex;
                        align-items: center;
                        @include small-medium($Gray600);
                        &:before {
                          display: block;
                          content: '';
                          width: 8px;
                          height: 8px;
                          border-radius: 50%;
                          margin-right: 4px;
                        }
                        &.green::before {
                          background: $Green500;
                        }
                        &.whiteGreen::before {
                          background: $Green300;
                        }
                        &.yellow::before {
                          background: $Yellow500;
                        }
                        &.orange::before {
                          background: $Orange;
                        }
                        &.red::before {
                          background: $Red500;
                        }
                      }
                    }
                  }
                  .rehabCount {
                    display: flex;
                    align-items: center;
                    margin-right: 20px;
                    .exSearch {
                      cursor: pointer;
                    }
                    .unit {
                      display: flex;
                      align-items: center;
                      justify-content: space-evenly;
                      width: 100px;
                      text-align: right;
                      @include small-regular($Gray900);
                      margin: 0 20px;
                      .multiply {
                        margin: 0 4px;
                        @include small-regular($Gray500);
                      }
                    }
                    .percent {
                      width: 32px;
                      text-align: end;
                      @include small-semi-bold($Gray900);
                      &.primary {
                        @include small-semi-bold($Mint500);
                      }
                      &.gray {
                        @include small-semi-bold($Gray300);
                      }
                    }
                  }
                }
              }
              .rehabWrap {
                width: 312px;
                > div {
                  position: relative;
                  width: 100%;
                  height: 125px;
                  background: $Gray100;
                  border-radius: 8px;
                  padding: 16px;
                  margin-bottom: 11px;
                  .rehabWrapTitle {
                    @include body3-500($Gray900);
                    margin-bottom: 8px;
                  }
                  .rehabWrapNumber {
                    @include headingSmall($Black);
                    &.black {
                      @include headingSmall($Black);
                    }
                    &.red {
                      @include headingSmall($Red500);
                    }
                    &.green {
                      @include headingSmall($Green500);
                    }
                  }
                  .rehabWrapTxt {
                    @include small-medium($Gray600);
                    &.red {
                      @include small-medium($Red500);
                    }
                    &.difficultEx {
                      display: flex;
                      &:before {
                        display: flex;
                        align-items: center;
                        background: $Gray600;
                        content: '';
                        border-radius: 50%;
                        width: 4px;
                        height: 4px;
                        margin-top: 7px;
                        margin-right: 4px;
                      }
                    }
                  }
                  > img {
                    position: absolute;
                    bottom: 35px;
                    right: 16px;
                  }
                  &:last-child {
                    height: auto;
                    min-height: 143px;
                  }
                }
              }
            }
            > button {
              width: 748px;
              height: 41px;
              border-radius: 4px;
              background: $Gray200;
              @include body3-600($Gray600);
            }
          }
        }
      }
      .noContent {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        flex-direction: column;
        margin: auto;
        > img {
          margin-bottom: 16px;
        }
        > p {
          @include body2-500($Gray1);
          width: 100%;
          text-align: center;
        }
      }
      .scheduleContent {
        width: 100%;
        .calendar {
          width: 100%;
          .header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 32px;
            width: 100%;
            margin: 0 0 24px 0;
            .date {
              display: flex;
              > p {
                @include heading4Regular($Black);
              }
            }
            .btns {
              display: flex;
              align-items: center;
              justify-content: center;

              > button {
                padding: 0;
                width: 32px;
                height: 32px;
                &:first-child {
                  margin-right: 8px;
                  > img {
                    transform: rotateY(180deg);
                  }

                }
              }
            }

            .todayMoveBtn {
              > button {
                padding: 0;
                width: 41px;
                height: 26px;
                background: $Gray200;
                border-radius: 4px;
                > p {
                  @include small-medium($Gray600);
                }
              }
            }
          }

          .body {
            height: 100%;
            border-radius: 4px;
            display: flex;
            flex-direction: column;
            border: 1px solid $Gray4;
            background: $White;
            .week {
              width: 100%;
              height: 32px;
              display: flex;
              border-top-left-radius: 4px;
              border-top-right-radius: 4px;

              > div {
                width: calc((100%) / 7);
                height: 100%;
                display: flex;
                align-items: center;
                border-right: 1px solid $Gray200;
                justify-content: center;
                &:last-child {
                  border-right: none;
                }

                > p {
                  @include body3-500($Gray600);
                }
              }
            }
            .scheduleCalendarWeek div:first-child {
              border-top-left-radius: 4px;
            }
            .scheduleCalendarWeek div:last-child {
              border-right: none;
              border-top-right-radius: 4px;
            }

            .calendarDate .dateBox:last-child {
              border-bottom-left-radius: 10px;
              border-bottom-right-radius: 10px;
            }

            .calendarDate .dateBox div:last-child {
              border-right: none;
            }

            .calendarDate {
              width: 100%;
              height: 100%;
              display: flex;
              flex-direction: column;

              .dateBox {
                width: 100%;
                display: flex;
                border-top: 1px solid $Gray5;
                background: $White;

                > div {
                  cursor: pointer;
                  width: calc(100% / 7);
                  min-height: 120px;
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  border-right: 1px solid $Gray5;
                  padding: 8px 0 0 0;
                  position: relative;

                  .eachDate {
                    width: 24px;
                    height: 24px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-bottom: 8px;
                    border-radius: 100%;
                    > p {
                      @include body3-500($Gray900);
                      letter-spacing: -2%;
                    }
                  }
                  .today{
                    >p{
                      color: $Mint500;
                    }
                  }
                  .disableDate{
                    >p{
                      color: $Gray3;
                    }
                  }
                  .scheduleBox {
                    width: 100%;
                    height: 80px;
                    overflow-y: hidden;
                    > div {
                      display: none;
                      margin-bottom: 2px;
                      &:first-child,
                      &:nth-child(2),
                      &:nth-child(3) {
                        display: flex;
                      }
                    }
                    .list {
                      .treatmentColor {
                        > div {
                          width: 6px;
                          height: 6px;
                          background: $Green300;
                          border-radius: 50px;
                          margin: 8px 4px 4px 4px;
                        }
                      }

                      .exColor {
                        > div {
                          width: 6px;
                          height: 6px;
                          background: $Yellow300;
                          border-radius: 50px;
                          margin: 4px;
                        }
                      }

                      .surgeryColor {
                        > div {
                          width: 6px;
                          height: 6px;
                          background: $Red300;
                          border-radius: 50px;
                          margin: 8px 4px 4px 4px;
                        }
                      }

                      .etcColor {
                        > div {
                          width: 6px;
                          height: 6px;
                          background: $Yellow;
                          border-radius: 50px;
                          margin: 8px 4px 4px 4px;
                        }
                      }

                      > p {
                        text-align: left;
                        @include small-medium($Black);
                        line-height: 22px;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;
                        padding-right: 4px;
                      }
                    }
                  }


                  .plan {
                    background: $Mint200;
                    height: 22px;
                    padding: 0 8px;

                    > p {
                      @include body3-500($Black);
                      text-overflow: ellipsis;
                      overflow: hidden;
                      white-space: nowrap;
                    }
                  }

                  .rest {
                    width: 100%;
                    margin-top: auto;
                    > p {
                      margin: 0 auto 10px 8px;
                      width: fit-content;
                      text-align: right;
                      color: $Gray900 !important;
                      @include small-medium($Gray900);
                    }
                  }
                  .modal {
                    position: absolute;
                    top: 20px;
                    width: 208px;
                    background: $White;
                    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1), 0px 0px 22px rgba(0, 0, 0, 0.25);
                    border-radius: 8px;
                    z-index: 100;
                    .box{
                      .dateWeek{
                        height: 32px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        border-bottom: 1px solid $Black;
                        padding: 8px;
                        >h6{
                          @include body3-500($Gray900);
                        }
                        >img{
                          cursor: pointer;
                        }
                      }
                      .dateSchedule{
                        padding: 8px;
                        height: auto;
                        >div{
                          .detail{
                            width: 100%;
                            height: 18px;
                            background: $Mint200;
                            padding: 0 8px;
                            border-radius: 4px;
                            margin-bottom: 4px;
                            >p{
                              @include small-medium($Black);
                              white-space: nowrap;
                              text-overflow: ellipsis;
                              overflow-x: hidden;
                            }
                          }
                          .noPl{
                            display: flex;
                            align-items: center;
                            margin-bottom: 4px;
                            .treatmentColor{
                              width: 6px;
                              height: 6px;
                              background: $Self0;
                              border-radius: 50px;
                              margin: 4px;
                            }
                            .surgeryColor{
                              width: 6px;
                              height: 6px;
                              background: $Pink;
                              border-radius: 50px;
                              margin: 4px;
                            }
                            .etcColor{
                              width: 6px;
                              height: 6px;
                              background: $Yellow;
                              border-radius: 50px;
                              margin: 4px;
                            }
                            >p{
                              @include small-medium($Gray900);
                              text-align: center;
                            }
                          }
                        }
                        >div:last-child{
                          margin-bottom: 0;
                        }
                      }
                    }
                  }
                }

                .clickDate {
                  .eachDate {
                    background: $Mint500;
                    > p {
                      color: $White;
                    }
                  }
                }

                .sunDayColor p {
                  color: $Alert;
                }


                .disable {
                  pointer-events: none;
                  > div {
                    background: $White !important;
                    > p {
                      color: $Gray300 !important;
                    }
                  }

                  > p {
                    color: $Gray4 !important;
                    border: none !important;
                  }

                  .scheduleBox {
                    .list {
                      display: none;
                    }
                  }

                  .rest{
                    display: none;
                  }
                  .modal{
                    display: none;
                  }
                }

                .disablePast {
                  > p {
                    color: $Gray4 !important;
                    border: none !important;
                  }
                }
              }
            }
          }
        }
        .scheduleEdit {
          margin-top: 44px;
          display: flex;
          flex-direction: column;
          width: 100%;
          border-radius: 14px;
          margin-bottom: 40px;
          .dateTop {
            width: 100%;
            height: 50px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 4px 16px 20px 16px;
            margin-bottom: 24px;
            border-bottom: 1px solid $Gray4;
            .generalTextBox{
              display: flex;
              justify-content: center;
              @include heading4Regular($Black);
            }
            .addScheduleBtn{
              margin-bottom: 0;
              @include small-medium($Gray600);
              text-decoration: underline;
            }
          }
          .addInputSchedule{
            width: 100%;
            height: 200px;
            background: $White;
            border-radius: 4px;
            border: 1px solid $Gray300;
            display: flex;
            align-items: center;
            justify-content: center;
            >p{
              @include body2-500($Gray600);
            }

          }
          .addInputScheduleBox {
            position: relative;
            width: 100%;
            background: $White;
            padding: 16px;
            border-radius: 4px;
            border: 1px solid $Gray4;
            margin-bottom: 8px;
            cursor: pointer;
            .scheduleState{
              display: flex;
              justify-content: space-between;
              width: 100%;
              margin-bottom: 8px;
              .stateList {
                width: 100%;
                display: flex;
                .selectedState{
                  border: 1px solid $Primary;
                  background: $Primary100;
                }
                > div{
                  height: 26px;
                  display: flex;
                  align-items: center;
                  margin-right: 8px;
                  padding: 0 8px;
                  cursor: pointer;
                  border: 1px solid $Gray200;
                  border-radius: 28px;
                  >div{
                    margin-right: 8px;
                  }
                  >p{
                    @include body3-500($Gray900);
                  }
                }
                .treatmentColor{
                  > div {
                    width: 10px;
                    height: 10px;
                    background: $Self0;
                    border-radius: 50px;
                  }
                }
                .surgeryColor{
                  >div{
                    width: 10px;
                    height: 10px;
                    background: $Pink;
                    border-radius: 50px;
                  }
                }
                .etcColor{
                  >div{
                    width: 10px;
                    height: 10px;
                    background: $Yellow;
                    border-radius: 50px;
                  }
                }
              }
              > img {
                cursor: pointer;
              }
            }
            .scheduleInput {
              position: relative;
              display: flex;
              align-item: center;
              justify-content: space-between;
              >input{
                width: 1027px;
                height: 41px;
                outline: none;
                border: none;
                background: $Gray100;
                border-radius: 4px;
                padding: 10px 16px;
                margin-right: 8px;
                @include body2-500($Black);
                &.borderPrimary {
                  border: 1px solid $Primary;
                  background: $White;
                }
              }
              >input::placeholder{
                @include body-regular($Gray500);
              }
              > img {
                position: absolute;
                top: 12px;
                right: 86px;
              }
              >span {
                position: absolute;
                right: 95px;
                top: 8px;
                @include body2-400($Gray3);
              }
              .btnWrapper {
                width: 65px;
              }
            }

          }
          .generalItem {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            background: $White;
            padding: 15px 16px;
            margin-bottom: 18px;
            border-radius: 4px;
            border: 1px solid $Gray300;
            cursor: pointer;
            .generalAdd{
              width: 100%;
              display: flex;
              justify-content: space-between;
              position: relative;
              margin-top: 8px;
              >input{
                width: 1027px;
                height: 41px;
                outline: none;
                border: none;
                border-radius: 4px;
                background: $Gray6;
                padding: 10px 24px;
                margin-right: 8px;
                @include body3-500($Black);
              }
              >input::placeholder{
                @include body2-500($Gray3);
              }
              >p{
                width: 100%;
                height: 41px;
                background: $Gray6;
                border-radius: 4px;
                @include body3-500($Black);
                padding: 10px 24px;
                cursor: pointer;
              }
              .btnWrapper {
                width: 65px;
              }
            }
            .generalCategory{
              width: 100%;
              display: flex;
              justify-content: space-between;
              >div{
                display: flex;
                align-items: center;
                >p{
                  @include body3-500($Gray900);
                }
                .generalColor{
                  margin-right: 8px;
                  >div{
                    width: 10px;
                    height: 10px;
                    background: $Self0;
                    border-radius: 50px;
                  }
                }
                .plan {
                  margin-right: 8px;
                  >div{
                    width: 10px;
                    height: 10px;
                    background: $Mint200;
                    border-radius: 50px;
                  }
                }
                .treatmentColor{
                  margin-right: 8px;
                  >div{
                    width: 10px;
                    height: 10px;
                    background: $Self0;
                    border-radius: 50px;
                  }
                }
                .surgeryColor{
                  margin-right: 8px;
                  >div{
                    width: 10px;
                    height: 10px;
                    background: $Pink;
                    border-radius: 50px;
                  }
                }
                .etcColor{
                  margin-right: 8px;
                  >div{
                    width: 10px;
                    height: 10px;
                    background: $Yellow;
                    border-radius: 50px;
                  }
                }
              }
              >img{
                transform: rotate(0deg);
                transition: ease all 0.5s;
                cursor: pointer;
              }
            }
            &.noBottomMargin {
              margin-bottom: 0;
            }
          }

          .planItem {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            background: $White;
            padding: 15px 16px;
            margin-bottom: 16px;
            border-radius: 4px;
            border: 1px solid $Gray300;
            cursor: pointer;
            .generalCategory{
              width: 10px;
              height: 10px;
              background: $Mint200;
              border-radius: 50px;
            }
            .planContent{
              padding-left: 8px;
              @include body3-500($Gray900);
            }
            &.noBottomMargin {
              margin-bottom: 0;
            }
          }
        }
      }
    }
    &.marginTop {
      margin-top: 38px;
    }

  }
  .tabContainer {
    width: 100%;
    height: 48px;
    background: $Gray6;
    border-top: 1px solid $Gray300;
    border-bottom: 1px solid $Gray200;
    .tabWrapper {
      width: 1280px;
      height: 100%;
      display: flex;
      align-items: center;
      margin: 0 auto;
      padding: 0 50px 0 50px;
      > div {
        height: 100%;
        padding: 12px 16px;
        cursor: pointer;
        > p {
          @include body2-500($Gray900);
        }
        &.active {
          cursor: auto;
          border-bottom: 2px solid $Mint500;
          > p {
            @include body2-600($Mint500);
          }
        }

      }
    }
  }
  .dropdownWrapper {
    width: 1280px;
    min-width: 1280px;
    padding: 0 50px;
    height: 52px;
    margin: 38px auto 24px auto;
  }
}