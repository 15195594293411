
          @import "src/assets/scss/colors.scss";
          @import "src/assets/scss/mixins.scss";
        
.patientStatistics {
  width: 100%;
  .filters {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .dropdownOrderWrapper {
      width: 328px;
    }
    .periodBtns {
      display: flex;
      align-items: center;
      > button {
        width: 101px;
        height: 41px;
        border-radius: 4px;
        background: $Gray200;
        margin-left: 8px;
        @include body3-600($Gray600);
        &.active {
          background: $Mint100;
          @include body3-600($Mint700);
        }
      }
    }
    &.inactive {
      .dropdownOrderWrapper {
        pointer-events: none;
      }
      .periodBtns {
        > button {
          pointer-events: none;
        }
      }
    }
  }
  .planCount {
    @include body-regular($Gray900);
    margin: 40px 0;
  }
  .content {
    width: 100%;
    border: 1px solid $Gray300;
    border-radius: 4px;
    background: $White;
    margin: auto;
    padding: 16px;
    .rates {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 24px;
      > div {
        width: 534px;
        height: 117px;
        border: 1px solid $Gray100;
        border-radius: 4px;
        padding: 16px;
        background: $Gray100;
        .title {
          @include body3-500($Gray900);
        }
        .number {
          @include headingSmall($Black);
          margin-bottom: 8px;
        }
        > div {
          position: relative;
          width: 100%;
          height: 8px;
          border-radius: 4px;
          .backBar {
            position: absolute;
            width: 100%;
            height: 8px;
            background: $Gray200;
            border-radius: 4px;
          }
          .coloredBar {
            position: absolute;
            width: 28%;
            height: 100%;
            background: $Mint700;
            border-radius: 4px;
            &.red {
              background: $Red400;
            }
            &.green {
              background: $Green400;
            }
          }
          .previousBar {
            position: absolute;
            top: 0;
            height: 100%;
            border-radius: 4px;
            &.flatRight {
              border-top-right-radius: 0;
              border-bottom-right-radius: 0;
              z-index: 1;
            }
            &.zIndex {
              z-index: 2;
            }
          }
          .currentBar {
            position: absolute;
            top: 0;
            height: 100%;
            border-radius: 4px;
            &.red {
              background: $Red400;
            }
            &.green {
              background: $Green400;
            }

            &.pain0 {
              background: $Pain0;
            }
            &.pain1 {
              background: $Pain1;
            }
            &.pain2 {
              background: $Pain2;
            }
            &.pain3 {
              background: $Pain3;
            }
            &.pain4 {
              background: $Pain4;
            }
            &.pain5 {
              background: $Pain5;
            }
            &.pain6 {
              background: $Pain6;
            }
            &.pain7 {
              background: $Pain7;
            }
            &.pain8 {
              background: $Pain8;
            }
            &.pain9 {
              background: $Pain9;
            }
            &.zIndex {
              z-index: 2;
            }
          }
        }
        &.performance {

        }
        &.pain {

        }
      }
    }
    .chart {
      width: 1084px;
    }
  }
  .noContent {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 554px;
    flex-direction: column;
    border: 1px solid $Gray300;
    border-radius: 4px;
    background: $White;
    margin: auto;
    > img {
      margin-bottom: 16px;
    }
    > p {
      @include body2-500($Gray1);
      width: 100%;
      text-align: center;
    }
  }
}