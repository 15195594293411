
          @import "src/assets/scss/colors.scss";
          @import "src/assets/scss/mixins.scss";
        
.pagination {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .btn {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
  .first {
    background: url('https://resources.mora.kr/static/app/mora-ex/assets/web/endD.svg') no-repeat;
    transform: rotate(180deg);
  }

  .prev {
    background: url('https://resources.mora.kr/static/app/mora-ex/assets/web/nextD.svg') no-repeat;
    transform: rotate(180deg);
  }

  .next {
    background: url('https://resources.mora.kr/static/app/mora-ex/assets/web/nextD.svg') no-repeat;
  }

  .last {
    background: url('https://resources.mora.kr/static/app/mora-ex/assets/web/endD.svg') no-repeat;
    margin-right: 0;
  }
  >div{
    display: flex;
    align-items: center;
    >span{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 24px;
      @include body-regular($Gray600);
      cursor: pointer;
    }
    >span:last-child {
      margin-right: 8px;
    }
    .focus {
      @include body3-600($Gray1);
    }
  }
}