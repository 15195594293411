
          @import "src/assets/scss/colors.scss";
          @import "src/assets/scss/mixins.scss";
        
.rehab {
  width: 100%;
  .rightWrapper {
    width: 1280px;
    min-height: 632px;
    padding: 0 50px;
    margin: 40px auto 120px auto;
    .exerciseBox {
      display: flex;
      .filterContainer {
        width: 280px;
        margin: 0 20px 0 0;
        .bodyPartFilter {
          width: 100%;
          margin-top: 16px;
          .bodyPartTitle {
            width: 100%;
            height: 49px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid $Black;
            padding-right: 8px;
            > p {
              @include heading4Regular($Black);
            }
            .reset {
              display: flex;
              align-items: center;
              > div {
                width: 20px;
                height: 20px;
                margin-right: 6px;
                background: url('https://resources.mora.kr/static/app/mora-ex/assets/web/G_replay.svg') no-repeat;
              }
              > span {
                @include body3-500($Gray300);
              }
              &.active {
                cursor: pointer;
                > div {
                  background: url('https://resources.mora.kr/static/app/mora-ex/assets/web/B_replay.svg') no-repeat;
                }
                > span {
                  @include body3-500($Gray600);
                }
              }
              &.active:active {
                > div {
                  background: url('https://resources.mora.kr/static/app/mora-ex/assets/web/P_replay.svg') no-repeat;
                }
                > span {
                  @include body3-500($Mint700);
                }
              }
            }
          }
          .bookmark {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 16px 0 0 0;
            background: $Gray100;
            border-radius: 4px;
            padding: 4px 8px;
            .bookmarkTitle {
              display: flex;
              align-items: center;
              > img {
                margin-right: 8px;
              }
              > span {
                @include body3-500($Gray900);
              }
            }
            .toggle {
              margin-right: 8px;
              .switch {
                position: relative;
                display: inline-block;
                width: 36px;
                height: 20px;
                vertical-align: middle;
                border-radius: 50%;
                > input {
                  display: none;
                }
              }
              .slider {
                position: absolute;
                cursor: pointer;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: $Gray4;
                -webkit-transition: .4s;
                transition: .4s;
              }
              .slider:before {
                position: absolute;
                content: "";
                height: 16px;
                width: 16px;
                background: $White;
                -webkit-transition: .4s;
                transition: .4s;
              }
              input:checked + .slider {
                background-color: $Primary;
              }
              input:checked + .slider:before {
                -webkit-transform: translateX(16px);
                -ms-transform: translateX(16px);
                transform: translateX(16px);
                border: 2px solid $Primary;
              }
              .slider {
                &.round {
                  width: 36px;
                  height: 20px;
                  border-radius: 34px;
                  &:before {
                    border-radius: 50%;
                    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.08);
                    border: solid 2px $Gray4;
                  }
                }
              }
            }
          }
          .filterWrapper {
            border-bottom: 1px solid $Gray300;
            .filterItem {
              height: 56px;
              display: flex;
              justify-content: space-between;
              align-items: center;
              cursor: pointer;
              > span {
                @include body2-600($Black);
              }
              > img {
                transition: ease all .3s;
                &.rotate {
                  transform: rotateX(180deg);
                }
              }
            }
            .bodyList {
              width: 100%;
              padding: 0 0 16px 0;
              .filterItemBox {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                margin-bottom: 8px;
                .itemBox {
                  display: flex;
                  align-items: center;
                  cursor: pointer;
                  > div {
                    width: 24px;
                    height: 24px;
                    margin-right: 8px;
                    background: url('https://resources.mora.kr/static/app/mora-ex/assets/web/G_checkSquare.svg') no-repeat;
                    &.marginBottom {
                      margin-bottom: 20px;
                    }
                  }
                  > span {
                    @include body3-500($Gray900);
                    > span {
                      @include small-regular($Gray600);
                    }
                    &.lvTxt {
                      display: flex;
                      &.green::before {
                        background: $Green500;
                      }
                      &.whiteGreen::before {
                        background: $Green300;
                      }
                      &.yellow::before {
                        background: $Yellow500;
                      }
                      &.orange::before {
                        background: $Orange;
                      }
                      &.red::before {
                        background: $Red500;
                      }
                    }
                    &.lvTxt::before {
                      display: block;
                      content: '';
                      width: 8px;
                      height: 8px;
                      border-radius: 50%;
                      margin: 7px 8px 0 0;
                    }
                  }
                }
                > span {
                  @include body3-500($Gray500);
                }
                &.active {
                  .itemBox {
                    > div {
                      background: url('https://resources.mora.kr/static/app/mora-ex/assets/web/P_checkSquare.svg') no-repeat;
                    }
                    > span {
                      @include body3-500($Mint500);
                    }
                  }
                }
                &.displayNone {
                  display: none;
                }
              }

            }
          }
          .muFilterWrapper {
            border-bottom: 1px solid $Gray300;
            .filterItem {
              height: 40px;
              align-items: flex-end;
              display: flex;
              justify-content: space-between;
              cursor: pointer;
              margin-bottom: 8px;
              > span {
                @include body2-600($Black);
              }
              > img {
                transition: ease all .3s;
                margin-bottom: 8px;
                &.rotate {
                  transform: rotateX(180deg);
                }
              }
            }
            .muFilterList {
              max-height: 440px;
              overflow-y: auto;
              padding-right: 8px;
              margin-bottom: 8px;
              &::-webkit-scrollbar {
                /* 스크롤바 막대 너비 설정 */
                width: 4px;
              }

              &::-webkit-scrollbar-thumb {
                /* 스크롤바 막대 높이 설정 */
                background: $Mint500;
                /* 스크롤바 둥글게 설정 */
                border-radius: 100px;
                //background-clip: padding-box;
                border: 3px solid transparent;
              }

              &::-webkit-scrollbar-track {
                /* 스크롤바 막대 높이 설정 */
                background: $Gray300;
                /* 스크롤바 둥글게 설정 */
                border-radius: 100px;
                //background-clip: padding-box;
                border: 3px solid transparent;
              }
              .muInput {
                margin-bottom: 12px;
              }
              .bodyList {
                width: 100%;
                .muPartWrapper {
                  .muPart {
                    margin: 12px 0;
                    @include body3-500($Gray900);
                  }
                  .filterItemBox {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-start;
                    margin-bottom: 8px;
                    .itemBox {
                      display: flex;
                      align-items: center;
                      cursor: pointer;
                      > div {
                        width: 24px;
                        height: 24px;
                        margin-right: 8px;
                        background: url('https://resources.mora.kr/static/app/mora-ex/assets/web/G_checkSquare.svg') no-repeat;
                        &.marginBottom {
                          margin-bottom: 20px;
                        }
                      }
                      > span {
                        @include body3-500($Gray900);
                        > span {
                          @include small-regular($Gray600);
                        }
                        &.lvTxt {
                          display: flex;
                          &.green::before {
                            background: $Green500;
                          }
                          &.whiteGreen::before {
                            background: $Green300;
                          }
                          &.yellow::before {
                            background: $Yellow500;
                          }
                          &.orange::before {
                            background: $Orange;
                          }
                          &.red::before {
                            background: $Red500;
                          }
                        }
                        &.lvTxt::before {
                          display: block;
                          content: '';
                          width: 8px;
                          height: 8px;
                          border-radius: 50%;
                          margin: 7px 8px 0 0;
                        }
                      }
                    }
                    > span {
                      @include body3-500($Gray500);
                    }
                    &.active {
                      .itemBox {
                        > div {
                          background: url('https://resources.mora.kr/static/app/mora-ex/assets/web/P_checkSquare.svg') no-repeat;
                        }
                        > span {
                          @include body3-500($Mint500);
                        }
                      }
                    }
                    &.displayNone {
                      display: none;
                    }
                  }
                }


              }
            }

          }
        }
      }
      .listContainer {
        width: 880px;
        background: $Gray100;
        padding: 16px;
        border-radius: 4px;
        .listContainerTop {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 24px;
          .topFilter {
            display: flex;
            align-items: center;
            .inputBox {
              width: 328px;
              height: 41px;
            }
            .dropdownWrapper {
              display: flex;
              width: 81px;
              margin-left: 8px;
            }
          }
          .createBtn {
            display: flex;
            width: 136px;
            height: 41px;
          }

        }

        .listWrapper {
          min-height: 600px;
          width: 100%;
          .exerciseList {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 40px;
          }
          .empty {
            width: fit-content;
            margin: 150px auto;
            > img {
              margin-bottom: 16px;
            }
            > p {
              @include body2-500($Gray2);
              text-align: center;
            }
          }
        }
      }
    }
  }
}