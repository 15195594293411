
          @import "src/assets/scss/colors.scss";
          @import "src/assets/scss/mixins.scss";
        
.leftTabs {
  width: 280px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  .tabWrapper {
    margin-bottom: 8px;
    > div {
      height: 48px;
      min-width: fit-content;
      display: flex;
      background: $Gray100;
      border: 1px solid $Gray100;
      align-items: center;
      border-radius: 4px;
      cursor: pointer;
      padding: 0 15px;
      transition: ease all .2s;
      .tabName {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        > span {
          position: relative;
          @include body1-400($Gray600);
          text-align: center;
          > img {
            position: absolute;
            top: 3px;
            left: 61px;
          }
        }
        > div {
          width: 24px;
          height: 24px;
        }
      }
      &.active {
        background: $Mint200;
        border: 1px solid $Mint200;
        .tabName {
          > span {
            @include body2-600($Mint700);
          }
          > div {
            background: url('https://resources.mora.kr/static/app/mora-ex/assets/web/DP_arrow_right.svg') no-repeat !important;
          }
        }
      }
      &.inactive:hover {
        background: $Mint200;
        border: 1px solid $Mint200;
        .tabName {
          > div {
            background: url('https://resources.mora.kr/static/app/mora-ex/assets/web/G_arrow_right_sharp.svg') no-repeat;
          }
        }
      }
      &.inactive:active {
        border: 1px solid $Mint500;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
    &.borderTop {
      border-top: 1px solid $Gray300;
      padding-top: 8px;
    }
  }


}