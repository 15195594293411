
          @import "src/assets/scss/colors.scss";
          @import "src/assets/scss/mixins.scss";
        
.organGroupSelect {
  width: 100%;
  .wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: calc(100vh - 146px);
    background: $Gray100;
    padding-top: 80px;
    .container {
      width: 580px;
      height: 460px;
      display: flex;
      flex-direction: column;
      margin: 0 auto;
      border-radius: 8px;
      border: 1px solid $Gray200;
      background: $White;
      padding: 24px;
      .flow {
        display: flex;
        justify-content: center;
        align-content: center;
        margin-bottom: 12px;
        > div {
          display: flex;
          @include small-medium($Gray400);
          line-height: 20px;
          > p {
            width: 20px;
            height: 20px;
            display: flex;
            align-content: center;
            justify-content: center;
            margin-right: 4px;
            border-radius: 50%;
            border: 1px solid $Gray400;
            @include small-regular($Gray400);
          }
          &.active {
            color: $Mint500;
            > p {
              background: $Mint100;
              color: $Mint500;
              border: 1px solid $Mint500;
            }
          }
        }
        > img {
          margin: 0 8px;
        }
      }
      .organSelect {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .organContainer {
          > h2 {
            @include heading3Thin($Gray900);
            margin: 8px 0 0 0;
          }
          > p {
            @include small-regular($Gray700);
            margin: 8px 0 24px 0;
          }
          .organBox {
            > p {
              @include small-medium($Black);
              margin-bottom: 4px;
            }
            .organInputWrapper {
              width: 100%;
              display: flex;
              justify-content: space-between;
              > input {
                width: 436px;
                height: 41px;
                border: 1px solid $Gray300;
                border-radius: 4px;
                padding: 10px 16px;
                cursor: pointer;
                &::placeholder {
                  @include body-regular($Gray500);
                }

              }
              > button {
                width: 88px;
                height: 41px;
                border-radius: 4px;
                background: $Mint100;
                @include body3-600($Mint600);
              }
            }
          }
        }
        > button {
          width: 100%;
          height: 52px;
          background: $Gray300;
          border-radius: 4px;
          @include body2-600($White);
          cursor: auto;
          &.active {
            cursor: pointer;
            background: $Mint500;
            transition: ease all .2s;
            &:hover {
              background: $Mint400;
            }
            &:active {
              background: $Mint700;
            }
          }
        }
      }
      .groupSelect {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .groupContainer {
          > h2 {
            @include heading3Thin($Gray900);
            margin: 8px 0 0 0;
          }
          .selectedOrgan {
            @include small-regular($Gray700);
            margin: 8px 0 24px 0;
            > span {
              margin-left: 8px;
              @include small-semi-bold($Mint500);
            }
          }
          .groupListTitle {
            display: flex;
            justify-content: space-between;
            align-items: center;
            @include small-medium($Black);
            margin-bottom: 4px;
          }
          .groupListBox {
            width: 100%;
            height: 186px;
            margin-bottom: 40px;
            border: 1px solid $Gray300;
            border-radius: 4px;
            overflow-y: auto;
            .groupItem {
              display: flex;
              justify-content: space-between;
              align-items: center;
              width: 100%;
              height: 54px;
              padding: 12px 16px;
              border-bottom: 1px solid $Gray200;
              > div {
                display: flex;
                align-items: center;
                .code {
                  background: $Gray200;
                  border-radius: 28px;
                  margin-right: 8px;
                  padding: 0 8px;
                  > span {
                    @include body-small-bold($Gray700);
                  }
                }
                .name {
                  width: 340px;
                  @include body3-600($Black);
                }
              }
              > button {
                width: 76px;
                height: 30px;
                border-radius: 4px;
                background: $Mint500;
                @include small-semi-bold($White);
              }
              &:last-child {
                border-bottom: none;
              }
            }
            .empty {
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              @include body2-500($Gray600);
            }
            &::-webkit-scrollbar{
              width: 5px;
            }
            &::-webkit-scrollbar-thumb{
              /* 스크롤바 막대 높이 설정 */
              //height: 40px;
              background: $Mint400;
              //opacity: 0.16;
              /* 스크롤바 둥글게 설정 */
              border-radius: 10px;
              background-clip: padding-box;
              border: 1px solid transparent;
            }
          }
          .btnWrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .back {
              width: 88px;
              height: 52px;
              background: $Gray200;
              border-radius: 4px;
              @include body2-600($Gray800);
              transition: ease all .2s;
              &:hover {
                color: $Mint500;
              }
              &:active {
                color: $Mint700;
              }
            }
            .newGroup {
              width: 436px;
              height: 52px;
              background: $Mint100;
              border-radius: 4px;
              @include body2-600($Mint600);
              transition: ease all .2s;
              &:hover {
                color: $Mint400;
              }
              &:active {
                background: $Mint200;
                color: $Mint600;
              }
            }
          }
        }
      }
      .newGroupAdd {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .newGroupContent {
          position: relative;
          .backArrow {
            width: 24px;
            margin-bottom: 12px;
            cursor: pointer;
          }
          > h2 {
            @include heading3Thin($Gray900);
            margin-bottom: 24px;
          }
          > p {
            @include small-medium($Black);
            margin-bottom: 4px;
          }
          .groupInputWrapper {
            position: relative;
            > input {
              width: 100%;
              height: 41px;
              padding: 10px 16px;
              margin-bottom: 16px;
              border: 1px solid $Gray300;
              border-radius: 4px;
              @include body-regular($Gray900);
              transition: ease all .2s;
              &::placeholder {
                @include body-regular($Gray500);
              }
              &.active {
                border: 1px solid $Mint500;
              }
            }
            .close {
              position: absolute;
              right: 14px;
              top: 12px;
              cursor: pointer;
            }
          }

          .groupGuide {
            margin-bottom: 16px;
            > p {
              display: flex;
              align-items: center;
              @include body-regular($Gray800);
              margin-bottom: 4px;
              &:last-child {
                margin-bottom: 0;
              }
              &:before {
                display: inline-block;
                content: '';
                width: 6px;
                height: 6px;
                border-radius: 50%;
                background: $Gray400;
                margin-right: 7.5px;
              }
            }
          }
          .warning {
            display: flex;
            align-items: center;
            background: $Red100;
            padding: 4px;
            margin-right: 7.5px;
            > span {
              margin-left: 4px;
              @include small-medium($Red500);
            }
          }
        }

        > button {
          width: 100%;
          height: 52px;
          background: $Gray300;
          border-radius: 4px;
          @include body2-600($White);
          cursor: auto;
          transition: ease all .3s;
          &.active {
            background: $Mint500;
            cursor: pointer;
            &:hover {
              background: $Mint400;
            }
            &:active {
              background: $Mint700;
            }
          }

        }

      }
    }

  }
  .groupModalContent {
    .code {
      margin-bottom: 4px;
      @include small-medium($Black);
    }
    .inputBox {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-bottom: 32px;
      > input {
        width: 100%;
        height: 41px;
        padding: 10px 16px;
        outline: none;
        border: 1px solid $Gray300;
        border-radius: 4px;
        @include body-regular($Black);
        &::placeholder {
          @include body-regular($Gray500);
        }
        &.borderRed {
          border: 1px solid $Red500;
        }
      }
      .warning {
        margin-top: 2px;
        padding-left: 16px;
        @include small-regular($Red500);
      }
    }

    .desc {
      display: flex;
      align-items: flex-start;
      @include small-regular($Gray800);
      &::before {
        content: '';
        width: 6px;
        height: 6px;
        background: $Gray400;
        border-radius: 50%;
        margin-right: 4px;
        margin-top: 5px;
      }
    }
  }
}