
          @import "src/assets/scss/colors.scss";
          @import "src/assets/scss/mixins.scss";
        
.patientRegistration{
  width: 100%;
  position: relative;
  background: $White;
  padding: 24px;
  >p{
    @include body3-500($Black);
    margin-bottom: 8px;
    padding: 0 0 0 6px;
  }
  >input{
    border: 1px solid $Gray4;
    border-radius: 4px;
    width: 100%;
    height: 52px;
    padding: 16px;
    @include body2-400;
  }
  .close {
    position: absolute;
    right: 35px;
    top: 72px;
    width: 20px;
    height: 20px;
    cursor: pointer;
    background: url('https://resources.mora.kr/static/app/mora-ex/assets/web/close_round.svg') no-repeat;
    transition: ease .2s;
    &:hover {
      background: url('https://resources.mora.kr/static/app/mora-ex/assets/web/P_close_round.svg') no-repeat;
    }
    &.closePhone {
      top: 179px;
    }
    &.closeBirthday {
      top: 285px;
    }
  }
  .activeSearchBox{
    border: 1px solid $Primary;
  }
  .nameInput,
  .phoneInput {
    margin-bottom: 24px;
  }
  >input::placeholder {
    @include body2-400($Gray3);
  }
  .privacyBox {
    margin-top: 24px;
    .content {
      width: 100%;
      height: 116px;
      overflow-x: hidden;
      overflow-y: auto;
      background: $Gray6;
      border: 1px solid $Gray4;
      border-radius: 4px;
      padding: 10px 16px;
      margin-bottom: 24px;
      &::-webkit-scrollbar {
        /* 스크롤바 막대 너비 설정 */
        width: 10px;
      }

      &::-webkit-scrollbar-thumb {
        /* 스크롤바 막대 높이 설정 */
        background: $Mint500;
        /* 스크롤바 둥글게 설정 */
        border-radius: 100px;
        background-clip: padding-box;
        border: 3px solid transparent;
      }

      .main {
        @include small-medium($Gray800);
        margin-bottom: 6px;
      }
      .order,
      .bottom {;
        @include small-regular($Gray600);
      }
      > p:nth-child(5) {
        margin-bottom: 6px;
      }
    }
    .check {
      display: flex;
      align-items: center;
      width: fit-content;
      cursor: pointer;
      > img {
        width: 18px;
        height: 18px;
        margin-right: 7px;
      }
      > span {
        @include body3-500($Black);
      }
    }
  }
}