
          @import "src/assets/scss/colors.scss";
          @import "src/assets/scss/mixins.scss";
        
.modalBig {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 999;
  > div {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: $White;
    border-radius: 16px;
    box-shadow: -4px -4px 8px rgba(0, 0, 0, 0.08), 8px 8px 16px rgba(0, 0, 0, 0.16);
    .header {
      height: 54px;
      background: $Gray6;
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
      > p {
        line-height: 54px !important;
        text-align: center;
        @include heading4;
      }
    }
    .body {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-content: center;
      justify-content: center;
    }
    .btns {
      width: 100%;
      height: 88px;
      background: $Gray5;
      padding: 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom-left-radius: 16px;
      border-bottom-right-radius: 16px;
      .btnSmall {
        width: 22%;
        height: 52px;
        background: $White;
        border-radius: 4px;
        @include body2-600($Mint600);
        background: $Mint100;
      }
      .btnBig {
        width: 76%;
        height: 52px;
        background: $Gray300;
        border-radius: 4px;
        @include body2-600($White);
        cursor: auto;
        &.active {
          background: $Primary;
          cursor: pointer;
        }
      }
    }
  }
}