
          @import "src/assets/scss/colors.scss";
          @import "src/assets/scss/mixins.scss";
        
.changeAccount {
  width: 100%;
  height: 100vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
  min-width: 980px;
  align-items: center;
  position: relative;
  .content {
    width: 400px;
    margin-top: 144px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
    .title {
      width: 100%;
      @include heading2-thin($Gray900);
      margin-bottom: 4px;
    }
    .subTitle {
      width: 400px;
      border-bottom: 1px solid #DDDDDD;
      margin-bottom: 40px;
      padding-bottom: 8px;
      > p {
        @include body3-500($Gray600);
      }
    }

    .goToLogin {
      width: 400px;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 40px;
      > button {
        width: 400px;
        height: 41px;
        border-radius: 4px;
        background: $Mint100;
        @include body2-600($Mint600);
        cursor: pointer;
      }
    }

    .items {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .item {
        width: 196px;
        height: 165px;
        display: flex;
        flex-direction: column;
        align-items: center;
        background: $White;
        border: 1px solid $Gray4;
        border-radius: 4px;
        padding: 24px 38px;
        cursor: pointer;
        transition: ease-out all .3s;
        > img {
          width: 80px;
          height: 80px;
          margin-bottom: 16px;
        }
        > span {
          width: 100%;
          text-align: center;
          @include body3-500($Gray800);
        }
        &:hover {
          background: #F0FCFC;
          box-shadow: -2px -2px 4px rgb(0 0 0 / 2%), 2px 2px 4px rgb(0 0 0 / 8%);
        }
        &:first-child {
          margin-right: 8px;
        }
      }
    }


  }
}