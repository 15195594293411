/*푸터 약관*/

.moraexPrivacyProcessFooter {
  width: 100%;
}

.moraexPrivacyProcessFooter .privacyTitle {
  border-bottom: 1px solid #222222;
  margin: 80px auto 64px auto;
  width: 1180px;
}

.moraexPrivacyProcessFooter .privacyTitle > h1 {
  font-family: 'Pretendard';
  font-size: 40px;
  font-weight: 700;
  line-height: 60px;
  color: #222222;
  padding: 10px 0;
}

.moraexPrivacyProcessFooter .personalInformationProcessingPolicyContent {
  width: 1180px;
  height: 100%;
  margin: 0 auto 120px auto;
}
.moraexPrivacyProcessFooter .personalInformationProcessingPolicyContent .title {
  font-family: 'Pretendard';
  font-size: 20px;
  font-weight: 600;
  line-height: 150%;
  color: #222222;
  margin: 15px 0 20px 0;
}
.moraexPrivacyProcessFooter .personalInformationProcessingPolicyContent .title:first-child {
  margin: 0 0 20px 0;
}

.moraexPrivacyProcessFooter .personalInformationProcessingPolicyContent .subTitle {
  font-family: 'Pretendard';
  font-size: 16px;
  font-weight: 700;
  line-height: 25.6px;
  color: #545454;
  margin: 15px 0;
}

.moraexPrivacyProcessFooter .personalInformationProcessingPolicyContent .content {
  font-family: 'Pretendard';
  font-size: 16px;
  font-weight: 400;
  line-height: 25.6px;
  color: #747474;
}

.moraexPrivacyProcessFooter .personalInformationProcessingPolicyContent .termsSubContent {
  font-family: 'Pretendard';
  font-size: 16px;
  font-weight: 400;
  line-height: 25.6px;
  color: #747474;
  padding-left: 15px;
}

/*본인인증 약관*/
.moraexPrivacyProcessAuth {
  width: 100%;
  height: 100%;
}

.moraexPrivacyProcessAuth .personalInformationProcessingPolicyContent {
  width: 100%;
  height: 120px;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 10px 16px;
}

.moraexPrivacyProcessAuth .personalInformationProcessingPolicyContent::-webkit-scrollbar{
  width: 5px;
}

.moraexPrivacyProcessAuth .personalInformationProcessingPolicyContent::-webkit-scrollbar-thumb {
  background: #07BEB8;
  /* 스크롤바 둥글게 설정 */
  border-radius: 10px;
  background-clip: padding-box;
  border: 1px solid transparent;
}

.moraexPrivacyProcessAuth .personalInformationProcessingPolicyContent .title {
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  color: #545454;
  margin: 15px 0 12px 0;
}
.moraexPrivacyProcessAuth .personalInformationProcessingPolicyContent .title:first-child {
  margin: 0 0 12px 0;
}
.moraexPrivacyProcessAuth .personalInformationProcessingPolicyContent .subTitle {
  font-family: 'Pretendard';
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: #747474;
  margin: 10px 0;
}
.moraexPrivacyProcessAuth .personalInformationProcessingPolicyContent .content {
  font-family: 'Pretendard';
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: #747474;
}
.moraexPrivacyProcessAuth .personalInformationProcessingPolicyContent .termsSubContent {
  font-family: 'Pretendard';
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: #747474;
  padding-left: 15px;
}