
          @import "src/assets/scss/colors.scss";
          @import "src/assets/scss/mixins.scss";
        
.patientAccess{
  width: 100%;
  position: relative;
  background: $White;
  padding: 24px;
  >p{
    @include body3-500($Black);
    margin-bottom: 8px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  >input{
    border: 1px solid $Gray4;
    border-radius: 4px;
    width: 100%;
    height: 52px;
    padding: 16px;
    @include body2-400;
  }
  .nameInput{
    background: $Gray100;
    border: 1px solid $Gray300;
    margin-bottom: 24px;
    @include body2-400($Gray900);
  }
  .options {
    display: flex;
    flex-wrap: wrap;
    > div {
      display: flex;
      align-items: center;
      margin-right: 28px;
      margin-bottom: 8px;
      cursor: pointer;
      > img {
        margin-right: 8px;
      }
      > span {
        @include body2-500($Gray600);
      }
      &:nth-child(4) {
        margin-right: 0;
      }
      &:last-child {
        margin-bottom: 4px;
      }
    }
  }
  .purposeInput {
    height: 41px;
    padding: 10px 16px;
    margin-bottom: 24px;
    @include body2-400($Gray900);
    &::placeholder {
      @include body-regular($Gray500);
    }
  }
  .warning {
    margin-left: 14px;
    @include body3-500($Black);
    > span {
      @include body3-500($Red500);
    }
    &:before {
      position: absolute;
      content: '*';
      display: block;
      margin: 2px 0 0 -14px;
    }
  }
}