
          @import "src/assets/scss/colors.scss";
          @import "src/assets/scss/mixins.scss";
        
.homeSkeleton {
  width: 100%;
  height: 100%;
  min-height: 800px;
  background: $Gray100;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 0 118px 0;
  .top {
    width: 1180px;
    height: 90px;
    background: $White;
    padding: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid $Gray300;
    border-radius: 4px;
    margin-bottom: 20px;
    > p {
      @include skeletonBox(161, 42);
    }
    > div {
      @include skeletonBox(40, 40);
    }
  }
  .contentGroup {
    width: 1180px;
    height: 228px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    .group {
      width: 280px;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      border: 1px solid $Gray300;
      border-radius: 4px;
      padding: 24px;
      background: $White;
      .title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        > p {
          @include skeletonBox(60, 24);
        }
        > div {
          @include skeletonBox(24, 24);
        }
      }
      .code {
        display: flex;
        flex-direction: column;
        > div {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .codeTitle {
            @include skeletonBox(54, 21);
          }
          .codeContent {
            @include skeletonBox(166, 33);
          }
          &:first-child {
            margin-bottom: 8px;
          }
        }
      }
      .code2 {
        @include skeletonBox(232, 41);
      }
    }
    .service {
      width: 280px;
      height: 100%;
      padding: 24px;
      border: 1px solid $Gray300;
      border-radius: 4px;
      background: $White;
      .subs {
        @include skeletonBox(102, 24);
        margin-bottom: 18px;
      }
      .subsName {
        @include skeletonBox(102, 36);
        margin-bottom: 3px;
      }
      .subsDesc {
        @include skeletonBox(222, 18);
        margin-bottom: 40px;
      }
      .btn {
        @include skeletonBox(232, 41);
        border-radius: 4px;
      }
    }
    .plans {
      width: 580px;
      height: 100%;
      border: 1px solid $Gray300;
      border-radius: 4px;
      background: $White;
      padding: 24px;
      > p {
        @include skeletonBox(105, 24);
        margin-bottom: 18px;
      }
      .planList {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        > div {
          width: 100%;
          height: 38px;
          display: flex;
          margin-bottom: 12px;
          background: $Gray100;
          border-radius: 4px;
          padding: 8px 24px;
          cursor: pointer;
          .creator {
            width: 123px;
            display: flex;
            justify-content: center;
            align-items: center;
            > span {
              margin-left: 8px;
              @include skeletonBox(101, 21);
            }
          }
          .bar {
            @include small-regular($Gray300);
          }
          .mainPart {
            width: 123px;
            display: flex;
            justify-content: center;
            align-items: center;
            > span {
              //margin-left: 8px;
              @include skeletonBox(101, 21);
            }
          }
          .patient {
            width: 123px;
            display: flex;
            justify-content: center;
            align-items: center;
            > span {
              margin: 0 8px;
              @include skeletonBox(126, 21);
            }
          }
          .status {
            width: 123px;
            display: flex;
            justify-content: center;
            align-items: center;
            > span {
              margin-left: 8px;
              @include skeletonBox(67, 21);
            }
          }
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
  .contentStatistics {
    width: 1180px;
    border: 1px solid $Gray300;
    border-radius: 4px;
    background: $White;
    padding: 32px 24px;
    .contentBoxWrapper {
      width: 100%;
      height: 93px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 24px;
      > div {
        display: flex;
        flex-direction: column;
        padding: 16px;
        @include skeletonBox(366, 93, $Gray100);
        .boxTop {
          display: flex;
          .top1 {
            @include skeletonBox(20, 20);
            margin-right: 4px;
          }
          .top2 {
            @include skeletonBox(107, 21);
          }
        }
        .boxBottom {
          margin: 0 0 0 auto;
          @include skeletonBox(48, 48);
        }
      }
    }
    .chartWrapper {
      width: 100%;
      height: 478px;
      border: 1px solid $Gray200;
      border-radius: 8px;
      margin-bottom: 24px;
      padding: 24px;
      .topTitle {
        width: 100%;
        height: 36px;
        display: flex;
        justify-content: space-between;
        margin-bottom: 24px;
        > p {
          @include skeletonBox(46, 24);
        }
        .legend {
          display: flex;
          align-items: center;
          > p {
            @include skeletonBox(80, 22);
            margin-right: 4px;
          }
          > div {
            margin-left: 12px;
            @include skeletonBox(84, 36);
          }
        }
      }
      .chart {
        @include skeletonBox(1083, 370);
      }
    }
    .roundChartWrapper {
      width: 100%;
      height: 168px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 24px;
      .roundChartBox {
        position: relative;
        width: 558px;
        height: 100%;
        display: flex;
        justify-content: space-between;
        border: 1px solid $Gray200;
        border-radius: 8px;
        padding: 24px;
        .title {
          display: flex;
          flex-direction: column;
          > div {
            margin-bottom: 16px;
            @include skeletonBox(44, 44);
          }
          > p {
            @include skeletonBox(132, 21);
          }
        }
      }
    }
    .barChartWrapper {
      width: 100%;
      height: 255px;
      border: 1px solid $Gray200;
      border-radius: 8px;
      padding: 24px;
      .barTitleBox {
        width: 100%;
        margin-bottom: 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .title {
          @include skeletonBox(147, 21);
        }
        .titleRight {
          @include skeletonBox(177, 20);
        }
      }
      .barChartBox {
        margin-bottom: 25px;
        .barTitle {
          display: flex;
          align-items: center;
          margin-bottom: 12px;
          > span {
            @include skeletonBox(70, 18);
            margin-right: 4px;
          }
          > p {
            @include skeletonBox(19, 18);
          }
          .barChangeRate {
            margin-left: 8px;
            display: flex;
            justify-content: center;
            align-items: center;
            > span {
              @include small-medium;
              &.green {
                color: $Green500;
              }
              &.red {
                color: $Red500;
              }
            }
          }
        }
        .barProgress {
          position: relative;
          width: 100%;
          height: 8px;
          background: $Gray200;
          border-radius: 4px;
          margin-bottom: 4px;
        }
        .barRange {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          > span {
            @include skeletonBox(18, 18);
          }
        }
      }
      .barChartBox:last-child {
        margin-bottom: 0;
      }
    }
  }
}