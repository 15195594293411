
          @import "src/assets/scss/colors.scss";
          @import "src/assets/scss/mixins.scss";
        
.content {
  width: 328px;
  min-height: 700px;
  margin: 88px auto 100px auto;

  .title {
    @include heading2-thin($Gray900);
    margin-bottom: 8px;
  }
  .steps {
    width: 100%;
    height: 2px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
    > div {
      width: 49%;
      height: 100%;
      background: $Gray700;
      border-radius: 40px;
    }
    > div:nth-child(2),
    > div:first-child {
      background: $Black;
    }
  }
  .pwd,
  .pwdConfirm {
    width: 100%;
    margin-bottom: 10px;
    position: relative;
    > p {
      margin-bottom: 4px;
      @include small-medium($Black);
    }
    > input {
      width: 100%;
      height: 41px;
      border: 1px solid $Gray4;
      border-radius: 4px;
      padding: 14px 16px;
      @include body-regular($Gray800);
      &::placeholder {
        @include body-regular($Gray500);
      }
      &.redBorder {
        border: 1px solid $Alert;
      }
      &.greenBorder {
        border: 1px solid $Success;
      }
    }
    > img {
      position: absolute;
      right: 16px;
      top: 31px;
      cursor: pointer;
    }
    > div {
      display: flex;
      margin-top: 2px;
      &.warning {
        > p {
          @include small-medium($Alert);
          padding-left: 16px;
        }
      }
      &.confirmed {
        > p {
          @include small-medium($Success);
          padding-left: 16px;
        }
      }
    }
  }

  .job,
  .license {
    margin-bottom: 10px;
    > p {
      @include small-medium($Black);
      margin-bottom: 4px;
    }
    > input {
      width: 100%;
      height: 41px;
      border: 1px solid $Gray4;
      border-radius: 4px;
      padding: 14px 16px;
      @include body-regular($Gray800);
      &::placeholder {
        @include body-regular($Gray3);
      }
    }
    &.license {
      margin-bottom: 40px;
    }
  }

  .btnWrapper {
    width: 100%;
    height: 41px;
    margin-top: 30px;
  }
}

@include mobile{
  .content {
    min-height: 500px;
  }
}
