
          @import "src/assets/scss/colors.scss";
          @import "src/assets/scss/mixins.scss";
        
.modalPlanDetail {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 999;
  > div {
    width: 970px;
    height: 712px;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: $White;
    border-radius: 16px;
    box-shadow: -4px -4px 8px rgba(0, 0, 0, 0.08), 8px 8px 16px rgba(0, 0, 0, 0.16);
    .header {
      height: 54px;
      background: $White;
      border-bottom: 1px solid $Gray400;
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
      > p {
        line-height: 54px !important;
        text-align: center;
        @include heading4($Black);
      }
      > img {
        position: absolute;
        top: 15px;
        right: 24px;
        cursor: pointer;
      }
    }
    .body {
      overflow-y: auto;
      width: 100%;
      padding: 72px 126px 80px 126px;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
      &::-webkit-scrollbar {
        display: none; /* Chrome, Safari, Opera*/
      }
      > img {
        width: 30px;
        height: 30px;
        display: block;
        margin: 0 0 8px auto;
        cursor: pointer;
      }
      .title {
        width: 100%;
        height: 85px;
        border-bottom: 1px solid $Black;
        .type {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 16px;
          .planName {
            @include title2Thin($Gray900);
            margin-bottom: 4px;
          }
          .dateLength {
            @include body-regular($Gray600);
            > span {
              @include body3-500($Gray900);
            }
          }

        }
        .planWrap {
          display: flex;
          align-items: center;
          width: 100%;
          height: 20px;
          > span {
            @include body-regular($Gray600);
          }
          > span.bold {
            @include body3-500($Gray900);
            margin: 0 20px 0 8px;
          }
          > span.dateTime {
            margin-right: 0;
          }
          > span.diagnosis {
            max-width: 100px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            word-wrap: break-word;
          }
          > span.marginRight {
            margin-right: 10px;
          }
          .shareWrap {
            height: 21px;
            display: flex;
            align-items: center;
            border-radius: 4px;
            padding: 0 8px;
            margin-right: 24px;
            cursor: pointer;
            .share {
              display: inline-flex;
              flex-direction: column;
              align-items: center;
              position: relative;
              margin: 0 0 0 4px;
              > span {
                @include body-regular($Gray600);
              }
              > span.bold {
                @include body3-500($Gray900);
              }

              > span.mint {
                @include body3-500($Mint500);
              }
              .tooltiptextName {
                visibility: hidden;
                opacity: 0;
                width: max-content;
                top: 109%;
                border-radius: 4px;
                position: absolute;
                z-index: 1;
                background: $White;
                transition: opacity .3s;
                padding: 4px 12px;
                white-space: normal;
                @include body-regular($White);
                box-shadow: 0px 6px 14px 0px rgba(0, 0, 0, 0.15), 0px 0px 2px 0px rgba(0, 0, 0, 0.06);
                .staffName {
                  display: flex;
                  align-items: center;
                  margin-bottom: 4px;
                  .planSharedStaffName {
                    @include body-regular($Gray700);
                    margin-left: 8px;
                  }
                  &:last-child {
                    margin-bottom: 0;
                  }
                }
                &::after {
                  content: " ";
                  position: absolute;
                  bottom: 100%;
                  left: 42%;
                  transform: rotate(90deg);
                  border-width: 5px;
                  border-style: solid;
                  border-color: transparent $White transparent transparent;
                }
              }
              .show {
                visibility: visible;
                opacity: 1;
              }
            }
            > img {
              width: 16px;
              height: 17px;
            }
            &:hover {
              background: $Mint100;
            }
          }

        }
      }
      .content {
        width: 100%;
        padding: 50px 0 0 0;
        .datesHorizon {
          height: 50px;
          display: flex;
          align-items: center;
          border-bottom: 1px solid $Gray300;
          padding-bottom: 10px;
          .rotate {
            transform: rotateY(180deg);
          }
          .dateWrap {
            display: flex;
            align-items: center;
            width: 654px;
            > p {
              width: 126px;
              height: 37px;
              border: 1px solid $White;
              padding: 8px;
              @include body3-500($Gray600);
              cursor: pointer;
              text-align: center;
              &.active {
                border: 1px solid $Mint500;
                border-radius: 4px;
                background: $Mint100;
                margin-top: 2px;
                @include body3-600($Mint500);
              }
            }
          }
        }
        .listContainer {
          width: 100%;
        }
      }
      video {
        display: block;
        width: 653px;
        height: 367px;
        border-radius: 4px;
        margin: 0 auto 22px auto;
      }
    }
  }
}