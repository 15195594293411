
          @import "src/assets/scss/colors.scss";
          @import "src/assets/scss/mixins.scss";
        
.dropdownStaffToShare {
  width: 100%;
  min-height: 41px;
  .filterWrapper {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    .dropdownBig {
      width: 100%;
      z-index: 3;
      .listSelected {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        background: $White;
        min-height: 41px;
        padding: 10px 16px 0 16px;
        width: 100%;
        cursor: pointer;
        border-radius: 4px;
        border: 1px solid $Gray300;
        > div {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          .inputStaff {
            border: none;
            @include body-regular($Gray900);
            width: 100%;
            padding: 0 0 10px 0;
            &::placeholder {
              @include body-regular($Gray500);
            }
            &.disable {
              display: none;
            }
          }
          .selectedStaff {
            display: flex;
            align-items: center;
            background: $Mint100;
            border-radius: 4px;
            padding: 2px 6px 2px 4px;
            margin: 0 8px 8px 0;
            > span {
              @include small-semi-bold($Primary);
              margin-right: 4px;
            }

          }
        }
        .inputStaffSingle {
          border: none;
          @include body-regular($Gray900);
          width: 100%;
          padding: 0 0 10px 0;
          &::placeholder {
            @include body-regular($Gray500);
          }
        }
      }
      .listSelected.borderPrimary {
        border: 1px solid $Primary;
      }
      .filterLists {
        display: flex;
        flex-direction: column;
        border-radius: 4px;
        margin-top: 2px;
        width: 100%;
        max-height: 204px;
        background-color: $White;
        filter: drop-shadow(0px 6px 20px rgba(0, 0, 0, 0.12));
        .listBox {
          .items {
            display: flex;
            align-items: center;
            height: 41px;
            padding: 10px 14px;
            cursor: pointer;
            > img {
              margin-right: 8px;
              cursor: pointer;
            }
            p {
              cursor: pointer;
              @include body-regular($Gray800);
              margin-left: 8px;
            }
            &:hover {
              background: $Gray100;
            }
            &:active {
              background: $Gray200;
            }
          }
          &.scroll {
            overflow-x: hidden;
            &::-webkit-scrollbar {
              /* 스크롤바 막대 너비 설정 */
              width: 10px;
            }

            &::-webkit-scrollbar-thumb {
              /* 스크롤바 막대 높이 설정 */
              background: $Mint500;
              /* 스크롤바 둥글게 설정 */
              border-radius: 100px;
              background-clip: padding-box;
              border: 3px solid transparent;
            }

            &::-webkit-scrollbar-track {
              /* 스크롤바 막대 높이 설정 */
              background: $Gray300;
              /* 스크롤바 둥글게 설정 */
              border-radius: 100px;
              background-clip: padding-box;
              border: 3px solid transparent;
            }
          }
        }
      }
    }
  }
}