/*푸터 약관*/

.moraexPrivacyCollectFooter {
  width: 100%;
}

.moraexPrivacyCollectFooter .privacyTitle {
  border-bottom: 1px solid #222222;
  margin: 40px auto 64px auto;
  width: 1180px;
}

.moraexPrivacyCollectFooter .privacyTitle > h1 {
  font-family: 'Pretendard';
  font-size: 40px;
  font-weight: 700;
  line-height: 60px;
  color: #222222;
  padding: 10px 0;
}

.moraexPrivacyCollectFooter .personalInformationProcessingPolicyContent {
  width: 1180px;
  height: 100%;
  margin: 0 auto 120px auto;
}
.moraexPrivacyCollectFooter .personalInformationProcessingPolicyContent .title {
  font-family: 'Pretendard';
  font-size: 20px;
  font-weight: 600;
  line-height: 150%;
  color: #222222;
  margin: 15px 0 20px 0;
}
.moraexPrivacyCollectFooter .personalInformationProcessingPolicyContent .title:first-child {
  margin: 0 0 20px 0;
}

.moraexPrivacyCollectFooter .personalInformationProcessingPolicyContent .subTitle {
  font-family: 'Pretendard';
  font-size: 16px;
  font-weight: 700;
  line-height: 25.6px;
  color: #545454;
  margin: 15px 0;
}

.moraexPrivacyCollectFooter .personalInformationProcessingPolicyContent .content {
  font-family: 'Pretendard';
  font-size: 16px;
  font-weight: 400;
  line-height: 25.6px;
  color: #747474;
}

.moraexPrivacyCollectFooter .personalInformationProcessingPolicyContent .termsSubContent {
  font-family: 'Pretendard';
  font-size: 16px;
  font-weight: 400;
  line-height: 25.6px;
  color: #747474;
  padding-left: 15px;
}

/*본인인증 약관*/
.moraexPrivacyCollectAuth {
  width: 100%;
  height: 100%;
}

.moraexPrivacyCollectAuth > div {
    width: 100%;
    height: 120px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.moraexPrivacyCollectAuth > div::-webkit-scrollbar {
    width: 5px;
}

.moraexPrivacyCollectAuth > div::-webkit-scrollbar-thumb {
    background: #07BEB8;
    /* 스크롤바 둥글게 설정 */
    border-radius: 10px;
    background-clip: padding-box;
    border: 1px solid transparent;
}

.moraexPrivacyCollectAuth > div .term .title1 {
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  color: #545454;
}

.moraexPrivacyCollectAuth > div .term .title2 {
  font-family: 'Pretendard';
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: #747474;
}

.moraexPrivacyCollectAuth .table {
  margin: 0;
}

.moraexPrivacyCollectAuth > div .term .content1 {
  font-family: 'Pretendard';
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: #747474;
  padding: 0;
}

.moraexPrivacyCollectAuth table th,
.moraexPrivacyCollectAuth table td {
  font-family: 'Pretendard';
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: #747474;
  padding: 5px;
}