
          @import "src/assets/scss/colors.scss";
          @import "src/assets/scss/mixins.scss";
        
.authentication {
  min-width: 980px;
  .agreeConditionWrapper{
    min-height: 650px;
    width: 328px;
    margin: 88px auto 120px auto;
    >div{
      .agreeConditionTitle{
        margin-bottom: 8px;
        >h1{
          @include heading2-thin($Gray900);
        }
      }
      .agreeProgressBar{
        display: flex;
        margin-bottom: 24px;
        >div{
          width: 162px;
          height: 2px;
          border-radius: 40px;
          background: $Gray300;
          margin-right: 8px;
        }
        > div:first-child {
          background: $Gray700;
        }
      }
      .agreeConditionBox{
        .termsTopTitle {
          @include small-medium($Black);
          margin-bottom: 16px;
        }
        .agreeAll{
          display: flex;
          align-items: center;
          margin-bottom: 16px;
          cursor: pointer;
          >img{
            width: 20px;
            height: 20px;
            margin-right: 8px;
          }
          >p{
            @include body-regular($Gray800);
          }
        }
        .agreeEmptyLine{
          border: .5px solid $Gray200;
          margin-bottom: 18px;
        }
        .agreeIndividual{
          .termsTitle{
            margin-bottom: 16px;
            .contentTitle {
              display: flex;
              justify-content: space-between;
              > div {
                display: flex;
                cursor: pointer;
                >img{
                  margin-right: 8px;
                }
                >p{
                  @include body-regular($Gray800);
                  margin-right: auto;
                }
              }
              >span{
                @include body-regular($Gray2);
                margin-right: 9px;
                cursor: pointer;
              }
            }
            .contentBox {
              margin-top: 16px;
              margin-left: 28px;
              .subTitle {
                display: flex;
                align-items: center;
                margin-bottom: 10px;
                cursor: pointer;
                > img {
                  width: 16px;
                  height: 22px;
                  margin-right: 11px;
                }
                > span {
                  @include body3-500($Gray800);
                }
              }
              .subContent {
                width: 300px;
                height: 120px;
                background: $Gray100;
                border: 1px solid $Gray200;
                border-radius: 4px;
              }
            }
          }
          .termsTitle:last-child{
            margin-bottom: 0;
          }
        }
        .settingUserInfo{
          display: flex;
          flex-direction: column;
          margin-bottom: 16px;
          .settingInput{
            margin-bottom: 16px;
            position: relative;
            > span {
              position: absolute;
              right: 16px;
              bottom: 15px;
              @include body3-500($Gray800);
            }
            .inputTitle{
              @include small-medium($Black);
              margin-bottom: 4px;
            }
            > input {
              width: 100%;
              height: 41px;
              padding: 14px 16px;
              border: 1px solid $Gray300;
              border-radius: 4px;
              @include body-regular($Gray900);
              &::placeholder {
                @include body-regular($Gray500);
              }
              &:focus {
                border: 1px solid $Primary !important;
              }
              &.err {
                border: 1px solid $Red500 !important;
              }
              &.disable {
                pointer-events: none;
                background: $Gray100;
              }
              &:hover {
                border: 1px solid $Gray400;
              }

            }
            .idNumber {
              position: relative;
              display: flex;
              justify-content: space-between;
              align-items: center;
              height: 52px;
              width: 100%;
              > input {
                width: 153px;
                height: 41px;
                padding: 14px 16px;
                border: 1px solid $Gray300;
                border-radius: 4px;
                @include body-regular($Gray900);
                &::placeholder {
                  @include body-regular($Gray500);
                }
                &:focus {
                  border: 1px solid $Primary !important;
                }
                &.err {
                  border: 1px solid $Red500 !important;
                }
                &.disable {
                  pointer-events: none;
                  background: $Gray100;
                }
                &:hover {
                  border: 1px solid $Gray400;
                }
              }
              > span {
                @include body3-500($Gray900);
                margin: 0 8px;
              }
              > img {
                position: absolute;
                right: 35px;
                top: 20px;
              }
            }
            .carrier {
              display: flex;
              align-items: center;
              position: relative;
              width: 100%;
              height: 41px;
              padding: 14px 16px;
              border: 1px solid $Gray300;
              border-radius: 4px;
              margin-bottom: 8px;
              cursor: pointer;
              > span {
                @include body-regular($Gray500);
                &.active {
                  color: $Gray800;
                }
              }
              > img {
                position: absolute;
                top: 16px;
                right: 19px;
                transition: ease all .3s;
                &.rotate {
                  transform: rotate(180deg);
                }
              }
              &.disable {
                pointer-events: none;
                background: $Gray100;
              }
              &.active {
                border: 1px solid $Primary !important;
              }
              &.err {
                border: 1px solid $Red500 !important;
              }
              &:hover {
                border: 1px solid $Gray400;
              }
            }
            .carrierOptions {
              width: 100%;
              position: absolute;
              top: 65px;
              filter: drop-shadow(0px 6px 20px rgba(0, 0, 0, 0.12));
              border-radius: 4px;
              background: $White;
              cursor: pointer;
              z-index: 1;
              > p {
                width: 100%;
                height: 41px;
                padding: 10px 16px;
                @include body-regular($Gray800);
                &:hover {
                  background: $Gray100;
                }
                &:active {
                  background: $Gray200;
                }
                &.active {
                  background: $Mint100 !important;
                }
              }
            }
            .inputWrapper {
              display: flex;
              position: relative;
              align-items: center;
              justify-content: space-between;
              > input {
                width: 228px;
                margin-right: 8px;
                height: 41px;
                padding: 14px 16px;
                border: 1px solid $Gray300;
                border-radius: 4px;
                @include body-regular($Gray900);
                &::placeholder {
                  @include body-regular($Gray500);
                }
                &:focus {
                  border: 1px solid $Primary !important;
                }
                &.err {
                  border: 1px solid $Red500 !important;
                }
                &.disable {
                  pointer-events: none;
                  background: $Gray100;
                }
                &:hover {
                  border: 1px solid $Gray400;
                }
              }
              .btnWrapper {
                width: 92px;
                height: 41px;
                > button {
                  height: 41px !important;
                  > span {
                    font-size: 14px !important;
                    font-weight: 600 !important;
                  }
                }
                .disableBtn {
                  width: 92px;
                  height: 41px;
                  background: $Gray300;
                  border-radius: 4px;
                  pointer-events: none;
                  @include body3-600($White);
                }
              }
              > span {
                position: absolute;
                top: 10px;
                right: 116px;
                @include body3-500($Gray800);
              }
              &.authWrapper {
                margin-top: 8px;
                .disable {

                }
              }
              &.phoneWrapper {
                .disable {
                  width: 100%;
                  margin-right: 0;
                }
              }
            }
            .errMsg {
              margin: 2px 0 0 16px;
              @include small-medium($Red500);
            }
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
      .agreeConditionBtnBox{
        width: 100%;
        height: 41px;
        margin-top: 40px;
      }
    }
  }
}

@include mobile {
  .authentication {
    min-width: 360px;
    .agreeConditionWrapper {
      margin: 40px auto 20px auto;
    }
  }
}