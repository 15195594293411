
          @import "src/assets/scss/colors.scss";
          @import "src/assets/scss/mixins.scss";
        
.noticeDetail {
  width: 100%;
  .detailWrapper {
    width: 1180px;
    margin: 0 auto;
    padding: 60px 0 120px 0;
    .breadCrumb {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 40px;
      > span {
        @include body3-500($Gray600);
        cursor: pointer;
      }
      > img {
        margin: 0 4px;
      }
      > p {
        @include body3-600($Gray900);
      }
    }
    .top {
      width: 100%;
      border-bottom: 1px solid $Black;
      > h3 {
        @include heading5($Black);
        margin-bottom: 8px;
      }
      > p {
        @include body2-600($Gray700);
        margin-bottom: 16px;
      }
    }
    .content {
      min-height: 500px;
      padding-top: 40px;
    }
  }
}