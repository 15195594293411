
          @import "src/assets/scss/colors.scss";
          @import "src/assets/scss/mixins.scss";
        
.payment {
  width: 100%;
  .content {
    width: 100%;
    min-width: 1280px;
    display: flex;
    min-height: 800px;
    padding-top: 40px;
    padding-bottom: 120px;
    .contentWrapper {
      width: 1180px;
      height: 100%;
      display: flex;
      margin: 0 auto;
      .leftContent {
        min-width: 280px;
        margin-right: 22px;
        position: sticky;
        top: 40px;
        height: 310px;
      }
      .rightContent {
        width: 878px;
        background: $Gray100;
        border-radius: 4px;
        padding: 16px;
        .title {
          width: 100%;
          padding: 0 0 16px 0;
          border-bottom: 1px solid $Gray300;
          > p {
            @include heading3Thin($Black);
          }
        }

        .service {
          width: 100%;
          min-height: 235px;
          margin: 24px 0 0 0;
          border-radius: 4px;
          background: $White;
          padding: 24px;
          .tag {
            @include body2-600($Gray800);
          }
          > div {
            .mainTitle {
              margin: 16px 0 16px 0;
              @include title2Thin($Black);
            }
            .subInfo {
              display: flex;
              align-items: center;
              margin-bottom: 5px;
              @include body3-500($Gray600);
              .planCount {
                margin-left: 4px;
                @include body3-600($Mint500);
                &.red {
                  color: $Red500;
                }
              }
              > img {
                margin-left: 4px;
              }
              .freeEndDate {
                @include body-regular($Gray500);
              }
            }
            .memberCount {
              margin: 4px 0 40px 0;
              @include body3-500($Gray2);
              > span {
                @include body3-600($Mint500);
                margin-left: 4px;
                > span {
                  @include body-regular($Gray500);
                }
              }
            }
            .serviceDate {
              margin-bottom: 40px;
              .count {
                display: flex;
                align-items: center;
                margin-bottom: 5px;
                @include body3-500($Gray600);
                .imgWarning {
                  margin-left: 4px;
                }
                .red {
                  color: $Red500 !important;
                }
                .remainCount {
                  margin-left: 4px;
                  @include body3-600($Primary);
                }
                .endDate {
                  margin-left: 4px;
                  @include body3-600($Gray500);
                }
              }
              .date {
                display: flex;
                align-items: center;
                .dateTitle {
                  @include body3-500($Gray600);
                }
                .dateInfo {
                  margin-left: 4px;
                  @include body3-600($Gray700);
                }
              }
            }
            .serviceBtns {
              display: flex;
              justify-content: space-between;
              align-items: flex-end;
              > button {
                width: 129px;
                height: 41px;
                border-radius: 4px;
                background: $Primary;
                @include body3-600($White);
                &.changeBtn {
                  color: $Gray600;
                  background: $Gray200;
                }
                &.cancelBtn {
                  background: $White;
                  border-radius: 0;
                  height: fit-content;
                  width: fit-content;
                  @include small-semi-bold($Gray600);
                  line-height: 12px;
                  padding: 0;
                  border-bottom: 1px solid $Gray600;
                }
              }
            }
            &.inactive {
              display: none;
            }
          }
          &.inactive {
            display: none;
          }
        }
        .payMethodContent {
          padding: 24px 0 0 0;
          .cardSection {
            background: $White;
            padding: 24px 24px 0 24px;
            border-radius: 4px;
            margin-bottom: 24px;
            .card {
              width: 100%;
              display: flex;
              justify-content: space-between;
              padding-bottom: 8px;
              border-bottom: 1px solid $Gray5;
              > span {
                height: 27px;
                @include heading4Regular;
              }
              > button {
                width: 98px;
                height: 30px;
                border-radius: 4px;
                display: flex;
                align-items: center;
                justify-content: center;
                background: $Gray200;
                @include small-semi-bold($Gray600);
                transition: ease all .3s;
                &:hover {
                  background: $Gray300;
                }
                &:active {
                  background: $Gray400;
                }
                &.inactive {
                  background: $Gray100;
                  color: $Gray300;
                  cursor: auto;
                }
              }
            }
            .cardWrapper {
              padding: 24px 0;
            }
          }
          .depositSection {
            background: $White;
            padding: 24px;
            border-radius: 4px;
            .deposit {
              width: 100%;
              display: flex;
              justify-content: space-between;
              margin-bottom: 8px;
              > span {
                height: 27px;
                @include heading4Regular;
              }
              > a {
                width: 87px;
                height: 30px;
                border-radius: 4px;
                display: flex;
                align-items: center;
                justify-content: center;
                background: $Gray200;
                @include small-semi-bold($Gray600);
                transition: ease all .3s;
                &:hover {
                  background: $Gray300;
                }
                &:active {
                  background: $Gray400;
                }
              }
            }
            .depositDetail {
              width: 100%;
              height: 66px;
              display: flex;
              justify-content: space-between;
              align-items: center;
              border-top: 1px solid $Gray200;
              border-bottom: 1px solid $Gray200;
              margin-bottom: 8px;
              .balance {
                display: flex;
                align-items: center;
                @include body3-500($Gray800);
                > span {
                  @include body2-600($Gray600);
                  margin-left: 6px;
                }
              }
              > a {
                height: 16px;
                @include small-semi-bold($Gray300);
                border-bottom: 1px solid $Gray300;
              }
              &.active {
                .balance {
                  > span {
                    color: $Mint500;
                  }
                }
                > a {
                  color: $Gray600;
                  border-bottom: 1px solid $Gray600;
                  transition: ease all .3s;
                  &:hover {
                    color: $Mint500;
                    border-bottom: 1px solid $Mint500;
                  }
                  &:active {
                    color: $Mint500;
                    border-bottom: 1px solid $Mint500;
                  }
                }
              }
            }
            > p {
              @include small-medium($Gray600);
            }
          }
        }
        .historyList {
          width: 100%;
          display: flex;
          flex-direction: column;
          padding: 24px 0 0 0;
          .historyToPay {
            width: 100%;
            height: 46px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            background: $Red100;
            border-radius: 4px;
            padding: 8px 16px;
            margin-bottom: 24px;
            > span {
              @include body3-500($Red500);
            }
            > button {
              background: $Red500;
              border-radius: 4px;
              padding: 6px 16px;
              @include small-semi-bold($White);
            }
          }
          .historySummary {
            width: 100%;
            padding: 24px;
            height: 127px;
            border-radius: 4px;
            margin-bottom: 24px;
            .historyTitle {
              margin-bottom: 16px;
              text-align: center;
              @include heading4($Gray800);
              .historyService {
                @include heading4Regular($Mint500);
              }
              .nextPayDate {
                @include heading4Regular($Black);
                margin-left: 4px;
              }
            }
            .sub {
              text-align: center;
              @include small-medium($Gray600);
              > a {
                color: $Orange500;
                text-decoration: underline;
              }
            }
          }
          .historyTable {
            background: $White;
            border-radius: 4px;
            padding: 24px;
            .tableHeader {
              display: flex;
              justify-content: space-between;
              align-items: center;
              width: 100%;
              height: 37px;
              border-bottom: 1px solid $Gray400;
              padding: 8px 0;
              > p {
                text-align: center;
                @include body3-500($Gray800);
              }
              .date {
                width: 100px;
              }
              .serviceName {
                width: 140px;
              }
              .period {
                width: 130px;
              }
              .payMethod {
                width: 150px;
              }
              .status {
                width: 58px;
              }
              .priceOrdered {
                width: 95px;
              }
              .pricePaid {
                width: 101px;
              }
            }
            .depositTableHeader {
              display: flex;
              justify-content: space-between;
              align-items: center;
              width: 100%;
              height: 37px;
              border-bottom: 1px solid $Gray400;
              padding: 8px 0;
              > p {
                text-align: center;
                @include body3-500($Gray800);
              }
              .depositDate {
                width: 100px;
              }
              .depositStatus {
                width: 160px;
              }
              .price {
                width: 160px;
              }
              .remain {
                width: 160px;
              }
              .note {
                width: 160px;
              }
            }

            .tableBody {
              margin-bottom: 40px;
              > div {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                height: 68px;
                border-bottom: 1px solid $Gray200;
                > span {
                  text-align: center;
                  @include small-regular($Gray800);
                }
                .date {
                  width: 100px;
                }
                .serviceName {
                  width: 140px;
                }
                .period {
                  width: 130px;
                }
                .payMethod {
                  width: 150px;
                }
                .status {
                  width: 58px;
                  &.red {
                    color: $Red500;
                  }
                }
                .priceOrdered {
                  width: 95px;
                }
                .pricePaid {
                  width: 101px;
                  &.red {
                    color: $Red500;
                  }
                }
              }
            }
            .depositTableBody {
              > div {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                height: 68px;
                border-bottom: 1px solid $Gray200;
                > span {
                  text-align: center;
                  @include small-regular($Gray800);
                }
                .depositDate {
                  width: 100px;
                }
                .depositStatus {
                  width: 160px;
                }
                .price {
                  width: 160px;
                }
                .remain {
                  width: 160px;
                }
                .note {
                  width: 160px;
                }
              }
            }
          }
        }
        .noHistory {
          width: 830px;
          min-height: 550px;
          padding: 24px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          > img {
            width: 230px;
            height: 230px;
            margin-bottom: 16px;
          }
          > p {
            width: 100%;
            @include body2-500($Gray1);
            text-align: center;
          }
        }
        &.inactive {
          display: none;
        }
      }
    }
  }
  .terminateInputBox {
    width: 100%;
    background: $White;
    padding: 40px 24px;
    .title {
      height: 63px;
      border-bottom: 1px solid $Gray300;
      margin-bottom: 24px;
      > p {
        @include heading5;
      }
    }
    .body {
      > p {
        position: relative;
        margin-left: 14px;
        @include body2-500($Gray600);
        margin-bottom: 8px;
        > span {
          @include subHeading($Gray800);
        }
        > a {
          color: $Orange500;
          text-decoration-line: underline;
        }
        &::before {
          position: absolute;
          top: 10px;
          left: -13px;
          display: inline-block;
          content: '';
          width: 4px;
          height: 4px;
          border-radius: 50%;
          background: $Gray500;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    &.inactive {
      display: none;
    }
  }
  &.inactive {
    display: none;
  }
}