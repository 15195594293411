
          @import "src/assets/scss/colors.scss";
          @import "src/assets/scss/mixins.scss";
        
.inputUser {
  position: relative;
  width: 100%;
  input {
    width: 100%;
    height: 41px;
    border: 1px solid $Gray4;
    border-radius: 4px;
    padding: 16px;
    @include body-regular;
    &::placeholder {
      @include body-regular($Gray3);
    }
    &.redBorder {
      border: 1px solid $Alert;
    }
  }
  img {
    position: absolute;
    top: 9px;
    right: 18px;
    cursor: pointer;
  }

}



