
          @import "src/assets/scss/colors.scss";
          @import "src/assets/scss/mixins.scss";
        
.statisticsCard {
  width: 266px;
  height: 100%;
  border-radius: 8px;
  background: $Gray100;
  padding: 16px;
  .cardTitle {
    width: 100%;
    display: flex;
    align-items: center;
    > img {
      margin-right: 4px;
    }
    > span {
      @include body3-600($Gray800);
    }
  }
  > p {
    width: 100%;
    text-align: end;
    @include headingSmall($Mint500);
    &.orange {
      color: $Orange600;
    }
    &.purple {
      color: $Purple400;
    }
  }
}