
          @import "src/assets/scss/colors.scss";
          @import "src/assets/scss/mixins.scss";
        
.buttonLight {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $Mint100;
  padding: 13px 20px;
  border-radius: 4px;
  transition: ease-out all .3s;
  > img {
    margin-right: 13px;
    position: absolute;
    top: 14px;
    right: -1px;
  }
  > span {
    @include body2-600($Mint600)
  }
  &:hover {
    background: $Mint100;
    > span {
      @include body2-600($Mint400);
    }

  }
  &:active {
    background: $Mint200;
    > span {
      @include body2-600($Mint600);
    }

  }
}