
          @import "src/assets/scss/colors.scss";
          @import "src/assets/scss/mixins.scss";
        
.myPage {
  width: 100%;
  .myPageWrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    .mypageContentBox{
      width: 100%;
      display: flex;
      flex-direction: column;
      > div {
        min-height: 700px;
        display: flex;
        background: $Gray6;
        .accountBox {
          display: flex;
          flex-direction: column;
          width: 408px;
          margin: 80px auto 148px auto;
          padding: 24px;
          background: $White;
          border-radius: 4px;
          > p {
            text-align: center;
            @include heading4Regular($Gray900);
            margin-bottom: 40px;
          }
          > div {
            margin-bottom: 16px;
            > p {
              @include small-medium($Black);
              margin-bottom: 4px;
            }
            > div {
              display: flex;
              align-items: center;
              .disableField {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 294px;
                height: 41px;
                border-radius: 4px;
                border: 1px solid $Gray300;
                background: $Gray100;
                padding: 10px 16px;
                @include body-regular($Gray900);
              }
              .change {
                @include small-medium($Gray600);
                cursor: pointer;
                text-decoration: underline;
                margin-left: 18px;
              }
            }
          }
          .name {
            > div {
              display: flex;
              align-items: center;
              justify-content: space-between;
              width: 100%;
              height: 41px;
              border-radius: 4px;
              border: 1px solid $Gray300;
              background: $Gray100;
              padding: 10px 16px;
              @include body-regular($Gray900);
            }
          }

          .job,
          .license {
            position: relative;
            > input {
              outline: none;
              width: 100%;
              height: 41px;
              padding: 10px 16px;
              border: 1px solid $Gray300;
              border-radius: 4px;
              @include body-regular($Gray900);
              &:hover {
                border: 1px solid $Gray400;
              }
              &:focus {
                border: 1px solid $Primary;
              }
              &.disable {
                background: $Gray100;
                pointer-events: none;
              }
            }
            > span {
              width: 100%;
              height: 41px;
              padding: 10px 16px;
              border: 1px solid $Gray300;
              border-radius: 4px;
              position: absolute;
              top: 30px;
              background: $Gray100;
              @include body-regular($Gray900);
            }
          }

          .license {
            height: 78px;;
          }

          .btnWrapper {
            margin-top: 24px;
          }

          &.skeleton {
            align-items: center;
            > p {
              text-align: center;
              @include skeletonBox(80, 30);
            }
            > div {
              @include skeletonBox(360, 65);
              &:last-child {
                @include skeletonBox(360, 44);
                margin-top: 35px;
                margin-bottom: 0;
              }
            }
          }
        }

      }

    }
  }
}
