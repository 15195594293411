
          @import "src/assets/scss/colors.scss";
          @import "src/assets/scss/mixins.scss";
        
.banner {
  display: none;
  width: 100%;
  align-items: center;
  justify-content: center;
  height: 44px;
  background: $Yellow150;
  cursor: pointer;
  > div {
    position: relative;
    width: 1180px;
    display: flex;
    align-items: center;
    justify-content: center;
    > p {
      text-align: center;

      @include body2-600($Gray900);
    }
    > img {
      right: 16px;
      position: absolute;
    }
  }
  &.show {
    display: flex;
  }

}