
          @import "src/assets/scss/colors.scss";
          @import "src/assets/scss/mixins.scss";
        
.planStop {
  width: 100%;
  height: 310px;
  padding: 24px;
  >p {
    text-align: center;
  }
  .title {
    @include title2Thin($Black);
    margin-bottom: 4px;
  }
  .sub {
    @include body2-500($Gray600);
    margin-bottom: 40px;
  }
  > div {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 24px;
    > p {
      @include body2-600($Gray600);
      width: 202px;
      height: 52px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: $Gray200;
      border-radius: 4px;
      cursor: pointer;
      &:first-child,
      &:nth-child(3) {
      margin-right: 8px;
      }
      &:first-child,
      &:nth-child(2) {
        margin-bottom: 8px;
      }
      &.active {
        border: 1px solid $Mint700;
        @include body2-600($Mint700);
      }
    }

  }
  .warning {
    @include body3-500($Red500);
  }
}