
          @import "src/assets/scss/colors.scss";
          @import "src/assets/scss/mixins.scss";
        
.groupInfo {
  width: 100%;
  .wrapper {
    width: 1180px;
    height: 100%;
    min-height: 800px;
    display: flex;
    flex-direction: column;
    padding: 60px 0 144px 0;
    margin: 0 auto;
    .move {
      display: flex;
      align-items: center;
      margin-bottom: 26px;
      .home {
        @include body3-500($Gray600);
        cursor: pointer;
      }
      > img {
        margin: 0 4px;
      }
      .here {
        @include body3-600($Gray900);
      }
    }
    .title {
      display: flex;
      align-items: center;
      width: 100%;
      height: 65px;
      border-bottom: 1px solid $Black;
      margin-bottom: 24px;
      > p {
        @include heading2-thin($Black);
      }
    }
    .inputContainer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .inputWrapper {
        position: relative;
        width: 580px;
        margin-bottom: 16px;
        > p {
          @include body3-500($Black);
          margin-bottom: 4px;
        }
        > input {
          width: 100%;
          height: 52px;
          border-radius: 4px;
          border: 1px solid $Gray300;
          background: $White;
          padding: 14px 16px;
          @include body2-400($Gray900);
          &::placeholder {
            @include body2-400($Gray500);
          }
          &.active {
            border: 1px solid $Mint500;
          }
        }
        > img {
          position: absolute;
          top: 42px;
          right: 18px;
          width: 20px;
          height: 20px;
          background-size: cover;
          cursor: pointer;
        }
      }
    }
    .homepage {
      width: 100%;
      margin-bottom: 16px;
      > p {
        @include body3-500($Black);
        margin-bottom: 4px;
      }
      .detailAddressInput {
        position: relative;
        > input {
          width: 100%;
          height: 52px;
          border-radius: 4px;
          border: 1px solid $Gray300;
          padding: 14px 16px;
          margin-top: 8px;
          @include body2-400($Gray900);
          &::placeholder {
            @include body2-400($Gray500);
          }
          &.active {
            border: 1px solid $Mint500;
          }
        }
        > img {
          position: absolute;
          top: 23px;
          right: 18px;
          width: 20px;
          height: 20px;
          background-size: cover;
          cursor: pointer;
        }
      }
    }
    .address {
      width: 100%;
      margin-bottom: 16px;
      > p {
        @include body3-500($Black);
        margin-bottom: 4px;
      }
      .inputBox {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        > input {
          width: 1064px;
          height: 52px;
          border-radius: 4px;
          border: 1px solid $Gray300;
          background: $Gray100;
          padding: 14px 16px;
          @include body2-400($Gray900);
          &::placeholder {
            @include body2-400($Gray500);
          }
        }
        > button {
          width: 108px;
          height: 52px;
          background: $Mint500;
          border-radius: 4px;
          @include body2-600($White);
        }
      }
      .detailAddressInput {
        position: relative;
        > input {
          width: 100%;
          height: 52px;
          border-radius: 4px;
          border: 1px solid $Gray300;
          padding: 14px 16px;
          margin-top: 8px;
          @include body2-400($Gray900);
          &::placeholder {
            @include body2-400($Gray500);
          }
          &.active {
            border: 1px solid $Mint500;
          }
        }
        > img {
          position: absolute;
          top: 23px;
          right: 18px;
          width: 20px;
          height: 20px;
          background-size: cover;
          cursor: pointer;
        }
      }

    }
    .time {
      position: relative;
      width: 100%;
      margin-bottom: 40px;
      > p {
        @include body3-500($Black);
        margin-bottom: 16px;
      }
      .timeTable {
        display: flex;
        flex-direction: column;
        margin-left: 24px;
        border-bottom: 1px solid $Gray200;
        .timeItem {
          width: 100%;
          display: flex;
          align-items: center;
          padding: 0 16px;
          margin: 0 0 16px 0;
          .day {
            width: 80px;
            @include body2-400($Gray700);
            margin-right: 16px;
            &.SAT {
              @include body2-400($PurpleDay);
            }
            &.SUN,
            &.HOL {
              @include body2-400($Red500);
            }
          }
          .bar {
            @include body2-400($Gray400);
            margin: 0 16px;
          }
          .colon {
            margin: 0 8px;
          }
          .tilde {
            width: 20px;
            text-align: center;
            margin: 0 8px;
          }
          > p {
            display: flex;
            align-items: center;
            cursor: pointer;
            > img {
              margin-right: 8px;
            }
            &.workingDay {
              margin-right: 16px;
            }
          }
          .dropdownWrapper {
            width: 180px;
          }
          &:last-child {
            margin: 0 0 24px 0;
          }
        }
      }

    }
    .warning {
      width: 100%;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 40px;
      > img {
        margin-right: 8px;
      }
      > span {
        @include body3-500($Gray600);
      }
    }
    > button {
      display: block;
      width: 104px;
      height: 52px;
      @include body2-600($White);
      border-radius: 4px;
      margin: 0 auto;
      background: $Gray300;
      cursor: auto;
      &.active {
        background: $Mint500;
        cursor: pointer;
      }
    }
  }

}