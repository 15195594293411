
          @import "src/assets/scss/colors.scss";
          @import "src/assets/scss/mixins.scss";
        
.footerBreadCrumb{
  width: 100%;
  height: 84px;
  background: $Gray6;
  box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.25);
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  .forApprovePlan {
    justify-content: space-between;
    .breadCancel{
      width: 100px;
      height: 64px;
      margin: auto 0;
      background: white;
      border: 1px solid $Gray2;
      border-radius: 8px;
      >p{
        @include body2-500($Gray2);
      }
    }
    > div {
      .breadPrev {
        border: 1px solid $Primary;
        margin-right: 20px;
        >p{
          color: $Primary;
        }
      }
    }
  }
  .forPayment {
    > p {
      align-items: center;
      display: flex;
      @include body2-500($Gray800);
      margin-right: 24px;
      > span {
        @include heading3;
        margin-left: 8px;
      }
    }
  }
  .assignPlan {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  >div{
    width: 1200px;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .btnWrapper {
      width: 100%;
      display: flex;
      justify-content: space-between;
      .assignBtn {
        display: flex;
        .privacy {
          display: flex;
          align-items: center;
          margin-right: 20px;
          .checkBox {
            display: flex;
            align-items: center;
            cursor: pointer;
            > img {
              width: 17px;
              height: 17px;
              margin-right: 13px;

            }
            > span {
              padding-top: 2px;
              @include body2-500($Black);
            }
          }

          .infoImg {
            width: 21px;
            height: 21px;
            background: url('https://resources.mora.kr/static/app/mora-ex/assets/web/info.svg') no-repeat;
            background-size: contain;
            margin-left: 9px;
            cursor: pointer;
            transition: ease all .3s;
            &:hover {
              background-size: contain !important;
              background: url('https://resources.mora.kr/static/app/mora-ex/assets/web/P_info.svg') no-repeat;
              .tooltip {
                opacity: 1;
                visibility: visible;
              }
            }
            .tooltip {
              position: absolute;
              opacity: 0;
              visibility: hidden;
              width: 409px;
              height: 130px;
              bottom: 75px;
              background: $Mint100;
              border-radius: 4px;
              padding: 8px 12px;
              box-shadow: 0px 7px 16px rgba(0, 0, 0, 0.2);
              transition: opacity .5s;
              .content1 {
                @include small-medium($Gray900);
                margin-bottom: 6px;
              }
              .content2 {
                @include small-regular($Gray800);
              }
            }
          }
        }
      }
    }
    .breadPrev{
      width: 100px;
      height: 64px;
      background: white;
      border: 1px solid $Primary;
      border-radius: 8px;
      >p{
        @include body2-500($Primary);
      }
    }
    .breadNext{
      width: 280px;
      height: 52px;
      background: $Gray300;
      margin: auto 0;
      cursor: default;
      border-radius: 8px;
      >p{
        @include body2-500($White);
      }
      &.setDate {
        margin-left: auto;
      }
      &.nextBtn {
        margin-left: auto;
      }
    }
    .breadActive{
      background: $Primary;
      cursor: pointer;
      >p{
        @include subHeading($White);
      }
    }
  }
  .createProgram {
    display: flex;
    justify-content: space-between;
    .createProgramBtnCreateActive{
      background: $Primary;
      cursor: pointer;
      >p{
        color: $White;
      }
    }
    .createProgramBtnCancelActive{
      background: $White !important;
    }
    .previewBtn {
      display: flex;
      > span {
        height: 64px;
        display: flex;
        align-items: center;
        @include body-regular($Gray4);
        margin-right: 40px;
        text-decoration: underline;
      }
      > span.prevActive {
        color: $Gray2;
        cursor: pointer;
      }
    }

  }
}