
          @import "src/assets/scss/colors.scss";
          @import "src/assets/scss/mixins.scss";
        
.cards {
  width: 100%;
  .cardEmptyBoxes {
    width: 100%;
    display: flex;
    justify-content: space-between;
    > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
      background: $Gray200;
      border-radius: 4px;
      cursor: pointer;
      > p {
        margin-top: 16px;
        @include body3-500($Gray600);
      }
    }
    &.inactive {
      display: none;
    }
  }

  .cardBoxes {
    width: 100%;
    display: flex;
    justify-content: space-between;
    > div {
      display: flex;
      flex-direction: column;
      height: 100%;
      border-radius: 4px;
      &.cardsWrapper {
        padding: 24px;
        display: flex;
        flex-direction: column;
        border: 1px solid $Gray300;
        border-radius: 4px;
        .content {
          width: 100%;
          height: 26px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .cardDefault {
            display: flex;
            height: 26px;
            align-items: center;
            > img {
              width: 16px;
              height: 16px;
              margin-right: 4px;
            }
            > span {
              @include small-medium($Primary);
            }
          }
          .editBtn {
            @include small-medium($Gray600);
            cursor: pointer;
            padding: 4px 8px;
          }
          .defaultBtn {
            background: $Gray200;
            padding: 4px 8px;
            border-radius: 4px;
            @include small-medium($Gray600);
            &.inactive {
              background: $Gray100;
              color: $Gray500;
              cursor: auto;
            }
          }
          .cardBtns {
            > button {
              padding: 4px 8px;
              @include small-medium($Gray600);
            }
          }
          &.inactive {
            display: none;
          }
        }
        .cardName {
          margin: 41px 0 8px 0;
          @include title2Thin;
        }
        .cardNo {
          >span {
            margin-right: 8px;
            @include body3-500($Gray600);
          }
        }
        &.active {
          border: 1px solid $Primary;
          background: $Primary100;
        }
      }
      &.emptyCards {
        justify-content: center;
        align-items: center;
        cursor: pointer;
        background: $Gray200;
        > p {
          margin-top: 16px;
          @include body3-500($Gray500);
        }
      }

    }
    &.inactive {
      display: none;
    }
  }
  .addCardInputBox{
    width: 100%;
    background: $White;
    padding: 24px;
    .cardInfo1 {
      position: relative;
      margin-bottom: 24px;
      >p{
        @include body3-500($Black);
        margin-bottom: 4px;
      }
      .error {
        margin-bottom: 0;
        margin-top: 4px;
        padding-left: 8px;
        @include small-regular($Alert);
        &.inactive {
          display: none;
        }
      }
      >input{
        border: 1px solid $Gray4;
        border-radius: 4px;
        width: 100%;
        height: 41px;
        padding: 16px;
        @include body-regular($Gray900);
      }
      >input::placeholder {
        @include body-regular($Gray3);
      }
    }
    .cardInfo2 {
      display: flex;
      justify-content: space-between;
      margin-bottom: 24px;
      > div {
        >p{
          @include body3-500($Black);
          margin-bottom: 4px;
        }
        .error {
          margin-bottom: 0;
          margin-top: 4px;
          padding-left: 8px;
          @include small-regular($Alert);
          &.inactive {
            display: none;
          }
        }
        >input{
          border: 1px solid $Gray4;
          border-radius: 4px;
          width: 100%;
          height: 41px;
          padding: 16px;
          @include body-regular($Gray900);
          &.small {
            width: 95px;
          }
          &.margin8 {
            margin-right: 8px;
          }
          &.medium {
            width: 198px;
          }
        }
        >input::placeholder {
          @include body-regular($Gray3);
        }
      }

    }
    .cardInfo3 {
      >p{
        @include body3-500($Black);
        margin-bottom: 4px;
      }
      .error {
        margin-bottom: 0;
        margin-top: 4px;
        padding-left: 8px;
        @include small-regular($Alert);
        &.inactive {
          display: none;
        }
      }
      >input{
        border: 1px solid $Gray4;
        border-radius: 4px;
        width: 100%;
        height: 41px;
        padding: 16px;
        @include body-regular($Gray900);
      }
      >input::placeholder {
        @include body-regular($Gray3);
      }
    }
  }
}
